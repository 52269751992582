import React, { useState, useContext, useEffect } from "react";
import { PostApi } from "../../apis";
import PostContext from "../../context/PostContext";
import Loader from "../GlobalComponent/Loader";
import PostCard from "./PostCard";
import { Box, Paper, Grid } from "@mui/material";

const getFirstPartOfEmail = (email) => {
  return email.split("@")[0];
};

export default function SocialMediaPost() {
  const { allPosts, removePost } = useContext(PostContext);
  const [posts, setPosts] = useState([]);
  const [likes, setLikes] = useState({});
  const [loading, setLoading] = useState(true);
  const [commentText, setCommentText] = useState("");

  const fetchPosts = async () => {
    try {
      const res = await PostApi.getPost();
      const postData = res.data.data;


      setPosts(postData);

      const userLikes = {};
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user.id;

      postData.forEach((post) => {
        const likedByUser = post.likes.some((like) => like.liker_id === userId);
        userLikes[post.id] = likedByUser;
      });

      setLikes(userLikes);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async (postId) => {
    const userId = JSON.parse(localStorage.getItem("user")).id;

    // Optimistic UI Update
    setLikes((prevLikes) => ({
      ...prevLikes,
      [postId]: !prevLikes[postId], // Toggle like status locally
    }));

    setPosts((prevPosts) =>
      prevPosts.map((post) => {
        if (post.id === postId) {
          const hasLiked = post.likes.some((like) => like.liker_id === userId);
          const updatedLikes = hasLiked
            ? post.likes.filter((like) => like.liker_id !== userId)
            : [...post.likes, { liker_id: userId }];

          return { ...post, likes: updatedLikes }; // Update post's likes array
        }
        return post;
      })
    );

    try {
      // Call the API to handle the like/dislike request
      await PostApi.postLike(postId);
    } catch (error) {
      console.error("Error liking post:", error);

      // Revert like status in case of an error
      setLikes((prevLikes) => ({
        ...prevLikes,
        [postId]: !prevLikes[postId], // Revert like status
      }));

      setPosts((prevPosts) =>
        prevPosts.map((post) => {
          if (post.id === postId) {
            // Revert the likes array to the original state
            const hasLiked = post.likes.some(
              (like) => like.liker_id === userId
            );
            const revertedLikes = hasLiked
              ? post.likes.filter((like) => like.liker_id !== userId)
              : [...post.likes, { liker_id: userId }];

            return { ...post, likes: revertedLikes };
          }
          return post;
        })
      );
    }
  };

  const handleAddComment = async (postId, commentText) => {
    try {
      // Call API to post comment
      const response = await PostApi.commentOnPost(postId, {
        comment: commentText,
      });

      // Add the new comment to the post's comments in the local state
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? {
                ...post,
                comments: [...post.comments, { text: commentText }],
              }
            : post
        )
      );

      // Clear the comment input field
      setCommentText("");
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    setPosts(allPosts);
  }, [allPosts]);

  if (loading) {
    return (
      <div>
        <Loader loading={loading} />
      </div>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12}>
        <Box>
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              border: "none",
              boxShadow: "none",
              backgroundColor: "#F5F5F5",
            }}
          >
            {posts.map((post, index) => (
              <PostCard
                key={index}
                post={post}
                handleLike={handleLike}
                handleAddComment={handleAddComment}
                likes={likes}
                setPosts={setPosts}
                fetchPosts={fetchPosts}
              />
            ))}
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}
