import React from 'react';
import { MenuItem, Select, FormControl, Box, Chip, Grid, Typography, } from '@mui/material';

const ExperienceForm = ({
  color,
  placeHolder,
  specialties,
  experienceYears,
  formik,
  selectedSpecialty,
  handleSpecialtyChange,
  handleChipClick, // Add this prop
  placeholderName
}) => {

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ fontWeight: '600',mt:-0.5 }}>
        Profession
      </Typography>

      <FormControl fullWidth>
        <Select
       
          name={placeholderName}
          value={selectedSpecialty || ''}
          onChange={handleSpecialtyChange}
          displayEmpty
          renderValue={(selected) => {
            if (!selected || selected.length === 0) {
              return <span style={{ color: color}}>{placeHolder}</span>;
            }
          
            return selected.replace(/([A-Z])/g, ' $1').trim();
          }}
        >
          <MenuItem value="" disabled>
            <em>{ 'Select Specialities'}</em>
          </MenuItem>
          {Object.keys(specialties).map((specialty) => (
            <MenuItem key={specialty} value={specialty}>
              {specialty.replace(/([A-Z])/g, ' $1').trim()}
            </MenuItem>
          ))}
        </Select>
        {/* Formik validation error for specialties */}
        {formik?.touched?.specialties && formik?.errors?.jobDetails?.specialties && (
          <Typography variant="body2" color="error">
            {formik.errors.specialties}
          </Typography>
        )}
      </FormControl>

      {/* Display Subcategories as Chips */}
      {selectedSpecialty && (
        <Box mt={2}>
          <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>
            Specialties
          </Typography>
          <Grid container spacing={1}>
            {Object.keys(experienceYears[selectedSpecialty] || {}).map((subcategory) => (
              <Grid item key={subcategory}>
                <Chip
                  label={subcategory.replace(/([A-Z])/g, ' $1').trim()}
                  onClick={() => handleChipClick(subcategory)} // Call handleChipClick prop
                  color={experienceYears[selectedSpecialty]?.[subcategory] ? 'primary' : 'default'}
                  sx={{ mr: 0, mb: 1 }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ExperienceForm;
