import React, { useEffect, useState, useRef, useContext } from "react";
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  Typography,
  Container,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  ListItemIcon,
  Avatar,
  Badge,
  TextField,
  Button, useMediaQuery, useTheme
} from "@mui/material";
import { AccountCircle, ArrowBack } from "@mui/icons-material";
import { io } from "socket.io-client";
import JobContext from "../../context/JobContext";
import SendIcon from '@mui/icons-material/Send';

// Custom styles using Material-UI
const useStyles = makeStyles((theme) => ({
  chatSection: {
    width: "100%",
    height: "550px",
    display: "flex",
    flexDirection: "row",
    borderRadius: 8,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  contactList: {
    width: "100%",
    border: "1px solid white",
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "650px",

    },
  },
  chatArea: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "550px",
  },
  header: {
    backgroundColor: "white",
    color: "black",
    padding: "16.5px",
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid white",
    borderRight: "1px solid #e0e0e0",
    borderLeft: "1px solid #e0e0e0",

  },
  messageArea: {
    flex: 1,
    overflowY: "scroll", // Change to scroll to allow scrolling
    padding: "10px",
    backgroundColor: "#f0f6ff",
    borderRight: "1px solid white",
    borderLeft: "1px solid white",
    borderTop: "1px solid white",
    height: "650px",
    // Hide scrollbar
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none", // For Firefox
    msOverflowStyle: "none", // For Internet Explorer and Edge
  },

  footer: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid white",
    borderRight: "1px solid white",
    borderLeft: "1px solid white",
    backgroundColor: "white",
  },
  messageBubble: {
    borderRadius: "20px",
    padding: "12px",
    marginBottom: "10px",
    wordBreak: "break-word",
    display: "inline-block",
    position: "relative",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
  },
  sentMessage: {
    backgroundColor: "#00c661",
    color: "white",
    alignSelf: "flex-end",
    marginLeft: "auto",
  },
  receivedMessage: {
    backgroundColor: "white",
    alignSelf: "flex-start",
    border: "1px solid white",
    textAlign: "right",
  },
  userList: {
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide chat window on small screens
    },
  },
  chatWindow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide chat window on small screens
    },
  },
  hidden: {
    display: 'none',
  },
}));

// ChatRooms Component
const ChatRooms = ({ userId }) => {
  const theme = useTheme();
  // Inside your component
  const isSmallScreen = useMediaQuery('(max-width: 900px)'); // For small screens (e.g., mobile)
  const isLargeScreen = useMediaQuery('(min-width: 901px)'); // For large screens (e.g., tablet and up)


  const [chatRooms, setChatRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const classes = useStyles();
  const [unreadCounts, setUnreadCounts] = useState({});
  const [socket, setSocket] = useState(null);
  const { addNewMessage } = useContext(JobContext);
  const messageEndRef = useRef(null); // Ref to track the end of the message list
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleChatToggle = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleBackButtonClick = () => {
    setIsChatOpen(false);
  };
  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };



  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [messages]); // This ensures it only scrolls when `messages` changes
  useEffect(() => {
    const socketInstance = io(`https://api.hirio.app/`);
    setSocket(socketInstance); // Save socket instance
    return () => {
      socketInstance.disconnect(); // Clean up on unmount
    };
  }, []);
  useEffect(() => {
    // Fetch chat rooms when component loads
    const fetchChatRooms = async () => {
      try {
        const response = await axios.get(
          `https://api.hirio.app/chatrooms/${userId}`
        );
        setChatRooms(response.data);
        console.log("response", response.data);


        const counts = {};
        response.data.forEach((room) => {
          counts[room.id] = room.messages[0]?.unreadCount || 0;
        });
        setUnreadCounts(counts);
      } catch (error) {
        console.error("Error fetching chat rooms:", error);
      }
    };
    fetchChatRooms();
  }, [userId]);

  useEffect(() => {
    if (socket) {
      // Join the room when the user selects it
      if (selectedRoom) {
        socket.emit('joinRoom', { chatRoomId: selectedRoom.id });

        // Mark messages as read when opening the chat room
        fetch(`/messages/mark-as-read/${selectedRoom.id}/${userId}`, {
          method: 'PUT',
        })
          .then(() => {
            // Reset unread count for the currently open chat room
            setUnreadCounts((prevCounts) => ({
              ...prevCounts,
              [selectedRoom.id]: 0,
            }));
          })
          .catch((error) => console.error('Error marking messages as read:', error));
      }

      socket.on('receiveMessage', (message) => {
        if (selectedRoom && message.chatRoomId === selectedRoom.id) {
          setMessages((prevMessages) => [...prevMessages, message]);
        } else {
          // Increment unread count for other rooms
          setUnreadCounts((prevCounts) => ({
            ...prevCounts,
            [message.chatRoomId]: (prevCounts[message.chatRoomId] || 0) + 1,
          }));
        }
      });

      socket.on('unreadMessage', (data) => {
        if (data.receiver_id === userId && (!selectedRoom || data.chatRoomId !== selectedRoom.id)) {
          // Increment unread count only if the chat room is not currently open
          setUnreadCounts((prevCounts) => ({
            ...prevCounts,
            [data.chatRoomId]: (prevCounts[data.chatRoomId] || 0) + 1,
          }));
          addNewMessage(true); // Optional: Notify the user
        }
      });

      return () => {
        if (socket) {
          socket.off('receiveMessage');
          socket.off('unreadMessage');
        }
      };
    }
  }, [socket, selectedRoom]);


  const handleRoomSelection = (roomId) => {
    setSelectedRoom(roomId);

    // Reset unread count for the selected room
    setUnreadCounts((prevCounts) => ({
      ...prevCounts,
      [roomId]: 0,
    }));
  };

  // Handle selecting a chat room
  const handleUserClick = (room) => {
    setSelectedRoom(room);
    setMessages([]); // Clear previous messages

    // Join the selected room
    socket.emit("joinRoom", { chatRoomId: room.id });

    // Fetch message history for the selected room (optional)
    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          `https://api.hirio.app/messages/${room.id}`
        );
        setMessages(response.data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
    const countMessages = async () => {
      try {
        await axios.put(
          `https://api.hirio.app/messages/mark-as-read/${room.id}/${userId}`
        );
        setUnreadCounts((prevCounts) => ({
          ...prevCounts,
          [room.id]: 0, // Reset unread count for this room
        }));
      } catch (error) {
        console.error("Error marking messages as read:", error);
      }
    };
    countMessages();
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== "" && selectedRoom) {
      const messageData = {
        chatRoomId: selectedRoom.id, // Ensure we're using the existing room's ID
        senderId: userId,
        receiver_id: selectedRoom.reciverId === userId ? selectedRoom.senderId : selectedRoom.reciverId,
        message: newMessage,
      };
  
      socket.emit("sendMessage", messageData);
      setNewMessage(""); // Clear input
    }
  };
  console.log("messages", messages);

  return (
    <Container >
      {isLargeScreen && (

        <Grid container mt={5}>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <Box className={classes.contactList} sx={{ height: "549px", overflow: "hidden", }}>
              {/* <Divider /> */}
              <Typography variant="h5" sx={{ py: 2, pl: 2, fontWeight: "bold" }}>
                Chats
              </Typography>
              {/* <Divider /> */}
              <List
                sx={{
                  height: "467px",
                  overflow: "auto",
                  pr: 1, // Optional: Padding to avoid content being hidden under scrollbar
                  "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar for webkit-based browsers
                  "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
                  "scrollbar-width": "none" // Hide scrollbar for Firefox
                }}
              >
                {chatRooms.length === 0 ? (
                  <Typography
  variant="body1"
  color="gray"
  sx={{ textAlign: 'left', mt: 2,ml:2.5 }}
>
  No messages yet
</Typography>

) : (
  <List
    sx={{
      height: "467px",
      overflow: "auto",
      pr: 1,
      "&::-webkit-scrollbar": { display: "none" },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none"
    }}
  >
    {chatRooms.map((room) => (
      <>
        <ListItem
          button
          key={room.id}
          onClick={() => handleUserClick(room)}
          sx={{
            height: '72px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ListItemIcon>
            {(room.reciverId === userId ? room.sender?.profile?.profile_photo_url : room.reciver?.profile?.profile_photo_url) ? (
              <Avatar
                sx={{ width: 40, height: 40 }}
                src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${room.reciverId === userId ? room.sender?.profile?.profile_photo_url : room.reciver?.profile?.profile_photo_url}`}
              />
            ) : (
              <AccountCircle sx={{ color: "gray", width: 40, height: 40 }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary={room.reciverId === userId ? room.sender?.profile?.full_name || "Unknown User" : room.reciver?.profile?.full_name || "Unknown User"}
            secondary={
              <Typography variant="body1" paragraph>
                <Box component="span">
                  {room?.job?.jobDetails?.specialties &&
                  Object.entries(room.job.jobDetails.specialties).filter(([, value]) => value).length > 0 ? (
                    Object.entries(room.job.jobDetails.specialties).filter(([, value]) => value).map(([specialty], index) => (
                      <Typography key={index} component="span" sx={{ fontSize: '0.875rem', color: 'gray', marginRight: '4px' }}>
                        {specialty.replace(/([A-Z])/g, " $1").trim()}
                      </Typography>
                    ))
                  ) : (
                    <Typography component="span" variant="body1">
                      N/A
                    </Typography>
                  )}
                </Box>
              </Typography>
            }
            sx={{
              mt: 3,
              "& .MuiListItemText-secondary": {
                fontSize: "0.5rem",
                color: "gray",
              },
              "& .MuiListItemText-primary": {
                fontWeight: '500', marginRight: '8px', fontSize: "15px",
              },
            }}
          />
          <Badge
            badgeContent={unreadCounts[room.id] || 0}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'green',
                color: 'white',
              },
            }}
          />
        </ListItem>
        <Divider sx={{ mx: 2 }} />
      </>
    ))}
  </List>
)}

              </List>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={8} xl={8}>
  {/* Chat Area */}
  {chatRooms.length === 0 ? (
    <Box
      className={classes.chatArea}
      sx={{
        backgroundColor: "#f0f6ff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Typography
        variant="body1"
        color="primary.main"
        sx={{
          textAlign: 'center',
          mt: 2,
          fontWeight: 500,
          fontSize: '1.5rem',
        }}
      >
      Start the conversation!
      </Typography>
    </Box>
  ) : selectedRoom ? (
    <Box className={classes.chatArea}>
      {/* Render chat content */}
      <Typography variant="h6" className={classes.header}>
        <Box display="flex" alignItems="center">
          {/* Avatar */}
          {(selectedRoom.reciverId === userId
            ? selectedRoom.sender?.profile?.profile_photo_url
            : selectedRoom.reciver?.profile?.profile_photo_url) ? (
            <Avatar
              sx={{ width: 30, height: 30, mr: 1 }}
              src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${
                selectedRoom.reciverId === userId
                  ? selectedRoom.sender?.profile?.profile_photo_url
                  : selectedRoom.reciver?.profile?.profile_photo_url
              }`}
            />
          ) : (
            <AccountCircle sx={{ color: "gray", width: 30, height: 30, mr: 1 }} />
          )}
          {/* User Name */}
          <Typography sx={{ fontWeight: '500', marginRight: '8px', fontSize: "16px" }}>
            {selectedRoom.reciverId === userId
              ? selectedRoom.sender?.profile?.full_name || "Unknown User"
              : selectedRoom.reciver?.profile?.full_name || "Unknown User"}
          </Typography>
        </Box>
      </Typography>
      
      {/* Chat messages */}
      <Box className={classes.messageArea}>
        <List>
          {messages
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((msg) => (
              <ListItem key={msg.id}>
                <Box sx={{ width: '100%', textAlign: userId === msg.senderId ? 'right' : 'left' }}>
                  <Typography
                    className={`${classes.messageBubble} ${userId === msg.senderId
                      ? classes.sentMessage
                      : classes.receivedMessage}`}
                  >
                    {msg.message}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      display: 'block',
                      color: 'gray',
                      marginTop: '0px',
                      fontSize: '0.8rem'
                    }}
                  >
                    {msg.createdAt && !isNaN(new Date(msg.createdAt).getTime())
                      ? new Date(msg.createdAt).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false
                        })
                      : new Date().toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false
                        })}
                  </Typography>
                </Box>
              </ListItem>
            ))}
        </List>
        <p ref={messageEndRef}></p>
      </Box>

      {/* Message input */}
      <Box className={classes.footer}>
        <TextField
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          fullWidth
          onKeyDown={handleKeyDown}
          placeholder="Type a message..."
        />
        <IconButton onClick={handleSendMessage}>
          <SendIcon sx={{ color: "#00c661" }} />
        </IconButton>
      </Box>
    </Box>
  ) : (
    <Box
      className={classes.chatArea}
      sx={{
        backgroundColor: "#f0f6ff",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Typography
        variant="h5"
        color="primary"
        sx={{
          textAlign: 'center',
          margin: '15px 0',
          fontWeight: 500,
          fontSize: '1.5rem',
        }}
      >
       Choose a contact to chat with!
      </Typography>
    </Box>
  )}
</Grid>


        </Grid>
      )}
      {isSmallScreen && (
        <Grid container mt={5}>


          <Grid item xs={12} md={4} lg={4} xl={4}>
            <Box className={isChatOpen ? classes.hidden : classes.contactList}>
              <Divider />
              <Typography variant="h5" sx={{ py: 2, pl: 2, fontWeight: "bold" }}>
                Chats
              </Typography>
              <Divider />
              {!isChatOpen && (
                <Box >
                  <Box onClick={handleChatToggle}>
                    <List  sx={{
                  height: "487px",
                  overflow: "auto",
                  pr: 1, // Optional: Padding to avoid content being hidden under scrollbar
                  "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar for webkit-based browsers
                  "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
                  "scrollbar-width": "none" // Hide scrollbar for Firefox
                }}>
                    {chatRooms.length === 0 ? (
  <Typography variant="body1" color="gray" sx={{ textAlign: 'center', mt: 2 }}>
    No messages yet. Start the conversation!
  </Typography>
) : (
  <List
    sx={{
      height: "467px",
      overflow: "auto",
      pr: 1,
      "&::-webkit-scrollbar": { display: "none" },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none"
    }}
  >
    {chatRooms.map((room) => (
      <>
        <ListItem
          button
          key={room.id}
          onClick={() => handleUserClick(room)}
          sx={{
            height: '72px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ListItemIcon>
            {(room.reciverId === userId ? room.sender?.profile?.profile_photo_url : room.reciver?.profile?.profile_photo_url) ? (
              <Avatar
                sx={{ width: 40, height: 40 }}
                src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${room.reciverId === userId ? room.sender?.profile?.profile_photo_url : room.reciver?.profile?.profile_photo_url}`}
              />
            ) : (
              <AccountCircle sx={{ color: "gray", width: 40, height: 40 }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary={room.reciverId === userId ? room.sender?.profile?.full_name || "Unknown User" : room.reciver?.profile?.full_name || "Unknown User"}
            secondary={
              <Typography variant="body1" paragraph>
                <Box component="span">
                  {room?.job?.jobDetails?.specialties &&
                  Object.entries(room.job.jobDetails.specialties).filter(([, value]) => value).length > 0 ? (
                    Object.entries(room.job.jobDetails.specialties).filter(([, value]) => value).map(([specialty], index) => (
                      <Typography key={index} component="span" sx={{ fontSize: '0.875rem', color: 'gray', marginRight: '4px' }}>
                        {specialty.replace(/([A-Z])/g, " $1").trim()}
                      </Typography>
                    ))
                  ) : (
                    <Typography component="span" variant="body1">
                      N/A
                    </Typography>
                  )}
                </Box>
              </Typography>
            }
            sx={{
              mt: 3,
              "& .MuiListItemText-secondary": {
                fontSize: "0.5rem",
                color: "gray",
              },
              "& .MuiListItemText-primary": {
                fontWeight: '500', marginRight: '8px', fontSize: "15px",
              },
            }}
          />
          <Badge
            badgeContent={unreadCounts[room.id] || 0}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'green',
                color: 'white',
              },
            }}
          />
        </ListItem>
        <Divider sx={{ mx: 2 }} />
      </>
    ))}
  </List>
)}

                    </List>
                  </Box>
                </Box>
              )}

            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            {/* Chat Area */}
            {isChatOpen && (
              <Box className="chatWindow">
                <Button onClick={handleBackButtonClick} style={{ marginBottom: '10px', color: "#00c661" }}>
                  <ArrowBack />
                </Button>
                {/* Render your chat interface here */}

                {selectedRoom && (
                  <Box className={classes.chatArea}>
                    <Typography variant="h6" className={classes.header}>

                      <Box display="flex" alignItems="center">
                        {/* Avatar */}
                        {(
                          selectedRoom.reciverId === userId
                            ? selectedRoom.sender?.profile?.profile_photo_url
                            : selectedRoom.reciver?.profile?.profile_photo_url
                        ) ? (
                          <Avatar
                            sx={{ width: 30, height: 30, mr: 1 }} // Smaller avatar for inline display
                            src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${selectedRoom.reciverId === userId
                              ? selectedRoom.sender?.profile?.profile_photo_url
                              : selectedRoom.reciver?.profile?.profile_photo_url}`}
                          />
                        ) : (
                          <AccountCircle sx={{ color: "gray", width: 30, height: 30, mr: 1 }} />
                        )}
                        {/* User Name */}
                        <Typography sx={{ fontWeight: '500', marginRight: '8px', fontSize: "16px" }}>
                          {selectedRoom.reciverId === userId
                            ? selectedRoom.sender?.profile?.full_name || "Unknown User"
                            : selectedRoom.reciver?.profile?.full_name || "Unknown User"}
                        </Typography>


                      </Box>
                      {/* {selectedRoom.job.jobDetails && selectedRoom.job.jobDetails.specialties && (
                    <>
                      {Object.keys(selectedRoom.job.jobDetails.specialties)
                        .filter((specialty) => selectedRoom.job.jobDetails.specialties[specialty]) 
                        .map((specialty, index) => (
                          <Typography
                            key={index}
                            component="span"
                            variant="h6"

                          >
                            {specialty.replace(/([A-Z])/g, " $1").trim()}
                          </Typography>
                        ))}
                    </>
                  )} */}
                    </Typography>
                    <Box className={classes.messageArea}>
                      <List> 
                        {messages
                          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                          .map((msg) => (
                            <ListItem key={msg.id}>
                              <Box sx={{ width: '100%', textAlign: userId === msg.senderId ? 'right' : 'left' }}>
                                <Typography
                                  className={`${classes.messageBubble} ${userId === msg.senderId
                                    ? classes.sentMessage
                                    : classes.receivedMessage
                                    }`}
                                >
                                  {msg.message}

                                </Typography>

                                {/* Display the time below the message */}
                                <Typography
                                  variant="caption"
                                  sx={{
                                    display: 'block',
                                    color: 'gray',
                                    marginTop: '0px',
                                    fontSize: '0.8rem',
                                   
                                  }}
                                >
                                  {msg.createdAt && !isNaN(new Date(msg.createdAt).getTime())
                                    ? new Date(msg.createdAt).toLocaleTimeString([], {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: false // 24-hour format
                                    })
                                    : new Date().toLocaleTimeString([], { // Fallback to current time
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: false // 24-hour format
                                    })}
                                </Typography>

                              </Box>
                            </ListItem>
                            
                          ))}
                      </List>
                      <p ref={messageEndRef}></p>
                    </Box>
                    <Box className={classes.footer}>
                      <TextField
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        fullWidth
                        onKeyDown={handleKeyDown}
                        placeholder="Type a message..."
                      />
                      {/* <Button
                        onClick={handleSendMessage}
                        variant="contained"
                        color="primary"
                        sx={{ ml: 2, p: 2, px: 4 }}
                      >
                        Send
                      </Button> */}
                      <IconButton onClick={handleSendMessage}>
                        <SendIcon sx={{ color: "#00c661" }} />
                      </IconButton>
                    </Box>
                  </Box>
                )}

              </Box>
            )}

          </Grid>
        </Grid>


      )}
    </Container>
  );
};

export default ChatRooms;