import React from 'react';
import NavBar from '../../../../components/Global/NavBar';
import { Grid, Typography, useMediaQuery, useTheme,Container,Box } from '@mui/material';
import Chat from '../../../../components/Chat/chat';
import Footer from '../../../../components/Global/Footer';


const Chats = () => {



    return (
      
            <>
            <NavBar />
            <Box sx={{ backgroundColor: 'white', minHeight: '100vh',mt:-6 }}>
              <Container>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                  <Chat />
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Footer />
          </>
    );
}

export default Chats;
