import React, { useState, useEffect } from 'react';
import { Typography, Container, Tabs, Tab, Box } from '@mui/material';
import { JobApi } from '../../apis';
import JobCard from '../JobOffers/EmployeeCard';

export default function JobCandidates({ jobId }) {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [jobDataArray, setJobDataArray] = useState([]);
  const [pendingJobData, setPendingJobData] = useState([]);
  const [acceptedJobData, setAcceptedJobData] = useState([]);
  const [rejectedJobData, setRejectedJobData] = useState([]);
  const fetchJobPosts = async () => {
    try {
      const res = await JobApi.getAllJobPostUsers();
      if (res && res.data && res.data.data) {
        const parsedJobPosts = res.data.data.map((job) => ({
          ...job,
          topSpecialities: parseJSON(job.profile.top_specialities),
          employmentType: parseJSON(job.profile.employment_type),
          salaryRange: parseJSON(job.profile.salary_range),
        }));
        console.log("topSpecialities",parsedJobPosts);

        setJobDataArray(parsedJobPosts);
        setPendingJobData(parsedJobPosts.filter((job) => job.job_offer_status === 'Pending'));
        setAcceptedJobData(parsedJobPosts.filter((job) => job.job_offer_status === 'Accepted'));
        setRejectedJobData(parsedJobPosts.filter((job) => job.job_offer_status === 'Rejected'));
      } else {
        console.error('Error fetching job posts:', res ? res.message : 'No response received');
      }
    } catch (error) {
      console.error('Error fetching job posts:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchJobPosts();
  }, [jobId]);

  const parseJSON = (jsonString) => {
    try {
      if (typeof jsonString === 'object') {
        return jsonString;
      }
      return jsonString ? JSON.parse(jsonString) : {};
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return {};
    }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
 <Box>
   <Typography
       
        sx={{
          fontWeight: "600",
          fontSize: "22px",
          marginBottom: "10px",
          marginTop: "40px",
        }}
      >
        Candidates
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt.
      </Typography>
   <Box sx={{ borderBottom: 1, borderColor: 'divider', '& .MuiTabs-flexContainer': { justifyContent: 'space-between' }  ,minWidth:"240px"}}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="job offer tabs" indicatorColor="secondary">
              <Tab
                label={` Pending(${pendingJobData.length})`}
                sx={{   fontSize: { xs: "10px", sm: "12px",md:"14px"},color: tabValue === 0 ? 'primary.main' : 'black', width: '33.33%', fontWeight: tabValue === 0 ? 'bold' : 'normal' }}
              />
              <Tab
                label={`Accepted (${acceptedJobData.length})`}
                sx={{  fontSize: { xs: "10px", sm: "12px",md:"14px"}, color: tabValue === 1 ? 'primary.main' : 'black', width: '33.33%', fontWeight: tabValue === 1 ? 'bold' : 'normal' }}
              />
              <Tab
                label={`Rejected (${rejectedJobData.length})`}
                sx={{   fontSize: { xs: "10px", sm: "12px",md:"14px"},color: tabValue === 2 ? 'primary.main' : 'black', width: '33.33%', fontWeight: tabValue === 2 ? 'bold' : 'normal' }}
              />
     

      
          </Tabs>
          </Box>
          {tabValue === 0 && (
            <Box sx={{ pt: 2 }}>
              {pendingJobData.length > 0 ? (
                pendingJobData.map((offer) => (
                  <JobCard key={offer.id} offer={offer} offerId={offer}  isPending={true} isAccepted={false} isRejected={false} />
                ))
              ) : (
                <Typography sx={{ ml:{ md: 11 }}}>No Candidates available in pending</Typography>
              )}
            </Box>
          )}

          {tabValue === 1 && (
            <Box sx={{ pt: 2 }}>
              {acceptedJobData.length > 0 ? (
                acceptedJobData.map((offer) => (
                  <JobCard key={offer.id} offer={offer} offerId={offer} jobId={jobId} isPending={false} isAccepted={true} isRejected={false} />
                ))
              ) : (
                <Typography sx={{ ml:{ md: 11 }}}>No Candidates available in accepted </Typography>
              )}
            </Box>
          )}

          {tabValue === 2 && (
            <Box sx={{ pt: 2 }}>
              {rejectedJobData.length > 0 ? (
                rejectedJobData.map((offer) => (
                  <JobCard key={offer.id} offer={offer} offerId={offer} isPending={false} isAccepted={false} isRejected={true} />
                ))
              ) : (
                <Typography sx={{ ml:{ md: 11 }}}>No Candidates available in rejected </Typography>
              )}
            </Box>
          )}
 </Box>
         
  );
  
}
