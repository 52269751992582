import React from 'react';
import { Container, Grid, Typography, Button, Box, Divider, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#a3f7b5', // Light green background
    padding: theme.spacing(8, 0),
  },
  leftSection: {
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  rightSection: {
    padding: theme.spacing(4),
  },
  heading: {
    fontSize: '3.5rem',
    color: 'black',
    mt: 2
  },
  subheading: {
    fontSize: '3.5rem',
    fontWeight: 'bold',
  },
  button: {
    marginTop: theme.spacing(4),
    backgroundColor: '#00cc6a',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00b358',
    },
    borderRadius: '20px',
    padding: theme.spacing(1, 4),
  },
  featureTitle: {
    fontWeight: '500',
    marginBottom: theme.spacing(1),
    fontSize: '1.5rem',
  },
  featureDescription: {
    marginBottom: theme.spacing(3),
    fontSize: '1rem',
  },
  divider: {
    marginBottom: theme.spacing(6),
    backgroundColor: '#0a074f',
  },
}));

const features = [
  {
    title: 'OCULTA TU PERFIL A TU EMPLEADOR',
    description: 'Ten privacidad a la hora de buscar empleo',
  },
  {
    title: 'RECIBE PROPUESTAS DE EMPRESAS',
    description: 'Las empresas son las que te envían las ofertas',
  },
  {
    title: 'DECIDE SI ACEPTAS O NO LA OFERTA',
    description: 'Analiza las solicitudes que recibas y acepta las que te interesen',
  },
  {
    title: 'HAZ ENTREVISTAS Y MEJORA TUS CONDICIONES',
    description: 'Agenda entrevistas con las empresas que aceptaste',
  },
];

const FeatureSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/register'); // Change the path to your registration page
  };

  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={isSmallScreen ? 2 : 4}>
          <Grid item xs={12} md={6} className={classes.leftSection}>
            <Typography 
              className={classes.heading} 
              sx={{ fontSize: isSmallScreen ? '2.5rem' : '3.5rem' }}
            >
              Que te
            </Typography>
            <Typography 
              className={classes.heading} 
              sx={{ fontSize: isSmallScreen ? '2.5rem' : '3.5rem' }}
            >
              encuentre tu
            </Typography>
            <Typography 
              className={classes.subheading} 
              sx={{ fontSize: isSmallScreen ? '2.5rem' : '3.5rem' }}
            >
             <strong>trabajo</strong> 
            </Typography>
            <Typography 
              className={classes.subheading} 
              sx={{ fontSize: isSmallScreen ? '2.5rem' : '3.5rem' }}
            >
              <strong>soñado</strong>
            </Typography>
            <br/>
            <Button
            onClick={handleButtonClick}
              variant="outlined"
              color="primary"
              sx={{
                color: 'black',
                width: "165px",
                fontSize: "18px",
                borderColor: 'black',
                px: 3,
                borderRadius: '30px', // Adjust the value as per your design
                '&:hover': {
                  borderColor: 'black'
                }
              }}
            >
              ¡Comenzar!
            </Button>
          </Grid>
          <Grid item xs={12} md={6} className={classes.rightSection}>
            {features.map((feature, index) => (
              <Box key={index}>
                <Typography variant="h6">
                  <span className={classes.featureTitle}>{feature.title}</span>
                </Typography>
                <Typography className={classes.featureDescription}>
                  {feature.description}
                </Typography>
                <br/>
                <Divider className={classes.divider} />
                <br/>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FeatureSection;
