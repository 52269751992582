import React, { useState } from 'react';
import {
    Typography, TextField, Button, Grid, Radio, RadioGroup, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem, Box, Divider
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { JobPostApi, UploadImageApi } from "../../apis";
import { OnBoardingApi } from "../../apis";
import UploadImage from '../GlobalComponent/UploadImage';
import data from '../../components/GlobalComponent/data.json';
import ExperienceForm from '../GlobalComponent/ExperienceForm';
import DynamicAutocomplete from '../GlobalComponent/CityFinder';

const validationSchema = yup.object({
    jobDetails: yup.object().shape({
        // positionName: yup.string().required('Position name is required'),
        location: yup.string().required('Location is required'),
        salaryRange: yup.object().shape({
            from: yup.string().required('Salary is required'),
            to: yup.string()
                .required('Salary is Required')
              
        }),
       
    }),
});


const CreateJobPostStep1 = ({ formData, setFormData, nextStep }) => {
    const [selectedCategories, setSelectedCategories] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('');
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
    const [selectedSpecialty, setSelectedSpecialty] = useState('');


    const [showAutocomplete, setShowAutocomplete] = useState(false);

    // Handle change to update location and display DynamicAutocomplete if "onsite" or "hybrid"
    const handleLocationChange = (event) => {
      const { value } = event.target;
      formik.setFieldValue("jobDetails.location", value);
  
      // Show DynamicAutocomplete if location is "onsite" or "hybrid"
      setShowAutocomplete(value === "onsite" || value === "hybrid");
    };
    // const handleChipToggle = (name, checked) => {
    //     formik.setFieldValue(`jobDetails.specialties.${name}`, checked); // Correctly update Formik field value
        
    //     setSelectedCategories((prev) => {
    //         const newSelected = { ...prev };
    //         if (checked) {
    //             newSelected[name] = []; // If checked, add category with an empty subcategory list
    //         } else {
    //             delete newSelected[name]; // If unchecked, remove category
    //         }
    //         return newSelected;
    //     });
    // };
    
    // // Function to handle subcategory changes
    // const handleSubcategoryChange = (category, subcategory) => (event) => {
    //     const value = event.target.value;
    
    //     // Update Formik's state for subcategories dynamically
    //     formik.setFieldValue(`jobDetails.experienceYears.${category}.${subcategory}`, value);
        
    //     // Optionally update selected subcategories in state for UI updates
    //     setSelectedCategories((prev) => ({
    //         ...prev,
    //         [category]: {
    //             ...prev[category],
    //             [subcategory]: value, // Update the specific subcategory value
    //         },
    //     }));
    // };
    


// Handle Specialty Selection from Dropdown
const handleSpecialtyChange = (event) => {
    const specialty = event.target.value;
    setSelectedSpecialty(specialty);

    // Clear all previous specialties and set the new one to true
    const updatedSpecialities = Object.keys(formik.values.jobDetails.specialties).reduce((acc, key) => {
        acc[key] = false; // Reset all previous specialties to false
        return acc;
    }, {});

    // Update Formik's specialties state
    updatedSpecialities[specialty] = true; // Set the newly selected specialty to true
    

    formik.setFieldValue(`jobDetails.specialties`, updatedSpecialities);
};

// Handle Subcategory Chip Click
const handleChipClick = (subcategory) => {
    // Get the current experience years for the selected specialty
    const currentExperience = formik.values.jobDetails.experienceYears[selectedSpecialty] || {}; // Fixed access

    // Toggle the clicked subcategory experience
    const updatedExperience = {
        ...currentExperience,
        [subcategory]: !currentExperience[subcategory], // Toggle the current value
    };

    // Update Formik's subcategory state dynamically
    formik.setFieldValue(
        `jobDetails.experienceYears.${selectedSpecialty}`,
        updatedExperience
    );
};

    // const handleSubcategoryChange = (category, subcategory) => (event) => {
    //     const value = event.target.value;

    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         jobDetails: {
    //             ...prevFormData.jobDetails,
    //             experienceYears: {
    //                 ...prevFormData.jobDetails.experienceYears,
    //                 [category]: {
    //                     ...prevFormData.jobDetails.experienceYears[category],
    //                     [subcategory]: value
    //                 }
    //             }
    //         }
    //     }));
    // };



    const handleProfilePhotoChange = (event) => {
        const file = event.target.files[0];
        setProfilePhoto(file);
        setProfilePhotoPreview(URL.createObjectURL(file));
    };

    const handleProfilePhotoUpload = async () => {
        if (profilePhoto) {
            try {
                const formData = new FormData();
                formData.append('file', profilePhoto);

                const res = await UploadImageApi.uploadImage(formData);
                formik.setFieldValue('jobDetails.photo', res.data.data.name);
                return res.data.data.name; // Return only the name of the uploaded photo
            } catch (error) {
                console.error("Error uploading profile photo:", error);
            }
        }
        return null;
    };

    const formik = useFormik({
        initialValues: formData,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {

                const profilePhotoName = await handleProfilePhotoUpload(); // Upload the profile photo and get the URL data
                if (profilePhotoName) {
                    values.jobDetails.photo = profilePhotoName; // Set profile_photo_url to the photo name
                } 
                setFormData(values);
                nextStep();
            } catch (error) {
                // Handle API error
                console.error("Error submitting form:", error);
            }
        }
    });




    const specialtiesList = data.specialties || []; // Extract specialties from data.json
    const experienceList = data.experienceLevels || []; // Extract experience levels from data.json

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    // const handleSubcategoryChange = (subcategory, value) => {
    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         jobDetails: {
    //             ...prevFormData.jobDetails,
    //             experienceYears: {
    //                 ...prevFormData.jobDetails.experienceYears,
    //                 [selectedCategory]: {
    //                     ...prevFormData.jobDetails.experienceYears[selectedCategory],
    //                     [subcategory]: value
    //                 }
    //             }
    //         }
    //     }));
    // };

    const categories = Object.keys(formData.jobDetails.experienceYears);

    const handleInputSalaryChange = (event) => {
        const { name, value } = event.target;
        let formattedValue = value.replace(/,/g, ''); // Remove existing commas
        // Add commas to the number
        formik.setFieldValue(name, formattedValue);
    };

    const handleInputChange = (event) => {
        const { name, checked } = event.target;
        formik.setFieldValue(`jobDetails.specialties.${name}`, checked);
    };

    const handleEmploymentTypeChange = (event) => {
        const { name, checked } = event.target;

        // Set the clicked checkbox value
        formik.setFieldValue(`jobDetails.employmentType.${name}`, checked);

        // Determine the other checkbox to uncheck
        const otherEmploymentType = name === 'partTime' ? 'fullTime' : 'partTime';

        // Uncheck the other checkbox if the clicked one is checked
        if (checked) {
            formik.setFieldValue(`jobDetails.employmentType.${otherEmploymentType}`, false);
        }
    };
    const getSpecialityColor = (speciality) => {
        return speciality === "Select Speciality" ? '#aaa' : 'black';
      };
       // Safely extract true specialties
       const trueTopSpecialities = Object.entries(formik.values.jobDetails.specialties || {})
       .filter(([_, value]) => value) // Keep only entries where value is true
       .map(([key]) => key) // Extract the key (specialty name)
       .join(', '); // Join the names into a single string
       console.log("trueTopSpecialities",trueTopSpecialities);
       
       const formattedSpeciality = trueTopSpecialities
       ? trueTopSpecialities.replace(/([A-Z])/g, ' $1').trim() // Add space between words
       : "Select Speciality"; // Default value when no true speciality
       console.log("trueTopSpecialities",trueTopSpecialities);

     const specialityColor = getSpecialityColor(formattedSpeciality);  
  
     
     
    const handleExperienceChange = (event) => {
        const { name, value } = event.target;
        formik.setFieldValue(`jobDetails.experienceYears.${name}`, value);
    };

    const handleTotalExperienceChange = (event) => {
        const { name, value } = event.target;
        formik.setFieldValue(`jobDetails.${name}`, value);
    };
    const getCityColor = (city) => {
        return city === "Enter City" ? '#aaa' : 'black';
    };
    // Safely extract true specialties
    const trueTopCity = formik.values.jobDetails.city
    const formattedCity = trueTopCity
        ? trueTopCity // Add space between words
        : "Enter City"; // Default value when no true speciality

    const cityColor = getCityColor(formattedCity);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
            >
                {/* <Grid container spacing={2}>
                    <Button component="label" variant='standard' sx={{
                        '&:hover': {
                            backgroundColor: 'transparent', // Set the background color to transparent on hover
                        },
                    }}>
                        <Grid item>
                            <Box
                                sx={{
                                    width: '100px',
                                    height: '100px',
                                    border: '1px dotted black',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '15px', ml: 1, mt: 3,
                                    backgroundColor: "lightgray"
                                }}
                            >
                                {profilePhotoPreview ? (
                                    <UploadImage
                                        imageUrl={profilePhotoPreview}
                                        altText="Profile Photo"
                                    />
                                ) : (
                                    formik.values.jobDetails.photo ? (
                                        <UploadImage
                                            imageUrl={profilePhotoPreview || `${process.env.REACT_APP_API_BASE_URL}/public/image/${formik.values.jobDetails.photo}`}
                                            altText={formik.values.jobDetails.photo}
                                        />
                                    ) : (
                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                            Upload
                                        </Typography>
                                    )
                                )}

                            </Box>
                            <input type="file" hidden onChange={handleProfilePhotoChange} />
                        </Grid>
                    </Button>

                    <Grid item>
                        <Typography variant="subtitle1" sx={{ fontWeight: "600", mt: 3 }}> Photo</Typography>
                        <Typography variant="body2" sx={{ textDecoration: 'none', color: 'gray', fontSize: '11px' }}>
                            Maximum file size is up to 10MB
                        </Typography>
                    </Grid>
                </Grid> */}
            </Box>

            <Grid container spacing={2} sx={{ paddingTop: 1, paddingLeft: 1, backgroundColor: '#F5F5F5' }}>
            <Grid item xs={12} sm={12}>
  <InputLabel 
    mt={2} 
    sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
  >
    Position Name *
  </InputLabel>
  <FormControl fullWidth>
    <TextField
      placeholder="Enter Position Name"
      name="jobDetails.positionName"
      value={formik.values.jobDetails.positionName}
      onChange={formik.handleChange}
      error={formik.touched.jobDetails?.positionName && Boolean(formik.errors.jobDetails?.positionName)}
      helperText={
        formik.touched.jobDetails?.positionName && formik.errors.jobDetails?.positionName
          ? formik.errors.jobDetails?.positionName
          : null
      }
      variant="outlined"
    />
  </FormControl>
</Grid>

            <Grid item xs={12}>
                <ExperienceForm
                placeholderName="specialties"
                color={specialityColor}
                placeHolder={formattedSpeciality}
                    formik={formik}
                    specialties={formik.values.jobDetails.specialties}
                    experienceYears={formik.values.jobDetails.experienceYears}
                    selectedCategories={selectedCategories}
                    experienceList={experienceList}
                    handleChipClick={handleChipClick}
                    handleSpecialtyChange={handleSpecialtyChange}
                    selectedSpecialty={selectedSpecialty}
                    
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <InputLabel sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", marginTop: "-4px" }}>
                            Location*
                        </InputLabel>
      <FormControl fullWidth>
        <Select
          name="jobDetails.location"
          value={formik.values.jobDetails.location}
          onChange={handleLocationChange} // Use custom change handler
          onBlur={formik.handleBlur}
          displayEmpty
          renderValue={(selected) => {
            if (selected === "") {
              return <span style={{ color: "#aaa" }}>Select Location</span>;
            }
            return selected;
          }}
          error={
            formik.touched.jobDetails?.location &&
            Boolean(formik.errors.jobDetails?.location)
          }
        >
          <MenuItem value="" disabled>
            <em>Select Location</em>
          </MenuItem>
          <MenuItem value="remote">Remote</MenuItem>
          <MenuItem value="onsite">On-site</MenuItem>
          <MenuItem value="hybrid">Hybrid</MenuItem>
        </Select>
        {formik.touched.jobDetails?.location && formik.errors.jobDetails?.location ? (
          <Typography variant="body2" color="error">
            {formik.errors.jobDetails.location}
          </Typography>
        ) : null}
      </FormControl>

      {/* Render DynamicAutocomplete only if location is "onsite" or "hybrid" */}
      {showAutocomplete && (
  <>
    <InputLabel
      mt={2}
      sx={{
        fontWeight: "600",
        fontSize: "14px",
        color: "text.primary",
        ml:0.3,mt:1
      }}
      shrink={false}
    >
      Select City
    </InputLabel>
    <DynamicAutocomplete
      color={cityColor}
      placeHolder={formattedCity}
      formik={formik}
      fieldName="jobDetails.city" // Changed to "city" to avoid confusion with location
      value={formik.values.jobDetails.city} // Adjusted to reflect a city field
      // Uncomment fetchOptions to load city options dynamically
      // fetchOptions={fetchCities}
    />
  </>
)}

    </Grid>
{/*   {/* Total Years of Experience */}
<Grid item xs={12} sm={6}>
                    <Box mt={2}>
                        <InputLabel sx={{ fontWeight: "600", fontSize: "15.5px", color: "text.primary", marginTop: "-20px" }}>
                            Total Years Of Experience*
                        </InputLabel>
                        <FormControl fullWidth>
                            <Select
                                name="totalYearsExperience"
                                value={formik.values.jobDetails.totalYearsExperience}
                                onChange={handleTotalExperienceChange}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return <span style={{ color: '#aaa' }}>Select Total Experience</span>;
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem value="" disabled>
                                    <em>Select Total Experience</em>
                                </MenuItem>
                                {experienceList.map((experience) => (
                                    <MenuItem key={experience} value={experience}>
                                        {experience} years
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.jobDetails?.totalYearsExperience && formik.errors.jobDetails?.totalYearsExperience && (
                                <Typography variant="body2" color="error">
                                    {formik.errors.jobDetails.totalYearsExperience}
                                </Typography>
                            )}
                        </FormControl>
                    </Box>
                </Grid>

            {/* <Grid item xs={12} sm={6}>
              <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
              Position name *
              </InputLabel>
              <FormControl fullWidth>
                <Select
                   placeholder="Enter Position Name"
                  name="jobDetails.positionName"
                  value={formik.values.jobDetails.positionName}
                  onChange={formik.handleChange}
                  error={formik.touched.preferences?.professional_area && Boolean(formik.errors.preferences?.professional_area)}
                  displayEmpty
                  renderValue={(selected) => selected || <span style={{ color: '#aaa' }}>Select Position name</span>}
                >

                  {specialtiesList.map((design) => (
                    <MenuItem key={design} value={design}>
                      {design}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.jobDetails?.positionName && formik.errors.jobDetails?.positionName && (
                  <Typography variant="caption" color="error">
                    {formik.errors.jobDetails?.positionName}
                  </Typography>
                )}
              </FormControl>
            </Grid>  */}
 
 
                <Grid item xs={12}>
                    <InputLabel mt={3} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Employment Type
                    </InputLabel>
                    <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="partTime"
                                    checked={formik.values.jobDetails.employmentType.partTime}
                                    onChange={handleEmploymentTypeChange}
                                    sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                                />
                            }
                            label="Part Time"
                            sx={{ mr: 5, mb: 1 }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="fullTime"
                                    checked={formik.values.jobDetails.employmentType.fullTime}
                                    onChange={handleEmploymentTypeChange}
                                    sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                                />
                            }
                            label="Full Time"
                            sx={{ mr: 5, mb: 1 }}
                        />
                    </Box>

                    {formik.touched.jobDetails?.employmentType && formik.errors.jobDetails?.employmentType && (
                        <Typography variant="body2" color="error">
                            {formik.errors.jobDetails.employmentType}
                        </Typography>
                    )}
                </Grid>
                
                <Grid item xs={12}>
                <InputLabel
    mt={2}
    sx={{
        fontWeight: "600",
        fontSize: { xs: "12px", sm: "16px" },
        color: "text.primary",
        whiteSpace: { xs: "normal", sm: "nowrap" }, // Allow wrapping on small screens
        overflow: "hidden", // Ensure no overflow
        textOverflow: "ellipsis", // Add ellipsis for long text if necessary
    }}
>
Would you consider remote candidates?
</InputLabel>
    <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <RadioGroup
            aria-label="remoteCandidates"
            name="jobDetails.remoteCandidates"
            value={formik.values.jobDetails.remoteCandidates}
            onChange={formik.handleChange}
            sx={{ flexDirection: { xs: 'column', sm: 'row' }, marginTop: { xs: 1, sm: 0 } }} // Stack on small screens
        >
            <FormControlLabel
                value="Yes"
                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                label="Yes"
                sx={{ marginRight: { sm: '20px' }, marginBottom: { xs: 1, sm: 0 } }} // Adjust margin for small screens
            />
            <FormControlLabel
                value="No"
                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                label="No"
                sx={{ marginRight: { sm: '20px' }, marginBottom: { xs: 1, sm: 0 } }} // Adjust margin for small screens
            />
            <FormControlLabel
                value="Remote only"
                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                label="Remote only"
                sx={{ marginBottom: { xs: 1, sm: 0 } }} // Adjust margin for small screens
            />
        </RadioGroup>
    </FormControl>
</Grid>

<Grid item xs={12}>
<InputLabel
    mt={2}
    sx={{
        fontWeight: "600",
        fontSize: { xs: "12px", sm: "16px" },
        color: "text.primary",
        whiteSpace: { xs: "normal", sm: "nowrap" }, // Allow wrapping on small screens
        overflow: "hidden", // Ensure no overflow
        textOverflow: "ellipsis", // Add ellipsis for long text if necessary
    }}
>
    Would you consider candidates willing to relocate?
</InputLabel>
    <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <RadioGroup
            aria-label="willingToRelocate"
            name="jobDetails.willingToRelocate"
            value={formik.values.jobDetails.willingToRelocate}
            onChange={formik.handleChange}
            sx={{ flexDirection: { xs: 'column', sm: 'row' }, marginTop: { xs: 1, sm: 0 } }} // Stack on small screens
        >
            <FormControlLabel
                value="Yes"
                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                label="Yes"
                sx={{ marginRight: { sm: '20px' }, marginBottom: { xs: 1, sm: 0 } }} // Adjust margin for small screens
            />
            <FormControlLabel
                value="No"
                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                label="No"
                sx={{ marginRight: { sm: '20px' }, marginBottom: { xs: 1, sm: 0 } }} // Adjust margin for small screens
            />
        </RadioGroup>
    </FormControl>
</Grid>

                <Grid item xs={12} sm={6}>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                        Specify the salary range *
                    </InputLabel>
                    <TextField
                    type="number"
                        fullWidth
                        placeholder="From"
                        name="jobDetails.salaryRange.from"
                        value={formik.values.jobDetails.salaryRange.from}
                        onChange={handleInputSalaryChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.jobDetails?.salaryRange?.from && Boolean(formik.errors.jobDetails?.salaryRange?.from)}
                        helperText={formik.touched.jobDetails?.salaryRange?.from && formik.errors.jobDetails?.salaryRange?.from}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                        To *
                    </InputLabel>
                    <TextField
                    type="number"
                        fullWidth
                        placeholder="To"
                        name="jobDetails.salaryRange.to"
                        value={formik.values.jobDetails.salaryRange.to}
                        onChange={handleInputSalaryChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.jobDetails?.salaryRange?.to && Boolean(formik.errors.jobDetails?.salaryRange?.to)}
                        helperText={formik.touched.jobDetails?.salaryRange?.to && formik.errors.jobDetails?.salaryRange?.to}
                    />
                </Grid>

              
              

                <Grid item xs={12}>
                    <Divider />
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: '20px' }}>
                        <Button
                            type="Submit"
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: 'secondary.main',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'secondary.dark'
                                },
                                marginLeft: 'auto'
                            }}

                        >
                            <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'text.primary', padding: "2px" }}>
                                Next: Requirements
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

export default CreateJobPostStep1;