import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Divider,
  Button,
  RadioGroup, Radio
} from "@mui/material";
import RadioButton from "../../../../components/GlobalComponent/RadioButtons";
import { useNavigate } from "react-router-dom";
import { OnBoardingApi } from "../../../../apis";
import ExperienceForm from "../../../../components/GlobalComponent/ExperienceForm";

const Preferences = ({ formik }) => {
  const [loading, setLoading] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedSpecialty, setSelectedSpecialty] = useState('');

  // Handle Specialty Selection from Dropdown
  const handleSpecialtyChange = (event) => {
    const specialty = event.target.value;
    setSelectedSpecialty(specialty);

    // Clear all previous specialties and set the new one to true
    const updatedSpecialities = Object.keys(formik.values.preferences.top_specialities).reduce((acc, key) => {
      acc[key] = false; // Reset all previous specialties to false
      return acc;
    }, {});
    // Update Formik's specialties state
    updatedSpecialities[specialty] = true; // Set the newly selected specialty to true

    formik.setFieldValue(`preferences.top_specialities`, updatedSpecialities);

  };

  // Handle Subcategory Chip Click
  const handleChipClick = (subcategory) => {
    // Get the current experience years for the selected specialty
    const currentExperience = formik.values.preferences.experienceYears[selectedSpecialty] || {};

    // Toggle the clicked subcategory experience
    const updatedExperience = {
      ...currentExperience,
      [subcategory]: !currentExperience[subcategory], // Toggle the current value
    };

    // Update Formik's subcategory state dynamically
    formik.setFieldValue(
      `preferences.experienceYears.${selectedSpecialty}`,
      updatedExperience
    );
  };

  useEffect(() => {
    const fetchOnBoardedUserData = async () => {
      try {
        const res = await OnBoardingApi.getOnBoarding();
        function multiParse(input) {
          let result = input;
          try {
            while (typeof result === 'string') {
              result = JSON.parse(result);
            }
          } catch (e) {
            console.error('Error parsing input:', e);
          }
          return result;
        }
        if (res.data && res.data.profile) {
          


          const parsedTopSpecialities = multiParse(res?.data?.profile?.top_specialities || {});
          const parsedEmployementType = multiParse(res?.data?.profile?.employment_type || {});
          const salaryRange = multiParse(res?.data?.profile?.salary_range || '{}');
          const workExperience = multiParse((res?.data?.profile?.work_experience) || '[]');
          const education = multiParse((res?.data?.profile?.education) || '[]');
          const languages = multiParse((res?.data?.profile?.languages) || '[]');
          const experienceYears = multiParse((res?.data?.profile?.experienceYears) || '[]');

          const remoteWorkValue = res?.data?.profile?.remote_work ? "1" : "0";
          const relocationValue = res?.data?.profile?.relocation ? "1" : "0";
          const trueTopSpecialities = Object.entries(parsedTopSpecialities)
            .filter(([_, value]) => value) // Keep only entries where value is true
            .map(([key]) => key) // Extract the key (specialty name)
            .join(', '); // Join the names into a single string

          // Result
          formik.setValues({
            ...formik.values,
            preferences: {
              professional_area: trueTopSpecialities || '',
              total_years_experience: res?.data?.profile.total_years_experience || '',
              top_specialities: {
                PublicAdministration: parsedTopSpecialities.PublicAdministration || false,
                BusinessAdministration: parsedTopSpecialities.BusinessAdministration || false,
                QualityProductionAndRD: parsedTopSpecialities.QualityProductionAndRD || false,
                CommercialAndSales: parsedTopSpecialities.CommercialAndSales || false,
                PurchasingLogisticsAndWarehouse: parsedTopSpecialities.PurchasingLogisticsAndWarehouse || false,
                DesignAndGraphicArts: parsedTopSpecialities.DesignAndGraphicArts || false,
                EducationAndTraining: parsedTopSpecialities.EducationAndTraining || false,
                FinanceAndBanking: parsedTopSpecialities.FinanceAndBanking || false,
                ITAndTelecommunications: parsedTopSpecialities.ITAndTelecommunications || false,
                EngineersAndTechnicians: parsedTopSpecialities.EngineersAndTechnicians || false,
                RealEstateAndConstruction: parsedTopSpecialities.RealEstateAndConstruction || false,
                Legal: parsedTopSpecialities.Legal || false,
                MarketingAndCommunication: parsedTopSpecialities.MarketingAndCommunication || false,
                ProfessionsArtsAndCrafts: parsedTopSpecialities.ProfessionsArtsAndCrafts || false,
                Automotive: parsedTopSpecialities.Automotive || false,
                ConsumptionAndFood: parsedTopSpecialities.ConsumptionAndFood || false,
                SecurityAndSurveillance: parsedTopSpecialities.SecurityAndSurveillance || false,
                HumanResources: parsedTopSpecialities.HumanResources || false,
                HealthcareAndMedicine: parsedTopSpecialities.HealthcareAndMedicine || false,
                TourismAndHospitality: parsedTopSpecialities.TourismAndHospitality || false,
                Sales: parsedTopSpecialities.Sales || false

              },
              experienceYears: experienceYears || {},
              employment_type: {
                fullTime: parsedEmployementType?.fullTime || false,
                partTime: parsedEmployementType?.partTime || false,
              },
              job_search_status: res?.data?.profile?.job_search_status || '',
              remote_work: remoteWorkValue || "",
              relocation: relocationValue || "",
            },
            about_you: {
              resume_url: res?.data?.profile.resume_url || '',
              profile_photo_url: res?.data?.profile?.profile_photo_url || '',
              full_name: res?.data?.profile?.full_name || '',
              phone_number: res?.data?.profile?.phone_number || '',
              whatsapp_number: res?.data?.profile?.whatsapp_number || '',
              current_city: res?.data?.profile?.current_city || '',
              salary_range: {
                from: salaryRange?.from || '',
                to: salaryRange?.to || '',
              },
              linkedin_url: res?.data?.profile?.linkedin_url || '',
              personal_blog_url: res?.data?.profile?.personal_blog_url || '',
            },
            background: {

              work_experience: workExperience?.length > 0 ? workExperience : [
                {
                  company_name: workExperience?.company_name || '',
                  job_title: workExperience?.job_title || '',
                  is_current: workExperience?.is_current || false,
                  start: workExperience?.start || '',
                  end: workExperience?.end || '',
                  description: workExperience?.description || '',
                }
              ],
              education: education?.length > 0 ? education : [
                {
                  university_or_collage: education?.university_or_collage || '',
                  graduation_year: education?.graduation_year || '',
                  field_of_study: education?.field_of_study || '',
                  degree: education?.degree || '',
                }
              ],
              languages: languages?.length > 0 ? languages : [
                {
                  language: languages?.language || '',
                  language_level: languages?.language_level || '',
                }
              ],
              objective: res?.data?.profile?.objective || '',
              achievements: res?.data?.profile?.achievements || '',
              personal_characteristics: res?.data?.profile?.personal_characteristics || '',
            },


          });
        } else {
          console.log("No data available in the database");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching on-boarded user data:", error);
        setLoading(false);
      }
    };

    fetchOnBoardedUserData();
  }, []);
  const navigate = useNavigate();
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


  const submitForm = async (values) => {
    try {

      // Convert nested objects to JSON strings
      const updatedValues = {
        ...values,
        preferences: {
          ...values.preferences,
          top_specialities: values.preferences?.top_specialities ? JSON.stringify(values.preferences.top_specialities) : '{}',
          employment_type: values.preferences?.employment_type ? JSON.stringify(values.preferences.employment_type) : {},
        },
        background: {
          ...values.background,
          work_experience: JSON.stringify(values.background.work_experience),
          education: JSON.stringify(values.background.education),
          languages: JSON.stringify(values.background.languages),
        },
        about_you: {
          ...values.about_you,
          salary_range: JSON.stringify(values.about_you.salary_range),
        }
      };

      const res = await OnBoardingApi.OnBoarding(updatedValues);


    } catch (err) {
      console.error(err);
    }
  };

  const getSpecialityColor = (speciality) => {
    return speciality === "Select Speciality" ? '#aaa' : 'black';
  };
  const trueTopSpecialities = Object.entries(formik.values.preferences.top_specialities)
  .filter(([_, value]) => value) // Keep only entries where value is true
  .map(([key]) => key) // Extract the key (specialty name)
  .join(', '); // Join the names into a single string

const formattedSpeciality = trueTopSpecialities
  ? trueTopSpecialities.replace(/([A-Z])/g, ' $1').trim() // Add space between words
  : "Select Speciality"; // Default value when no true speciality

const specialityColor = getSpecialityColor(formattedSpeciality);  
 

  const handleEmploymentTypeChange = (event) => {
    const { name, checked } = event.target;

    // Set the clicked checkbox value
    formik.setFieldValue(`preferences.employment_type.${name}`, checked);

    // Determine the other checkbox to uncheck
    const otherEmploymentType = name === 'partTime' ? 'fullTime' : 'partTime';

    // Uncheck the other checkbox if the clicked one is checked
    if (checked) {
      formik.setFieldValue(`preferences.employment_type.${otherEmploymentType}`, false);
    }
  };
  const handleInputChange = (event) => {
    const { name, checked } = event.target;
    formik.setFieldValue(`preferences.top_specialities.${name}`, checked);
  };


  const handleChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };

  const experienceList = ["Less than 1 year", "1-3 years", "3-5 years", "5+ years"];
  const designList = ["Software Engineer", "Business Analyst", "Product Manager"];
  const jobStatusList = ["Not seeking to change jobs, but may consider", "Actively looking for a job", "Currently employed and not looking"];

  return (
    <Box>
      <Box
        sx={{
          background: "#FFFFFF",
          borderRadius: "14px",
          paddingLeft: "150px",
          paddingRight: "150px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography ml={1} sx={{ fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
            Qualification
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              {/* <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                Professional area *
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  name="preferences.professional_area"
                  value={formik.values.preferences.professional_area}
                  onChange={handleChange}
                  error={formik.touched.preferences?.professional_area && Boolean(formik.errors.preferences?.professional_area)}
                  displayEmpty
                  renderValue={(selected) => selected || <span style={{ color: '#aaa' }}>Select Professional Area</span>}
                >

                  {designList.map((design) => (
                    <MenuItem key={design} value={design}>
                      {design}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.preferences?.professional_area && formik.errors.preferences?.professional_area && (
                  <Typography variant="caption" color="error">
                    {formik.errors.preferences.professional_area}
                  </Typography>
                )}
              </FormControl> */}

              <ExperienceForm
                color={specialityColor}
                placeHolder={formattedSpeciality}
                formik={formik}
                specialties={formik.values.preferences.top_specialities}
                experienceYears={formik.values.preferences.experienceYears}
                selectedCategories={selectedCategories}
                experienceList={experienceList}
                handleChipClick={handleChipClick}
                handleSpecialtyChange={handleSpecialtyChange}
                selectedSpecialty={selectedSpecialty}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                Total Years Of Experience
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  name="preferences.total_years_experience"
                  value={formik.values.preferences.total_years_experience}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) => selected || <span style={{ color: '#aaa' }}>Select Experience</span>}
                >

                  {experienceList.map((experience) => (
                    <MenuItem key={experience} value={experience}>
                      {experience}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.preferences?.total_years_experience && formik.errors.preferences?.total_years_experience && (
                  <Typography variant="caption" color="error">
                    {formik.errors.preferences.total_years_experience}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box>



        </Box>

        <Box>
          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
            Job Search Status
          </Typography>
          <Grid item xs={12}>
            <InputLabel mt={3} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
              Employment Type
            </InputLabel>
            <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="partTime"
                    checked={formik.values.preferences.employment_type.partTime}
                    onChange={handleEmploymentTypeChange}
                    sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                  />
                }
                label="Part Time"
                sx={{ mr: 5, mb: 1 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="fullTime"
                    checked={formik.values.preferences.employment_type.fullTime}
                    onChange={handleEmploymentTypeChange}
                    sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                  />
                }
                label="Full Time"
                sx={{ mr: 5, mb: 1 }}
              />
            </Box>

            {formik.touched.preferences?.employment_type && formik.errors.preferences?.employment_type && (
              <Typography variant="body2" color="error">
                {formik.errors.preferences.employment_type}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={2}>
            <InputLabel mt={3} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
              Where are you in your job search? *
            </InputLabel>
            <FormControl fullWidth>
              <Select
                name="preferences.job_search_status"
                value={formik.values.preferences.job_search_status}
                onChange={handleChange}
                error={formik.touched.preferences?.job_search_status && Boolean(formik.errors.preferences?.job_search_status)}
                displayEmpty
                renderValue={(selected) => selected || <span style={{ color: '#aaa' }}>Select Status</span>}
              >
                <MenuItem value="" disabled>
                  <em>Select Status</em>
                </MenuItem>
                {jobStatusList.map((jobStatus) => (
                  <MenuItem key={jobStatus} value={jobStatus}>
                    {jobStatus}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.preferences?.job_search_status && formik.errors.preferences?.job_search_status && (
                <Typography variant="caption" color="error">
                  {formik.errors.preferences.job_search_status}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Box>

        <Box>
          <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
            Location
          </Typography>

          <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
            Are you interested in working remotely?
          </InputLabel>
          <Grid item xs={12}>
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column' }}>
              <RadioGroup
                aria-label="remoteCandidates"
                name="preferences.remote_work"
                value={formik.values.preferences.remote_work}
                onChange={(event) => {
                  formik.setFieldValue("preferences.remote_work", event.target.value);
                }}
                sx={{ flexDirection: 'row' }}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                  label="Yes"
                  sx={{ marginRight: '20px' }}
                />
                <FormControlLabel
                  value="0"
                  control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                  label="No"
                  sx={{ marginRight: '20px' }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
            Are you interested in relocation?
          </InputLabel>
          <Grid item xs={12}>
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column' }}>
              <RadioGroup
                aria-label="relocation"
                name="preferences.relocation"
                value={formik.values.preferences.relocation}
                onChange={(event) => {
                  formik.setFieldValue("preferences.relocation", event.target.value);
                }}
                sx={{ flexDirection: 'row' }}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                  label="Yes"
                  sx={{ marginRight: '20px' }}
                />
                <FormControlLabel
                  value="0"
                  control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                  label="No"
                  sx={{ marginRight: '20px' }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Box>

        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button
            type="submit"
            variant="standard"
            onClick={async () => {
              const errors = await formik.validateForm();


              if (Object.keys(errors).length === 0) {
                try {
                  await submitForm(formik.values);

                  // Add a delay before navigating
        

        // Navigate to the next page with form values
        navigate("/v1/employee/about-you", { state: { formikValues: formik.values } });
                } catch (error) {
                  console.error("Error submitting form:", error);
                }
              } else {
                formik.setTouched({
                  preferences: {
                    professional_area: true,
                    total_years_experience: true,
                    brand_graphic_designer_experience: true,
                    product_designer_experience: true,
                    job_search_status: true,
                  },
                });
              }
            }}
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: 'secondary.main',
              color: 'white',
              '&:hover': { backgroundColor: 'secondary.main' },
              marginLeft: 'auto'
            }}
          >
            <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
              Next: About You
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Preferences;