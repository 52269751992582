import React, { useState } from 'react';
import { Typography, TextField, Button, Grid, FormControl, InputLabel, Select, MenuItem, Box, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { JobPostApi } from "../../apis";

const CreateJobPostStep2 = ({ formData, setFormData, backStep, nextStep }) => {

    // Validation schema for Formik
    const validationSchema = yup.object().shape({
        requirements: yup.object().shape({
            education: yup.string().required('Education is required'),
            languages: yup.array().of(
                yup.object().shape({
                    language: yup.string().required('Language is required'),
                    level: yup.string().required('Level is required')
                })
            )
        })
    });

    // Formik instance for managing form state and validation
    const formik = useFormik({
        initialValues: formData,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                // const res = await JobPostApi.createJobPost(values);
                // // Update state or perform other actions based on API response
                // console.log("Form submitted successfully:", res);
                setFormData(values);
                nextStep();
            } catch (error) {
                // Handle API error
                console.error("Error submitting form:", error);
            }
        }
    });

    // Handle dynamic adding and removing of languages
    const [languages, setLanguages] = useState(formData.requirements.languages || [{ language: '', level: '' }]);
    const [addedLanguages, setAddedLanguages] = useState([false]);

    const handleEducationChange = (event) => {
        const { value } = event.target;
        formik.setFieldValue('requirements.education', value);
        setFormData({
            ...formData,
            requirements: {
                ...formData.requirements,
                education: value
            }
        });
    };


    const handleLanguageChange = (index, field, value) => {
        const updatedLanguages = [...languages];
        updatedLanguages[index][field] = value;
        setLanguages(updatedLanguages);
        formik.setFieldValue(`requirements.languages[${index}].${field}`, value);
    };

    const handleAddLanguage = () => {
        setLanguages([...languages, { language: "", level: "" }]);
        setAddedLanguages([...addedLanguages, true]);
        formik.setFieldValue(`requirements.languages[${languages.length}].language`, "");
        formik.setFieldValue(`requirements.languages[${languages.length}].level`, "");
    };

    const handleRemoveLanguage = (index) => {
        const updatedLanguages = [...languages];
        updatedLanguages.splice(index, 1);
        setLanguages(updatedLanguages);

        const updatedAddedLanguages = [...addedLanguages];
        updatedAddedLanguages.splice(index, 1);
        setAddedLanguages(updatedAddedLanguages);

        // Update Formik values when removing language
        formik.setFieldValue(`requirements.languages`, updatedLanguages);
    };


    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} sx={{ paddingTop: 1, paddingLeft: 1, backgroundColor: '#F5F5F5' }}>
                <Grid item xs={12}>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
                        Education *
                    </InputLabel>
                    <FormControl fullWidth>
                        <Select
                            name="requirements.education"
                            value={formik.values.requirements.education}
                            onChange={handleEducationChange}
                            onBlur={formik.handleBlur}
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected === "") {
                                    return <span style={{ color: '#aaa' }}>Select Degree</span>;
                                }
                                return selected;
                            }}
                        >
                            <MenuItem value="" disabled>
                                <em>Select Degree</em>
                            </MenuItem>
                            <MenuItem value="bachelor">Bachelor's Degree</MenuItem>
                            <MenuItem value="master">Master's Degree</MenuItem>
                            <MenuItem value="doctorate">Doctorate Degree</MenuItem>
                        </Select>
                        {formik.touched.requirements?.education && formik.errors.requirements?.education ? (
                            <Typography variant="body2" color="error">
                                {formik.errors.requirements.education}
                            </Typography>
                        ) : null}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            {languages.map((language, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                            Language *
                                        </InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                name={`requirements.languages[${index}].language`}
                                                value={language.language}
                                                onChange={(e) => handleLanguageChange(index, "language", e.target.value)}
                                                onBlur={formik.handleBlur}
                                                renderValue={(selected) => {
                                                    if (selected === "") {
                                                        return <span style={{ color: '#aaa' }}>Select Language</span>;
                                                    }
                                                    return selected;
                                                }}
                                                displayEmpty
                                            >
                                                <MenuItem value="" disabled>
                                                    <em>Select Language</em>
                                                </MenuItem>
                                                <MenuItem value="English">English</MenuItem>
                                                <MenuItem value="Spanish">Spanish</MenuItem>
                                                <MenuItem value="French">French</MenuItem>
                                                <MenuItem value="German">German</MenuItem>
                                                <MenuItem value="Italian">Italian</MenuItem>
                                                <MenuItem value="Portuguese">Portuguese</MenuItem>
                                                <MenuItem value="Mandarin Chinese">Mandarin Chinese</MenuItem>
                                                <MenuItem value="Japanese">Japanese</MenuItem>
                                                <MenuItem value="Russian">Russian</MenuItem>
                                                <MenuItem value="Arabic">Arabic</MenuItem>
                                            </Select>
                                            {formik.touched.requirements?.languages?.[index]?.language && formik.errors.requirements?.languages?.[index]?.language ? (
                                                <Typography variant="body2" color="error">
                                                    {formik.errors.requirements.languages[index].language}
                                                </Typography>
                                            ) : null}

                                        </FormControl>
                                        {/* <TextField
                                            placeholder="Select Language"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: false,
                                                style: {
                                                    fontSize: '14px',
                                                    color: 'gray'
                                                }
                                            }}
                                            fullWidth
                                            value={language.language}
                                            onChange={(e) => handleLanguageChange(index, "language", e.target.value)}
                                            sx={{ mb: 2 }}
                                        />
                                        {formik.touched.requirements?.languages?.[index]?.language && formik.errors.requirements?.languages?.[index]?.language ? (
                                            <Typography variant="body2" color="error">
                                                {formik.errors.requirements.languages[index].language}
                                            </Typography>
                                        ) : null} */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                            Level *
                                        </InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                name={`requirements.languages[${index}].level`}
                                                value={language.level}
                                                onChange={(e) => handleLanguageChange(index, "level", e.target.value)}
                                                onBlur={formik.handleBlur}
                                                displayEmpty
                                                renderValue={(selected) => {
                                                    if (selected === "") {
                                                        return <span style={{ color: '#aaa' }}>Select Level</span>;
                                                    }
                                                    return selected;
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    <em>Select Level</em>
                                                </MenuItem>
                                                <MenuItem value="Beginner">Beginner</MenuItem>
                                                <MenuItem value="Intermediate">Intermediate</MenuItem>
                                                <MenuItem value="Advanced">Advanced</MenuItem>
                                                <MenuItem value="Native">Native</MenuItem>
                                            </Select>
                                            {formik.touched.requirements?.languages?.[index]?.level && formik.errors.requirements?.languages?.[index]?.level ? (
                                                <Typography variant="body2" color="error">
                                                    {formik.errors.requirements.languages[index].level}
                                                </Typography>
                                            ) : null}

                                        </FormControl>
                                        {/* <TextField
                                            InputLabelProps={{
                                                shrink: false,
                                                style: {
                                                    fontSize: '14px',
                                                    color: 'gray'
                                                }
                                            }}
                                            placeholder="Select Level"
                                            variant="outlined"
                                            fullWidth
                                            value={language.level}
                                            onChange={(e) => handleLanguageChange(index, "level", e.target.value)}
                                            sx={{ mb: 2 }}
                                        />
                                        {formik.touched.requirements?.languages?.[index]?.level && formik.errors.requirements?.languages?.[index]?.level ? (
                                            <Typography variant="body2" color="error">
                                                {formik.errors.requirements.languages[index].level}
                                            </Typography>
                                        ) : null} */}
                                    </Grid>
                                    {addedLanguages[index] && ( // Only show remove button for added languages
                                        <Grid item xs={12}>
                                            <Button
                                                onClick={() => handleRemoveLanguage(index)}
                                                variant="standard"
                                                sx={{
                                                    mb: 2, backgroundColor: 'secondary.main', color: 'text.secondary', '&:hover': {
                                                        backgroundColor: 'secondary.main'
                                                    }
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            ))}
                            <Grid item xs={12}>
                                <Button
                                    type="button"
                                    onClick={handleAddLanguage}
                                    variant="standard"
                                    sx={{
                                        backgroundColor: '#F5F5F5',
                                        color: 'primary.main',
                                        '&:hover': {
                                            backgroundColor: '#F5F5F5'
                                        }
                                    }}
                                >
                                    <AddIcon sx={{ mt: -0.5, ml: -3, color: 'primary.main' }} />
                                    <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", backgroundColor: "#F5F5F5" }}>
                                        Add More
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <Button
                                type="button"
                                onClick={backStep}
                                variant="outlined"
                                sx={{
                                    mt: 3, mb: 2, backgroundColor: 'white', borderColor: 'primary.main', color: 'text.secondary', '&:hover': {
                                        backgroundColor: 'text.secondary'
                                    }
                                }}
                            >
                                <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                                    Back
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                variant="standard"
                                sx={{
                                    mt: 3, mb: 2, backgroundColor: 'secondary.main', color: 'white', '&:hover': {
                                        backgroundColor: 'secondary.main'
                                    }
                                }}
                            >
                                <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'text.primary', padding: "2px" }}>
                                    Next: Description
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default CreateJobPostStep2;
