import React from "react";
import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import Main from "../Pages/Main";
import Login from '../Pages/Auth/login';
import Register from "../Pages/Auth/register";
import LandingPage from "../Pages/Landing";
import JobProvider from "../context/JobProvider";

function isAuthenticated() {
  return localStorage.getItem('token') !== null;
}

export default function AppRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />z
        <Route path="/register" element={<Register />} />
        {/* Protected routes */}
        {isAuthenticated() ? (
          <Route path="/v1/*" element={<Main />} />
        ) : (
          <Route path="/v1/*" element={<Navigate to="/login" replace />} />
        )}
        {/* Catch-all for unknown routes */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
