import React, { useState } from 'react';
import { Container, Stepper, Typography } from '@mui/material';
import CreateJobPostStep1 from '../../../../components/JobPost/Step1';
import CreateJobPostStep2 from '../../../../components/JobPost/Step2';
import CreateJobPostStep3 from '../../../../components/JobPost/Step3';
import NavBar from '../../../../components/Global/NavBar';
import Footer from '../../../../components/Global/Footer';

const steps = ['Job Details', 'Requirements', 'Description'];
const stepTexts = [
    'Job Details',
    'Requirements',
    'Description'
];

const CreateJobPost = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        jobDetails: {
            photo: '',
            positionName: '',
            location: '',
            city:'',
            employmentType: {
                fullTime: false,
                partTime: false,
            },
            remoteCandidates: 'no',
            willingToRelocate: false,
            salaryRange: {
                from: '',
                to: ''
            },
            specialties: {
                PublicAdministration: false,
                BusinessAdministration: false,
                QualityProductionAndRD: false,
                CommercialAndSales: false,
                PurchasingLogisticsAndWarehouse: false,
                DesignAndGraphicArts: false,
                EducationAndTraining: false,
                FinanceAndBanking: false,
                ITAndTelecommunications: false,
                EngineersAndTechnicians: false,
                RealEstateAndConstruction: false,
                Legal: false,
                MarketingAndCommunication: false,
                ProfessionsArtsAndCrafts: false,
                Automotive: false,
                ConsumptionAndFood: false,
                SecurityAndSurveillance: false,
                HumanResources: false,
                HealthcareAndMedicine: false,
                TourismAndHospitality: false,
                Sales: false,
            },
             experienceYears : {
                PublicAdministration: {
                    EuropeanAdministration: false,
                    AutonomousAdministration: false,
                    StateAdministration: false,
                    LocalAdministration: false,
                    SecurityForces: false, 
                    PublicEnterprises: false,
                },
                BusinessAdministration: {
                    Administration: false,
                    Auditing: false,
                    Consulting: false,
                    Management: false,
                    BillingCollectionsAndPayments: false,
                    FinanceAndAccounting: false,
                    NegotiationAndContracting: false,
                    CorporateOperations: false,
                    CompanyOrganization: false,
                    Reception: false,
                    Secretarial: false,
                    CustomerService: false,
                },
                QualityProductionAndRD: {
                    QualityCertification: false,
                    QualityManagement: false,
                    ProjectManagement: false,
                    ResearchAndDevelopment: false,
                    Maintenance: false,
                    Environment: false,
                    Metrology: false,
                    Operations: false,
                },
                CommercialAndSales: {
                    CommercialAgent: false,
                    SalesRepresentative: false,
                    ForeignTrade: false,
                    KeyAccount: false,
                    TelemarketingAndTelephoneSales: false,
                },
                PurchasingLogisticsAndWarehouse: {
                    Warehouse: false,
                    PurchasingAndProcurement: false,
                    DistributionAndLogistics: false,
                },
                DesignAndGraphicArts: {
                    AudiovisualCreation: false,
                    GraphicDesign: false,
                    IndustrialDesign: false,
                    WebDesign: false,
                    PublishingAndPrinting: false,
                    FashionAndTailoring: false,
                },
                EducationAndTraining: {
                    SpecialEducation: false,
                    SocialEducation: false,
                    Teaching: false,
                    EducationalCenterManagement: false,
                    Pedagogy: false,
                    Childcare: false,
                },
                FinanceAndBanking: {
                    CorporateBanking: false,
                    PrivateBanking: false,
                    StockMarketSecuritiesAndInvestment: false,
                    BankingProductsAndServices: false,
                    Insurance: false,
                },
                ITAndTelecommunications: {
                    DatabaseAdministration: false,
                    Analysis: false,
                    Architecture: false,
                    Quality: false,
                    ERPCRMBusinessIntelligence: false,
                    ProjectManagement: false,
                    HardwareNetworksAndSecurity: false,
                    Helpdesk: false,
                    Programming: false,
                    Systems: false,
                    Telecommunications: false,
                },
                EngineersAndTechnicians: {
                    Aerospace: false,
                    AgronomyAndForestry: false,
                    Electronics: false,
                    IndustrialElectronicsAndAutomation: false,
                    RenewableEnergies: false,
                    GeologyGeodesyAndCartography: false,
                    Industrial: false,
                    Mining: false,
                    NavalAndOceanEngineering: false,
                    IndustrialOrganization: false,
                    Chemistry: false,
                    DefenseSystems: false,
                },
                RealEstateAndConstruction: {
                    RealEstateAdministration: false,
                    ArchitectureAndDesign: false,
                    ProjectAndConstructionManagement: false,
                    CivilEngineeringAndPublicWorks: false,
                    MaterialsEngineering: false,
                    InteriorDesign: false,
                    ConstructionTrades: false,
                },
                Legal: {
                    CivilCriminalAndConstitutionalLaw: false,
                    InternationalLaw: false,
                    ProceduralLaw: false,
                    BusinessLaw: false,
                    NotaryAndRegistries: false,
                },
                MarketingAndCommunication: {
                    CorporateCommunication: false,
                    BrandAndProductDevelopment: false,
                    MarketResearch: false,
                    Marketing: false,
                    DigitalMarketing: false,
                    JournalismAndEditing: false,
                    Production: false,
                    Advertising: false,
                    PublicRelationsAndEvents: false,
                    AudiovisualTechnicians: false,
                },
                ProfessionsArtsAndCrafts: {
                    AgricultureAndGardening: false,
                    PerformingArts: false,
                    PlasticArts: false,
                    Craftsmanship: false,
                },
                Automotive: {
                    Carpentry: false,
                    Tailoring: false,
                },
                ConsumptionAndFood: {
                    Electricity: false,
                    BeautyAndCosmetology: false,
                    Plumbing: false,
                    Photography: false,
                    Cleaning: false,
                },
                SecurityAndSurveillance: {
                    TranslationAndInterpretation: false,
                    Transport: false,
                    Others: false,
                },
                HumanResources: {
                    Training: false,
                    RiskPrevention: false,
                    LaborRelations: false,
                    PersonnelSelection: false,
                },
                HealthcareAndMedicine: {
                    Nursing: false,
                    Pharmacy: false,
                    SpecializedMedicine: false,
                    GeneralMedicine: false,
                    AlternativeMedicine: false,
                    Dentistry: false,
                    Psychology: false,
                    Veterinary: false,
                    OtherHealthcareProfessions: false,
                },
                TourismAndHospitality: {
                    Hospitality: false,
                    Catering: false,
                    Tourism: false,
                },
                Sales: {
                    Salesperson: false,
                    StoreManager: false,
                    Cashier: false,
                    InventoryManagement: false,
                    VisualMerchandising: false,
                },
            },
            
            
            totalYearsExperience: ''
        },
        requirements: {
            education: "",
            languages: [
                {
                    language: "",
                    level: ""
                }
            ]
        },
        description: {
            description: "",
            additionalInfo: ""
        }
    });
    

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <CreateJobPostStep1 formData={formData} setFormData={setFormData} nextStep={handleNext} />;
            case 1:
                return <CreateJobPostStep2 formData={formData} setFormData={setFormData} nextStep={handleNext} backStep={handleBack} />;
            case 2:
                return <CreateJobPostStep3 formData={formData} setFormData={setFormData} backStep={handleBack} />;
        }
    };

   

    return (
        <>
            <Container component="main" maxWidth="100%" sx={{ backgroundColor: '#F5F5F5',padding:"0px"  }}>
            <NavBar>
                <Typography ml={1} sx={{ fontWeight: "600", fontSize: "22px", marginBottom: '10px', mt: 3 }}>
                    Create Job Post
                </Typography>
                <Typography ml={1} sx={{ fontSize: "16px", marginBottom: '20px' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </Typography>
                <Typography ml={1} sx={{ mb: 1, textDecoration: 'none', color: 'gray', fontSize: '12px' }}>
                    Step {activeStep + 1} of 3
                </Typography>
                <Typography ml={1} sx={{ fontWeight: "Bold", fontSize: "18px", marginBottom: '10px' }}>
                    {stepTexts[activeStep]}
                </Typography>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <div key={label} style={{ flexGrow: 2,padding:"0px" }}>
                            <hr style={{ borderRadius: "4px", borderTop: `4px solid ${activeStep === index ? '#8EFFC2' : 'transparent'}`, marginTop: '10px' }} />
                        </div>
                    ))}
                </Stepper>
                <div>
                    {getStepContent(activeStep)}
                </div>
                </NavBar>
             
        </Container>
           <Footer />
        </>
    
    );
};

export default CreateJobPost;