import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Grid,
    FormControl,
    TextField,
    InputLabel,
    MenuItem,
    Select,
    Chip, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Divider, FormControlLabel, Checkbox, RadioGroup, Radio
} from "@mui/material";
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { OnBoardingApi, UploadImageApi } from "../../apis";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UploadImage from '../GlobalComponent/UploadImage';
import RadioButton from '../GlobalComponent/RadioButtons';
import Loader from '../GlobalComponent/Loader';
import ExperienceForm from '../GlobalComponent/EditExperienceForm';
import data from '../../components/JobPost/data.json';
import DynamicAutocomplete from '../GlobalComponent/CityFinder';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PhoneInput from 'react-phone-input-2'; // Import the PhoneInput component
import 'react-phone-input-2/lib/style.css'; // Import the default styles

const MyProfile = () => {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState({});
    const [selectedSpecialty, setSelectedSpecialty] = useState('');

    // const handleSpecialityChange = (event) => {
    //     // Assuming event.target.value is a comma-separated string
    //     const selectedSpecialties = event.target.value.split(','); // Split the string into an array

    //     // Update Formik field value
    //     formik.setFieldValue('preferences.top_specialities', selectedSpecialties);

    //     // Initialize an object to hold the updated categories
    //     const updatedCategories = {};

    //     // Iterate over the selected specialties
    //     selectedSpecialties.forEach(specialty => {
    //         if (specialtiesObject[specialty]) {
    //             updatedCategories[specialty] = specialtiesObject[specialty];
    //         }
    //     });

    //     // Update the state with the selected categories
    //     setSelectedCategories(updatedCategories);
    // };
    const handleSpecialityChange = (event) => {
        // Assuming event.target.value is a comma-separated string
        const selectedSpecialties = event.target.value.split(','); // Split the string into an array

        // Update Formik field value
        formik.setFieldValue('preferences.top_specialities', selectedSpecialties);

        // Initialize an object to hold the updated categories
        const updatedCategories = {};

        // Iterate over the selected specialties
        selectedSpecialties.forEach(specialty => {
            if (specialtiesObject[specialty]) {
                updatedCategories[specialty] = specialtiesObject[specialty];
            }
        });

        // Update the state with the selected categories
        setSelectedCategories(updatedCategories);
    };


    const handleSubcategoryChange = (category, subcategory) => (event) => {
        const value = event.target.value;

        // Updating Formik's values directly
        formik.setFieldValue(
            `preferences.experienceYears.${category}[0].${subcategory}`, value

        );
    };



    // Handle Specialty Selection from Dropdown
    const handleSpecialtyChange = (event) => {
        const specialty = event.target.value;
        setSelectedSpecialty(specialty);

        // Clear all previous specialties and set the new one to true
        const updatedSpecialities = Object.keys(formik.values.preferences.top_specialities).reduce((acc, key) => {
            acc[key] = false; // Reset all previous specialties to false
            return acc;
        }, {});

        // Update Formik's specialties state
        updatedSpecialities[specialty] = true; // Set the newly selected specialty to true

        formik.setFieldValue(`preferences.top_specialities`, updatedSpecialities);

    };

    // Handle Subcategory Chip Click
    const handleChipClick = (subcategory) => {
        // Get the current experience years for the selected specialty
        const currentExperience = formik.values.preferences.experienceYears[selectedSpecialty] || {};

        // Toggle the clicked subcategory experience
        const updatedExperience = {
            ...currentExperience,
            [subcategory]: !currentExperience[subcategory], // Toggle the current value
        };

        // Update Formik's subcategory state dynamically
        formik.setFieldValue(
            `preferences.experienceYears.${selectedSpecialty}`,
            updatedExperience
        );
    };

    // const handleCheckboxChange = (event) => {
    //     const { name, checked } = event.target;

    //     // Update Formik values
    //     formik.setFieldValue(`preferences.top_specialities.${name}`, checked);

    //     // Safely update selectedCategories
    //     setSelectedCategories((prev) => {
    //         const newSelected = { ...prev };

    //         if (checked) {
    //             // Add new category to the selected categories
    //             newSelected[name] = [];
    //         } else {
    //             // Remove the category from the selected categories
    //             const { [name]: _, ...rest } = newSelected; // Using destructuring to omit the property
    //             return rest;
    //         }

    //         return newSelected;
    //     });
    // };
    const specialtiesList = data.specialties || [];
    const subcategories = data.subcategories || []; // Extract specialties from data.json
    const specialtiesObject = specialtiesList.reduce((acc, specialty) => {
        acc[specialty] = true; // or any relevant details
        return acc;
    }, {});
    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the cleaned number has the correct length (11 digits including country code)
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `+${match[0].slice(0, 3)} (${match[1]}) ${match[2]}-${match[3]}`; // Format as +XXX (XXX) XXX-XXXX
        }

        return phoneNumber; // Return original number if it doesn't match the pattern
    };

    // const handleSubcategoryChange = (category, subcategory) => (event) => {
    //     const value = event.target.value;

    //     // Updating Formik's values directly
    //     formik.setFieldValue(`preferences.experienceYears.${category}[0].${subcategory}`, value);
    // };
    // Helper function to safely parse JSON
    const parseJSON = (jsonString) => {
        try {
            // Check if jsonString is already an object
            if (typeof jsonString === "object") {
                return jsonString; // Return as-is if already an object
            }
            return jsonString ? JSON.parse(jsonString) : {}; // Parse JSON if jsonString is not null or undefined
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return {}; // Return empty object if parsing fails
        }
    };
    const fetchOnBoardedUserData = async () => {
        try {
            const res = await OnBoardingApi.getOnBoarding();
            function multiParse(input) {
                let result = input;
                try {
                    while (typeof result === 'string') {
                        result = JSON.parse(result);
                    }
                } catch (e) {
                    console.error('Error parsing input:', e);
                }
                return result;
            }
            if (res.data && res.data.profile) {

                const parsedTopSpecialities = multiParse(res?.data?.profile?.top_specialities || '{}');
                const parsedEmployementType = multiParse(res?.data?.profile?.employment_type || '{}');
                const salaryRange = multiParse(res?.data?.profile?.salary_range || '{}');
                const workExperience = multiParse(res?.data?.profile?.work_experience || '[]');
                const education = multiParse(JSON.stringify(res?.data?.profile?.education) || '[]');
                const languages = multiParse(JSON.stringify(res?.data?.profile?.languages) || '[]');
                const experienceYears = multiParse(res?.data?.profile?.experienceYears || {});


                formik.setValues({
                    ...formik.values,
                    preferences: {
                        professional_area: res?.data?.profile?.professional_area || '',
                        total_years_experience: res?.data?.profile.total_years_experience || '',
                        top_specialities: {
                            PublicAdministration: parsedTopSpecialities.PublicAdministration || false,
                            BusinessAdministration: parsedTopSpecialities.BusinessAdministration || false,
                            QualityProductionAndRD: parsedTopSpecialities.QualityProductionAndRD || false,
                            CommercialAndSales: parsedTopSpecialities.CommercialAndSales || false,
                            PurchasingLogisticsAndWarehouse: parsedTopSpecialities.PurchasingLogisticsAndWarehouse || false,
                            DesignAndGraphicArts: parsedTopSpecialities.DesignAndGraphicArts || false,
                            EducationAndTraining: parsedTopSpecialities.EducationAndTraining || false,
                            FinanceAndBanking: parsedTopSpecialities.FinanceAndBanking || false,
                            ITAndTelecommunications: parsedTopSpecialities.ITAndTelecommunications || false,
                            EngineersAndTechnicians: parsedTopSpecialities.EngineersAndTechnicians || false,
                            RealEstateAndConstruction: parsedTopSpecialities.RealEstateAndConstruction || false,
                            Legal: parsedTopSpecialities.Legal || false,
                            MarketingAndCommunication: parsedTopSpecialities.MarketingAndCommunication || false,
                            ProfessionsArtsAndCrafts: parsedTopSpecialities.ProfessionsArtsAndCrafts || false,
                            Automotive: parsedTopSpecialities.Automotive || false,
                            ConsumptionAndFood: parsedTopSpecialities.ConsumptionAndFood || false,
                            SecurityAndSurveillance: parsedTopSpecialities.SecurityAndSurveillance || false,
                            HumanResources: parsedTopSpecialities.HumanResources || false,
                            HealthcareAndMedicine: parsedTopSpecialities.HealthcareAndMedicine || false,
                            TourismAndHospitality: parsedTopSpecialities.TourismAndHospitality || false,
                            Sales: parsedTopSpecialities.Sales || false

                        },
                        experienceYears: experienceYears || {},
                        employment_type: {
                            fullTime: parsedEmployementType?.fullTime || false,
                            partTime: parsedEmployementType?.partTime || false,
                        },
                        job_search_status: res?.data?.profile?.job_search_status || '',
                        remote_work: res?.data?.profile?.remote_work ? "1" : "0" || "",
                        relocation: res?.data?.profile?.relocation ? "1" : "0" || "",
                    },
                    about_you: {
                        resume_url: res?.data?.profile.resume_url || '',
                        profile_photo_url: res?.data?.profile?.profile_photo_url || '',
                        full_name: res?.data?.profile?.full_name || '',
                        phone_number: res?.data?.profile?.phone_number || '',
                        whatsapp_number: res?.data?.profile?.whatsapp_number || '',
                        current_city: res?.data?.profile?.current_city || '',
                        salary_range: {
                            from: salaryRange?.from || '',
                            to: salaryRange?.to || '',
                        },
                        linkedin_url: res?.data?.profile?.linkedin_url || '',
                        personal_blog_url: res?.data?.profile?.personal_blog_url || '',
                    },
                    background: {

                        work_experience: workExperience?.length > 0 ? workExperience : [
                            {
                                company_name: workExperience?.company_name || '',
                                job_title: workExperience?.job_title || '',
                                is_current: workExperience?.is_current || false,
                                start: workExperience?.start || '',
                                end: workExperience?.end || '',
                                description: workExperience?.description || '',
                            }
                        ],
                        education: education?.length > 0 ? education : [
                            {
                                university_or_collage: education?.university_or_collage || '',
                                graduation_year: education?.graduation_year || '',
                                field_of_study: education?.field_of_study || '',
                                degree: education?.degree || '',
                            }
                        ],
                        languages: languages?.length > 0 ? languages : [
                            {
                                language: languages?.language || '',
                                language_level: languages?.language_level || '',
                            }
                        ],
                        objective: res?.data?.profile?.objective || '',
                        achievements: res?.data?.profile?.achievements || '',
                        personal_characteristics: res?.data?.profile?.personal_characteristics || '',
                    },


                });

                setUserData(multiParse(res?.data?.profile));
            } else {
                console.log("No data available in the database");
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching on-boarded user data:", error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchOnBoardedUserData();

    }, []);
    const experienceList = ["Less than 1 year", "1-3 years", "3-5 years", "5+ years"];
    const designList = ["Software Engineer", "Business Analyst", "Product Manager"];
    const jobStatusList = ["Not seeking to change jobs, but may consider", "Actively looking for a job", "Currently employed and not looking"];

    const [openQualifications, setOpenQualification] = useState(false);
    const [openPreferences, setOpenPreferences] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [openPersonalInfo, setOpenPersonalInfo] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
    // const [remoteWork, setRemoteWork] = useState('');
    // const [relocation, setRelocation] = useState('');
    // const [professionalArea, setProfessionalArea] = useState('');
    // const [yearsOfExperience, setYearsOfExperience] = useState('');
    // const [specialties, setSpecialties] = useState('');
    const [employmentType, setEmploymentType] = useState('');
    const [jobSearchStatus, setJobSearchStatus] = useState('');
    const [openSalary, setOpenSalary] = useState(false);
    // const [preferredSalary, setPreferredSalary] = useState('');
    const [openLinks, setOpenLinks] = useState(false);
    // const [links, setLinks] = useState('');
    const [openWorkExperience, setOpenWorkExperience] = useState(false);
    // const [companyName, setCompanyName] = useState('');
    // const [jobTitle, setJobTitle] = useState('');
    const [openEducation, setOpenEducation] = useState(false);
    const [education, setEducation] = useState('');
    const [openLanguage, setOpenLanguage] = useState(false);
    // const [languages, setLanguages] = useState('');
    const [openAdditionalInfo, setOpenAdditionalInfo] = useState(false);
    // const [objective, setObjective] = useState('');
    // const [achievements, setAchievements] = useState('');
    // const [personalCharacteristics, setPersonalCharacteristics] = useState('');

    const [selectedOption, setSelectedOption] = useState("");
    const handleInputSalaryChange = (event) => {
        const { name, value } = event.target;
        let formattedValue = value.replace(/,/g, ''); // Remove existing commas
        // Add commas to the number
        formik.setFieldValue(name, formattedValue);
    };
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleInputChange = (event) => {
        const { name, checked } = event.target;
        formik.setFieldValue(`preferences.top_specialities.${name}`, checked);
    };
    const handleChange = (event) => {
        formik.setFieldValue(event.target.name, event.target.value);
    };
    const [workExperiences, setWorkExperiences] = useState([
        {
            company_name: "",
            job_title: "",
            is_current: false,
            start: null, // Initialize with null or a default date object
            end: null, // Initialize end date with null or a default date object
            description: "",
        }
    ]);
    const [addedWorkExperiences, setAddedWorkExperiences] = useState([false]);

    const handleWorkExperienceChange = (index, field, value) => {
        const updatedExperiences = [...workExperiences];
        updatedExperiences[index][field] = value;
        setWorkExperiences(updatedExperiences);
        formik.setFieldValue(`background.work_experience[${index}].${field}`, value);
    };
    // const handleDateChange = (date, dateType, index) => {
    //     const formattedDate = dayjs(date).format("MMMM DD, YYYY hh:mm A");

    //     // Make sure background.work_experience is initialized properly
    //     if (!formik.values.background.work_experience) {
    //         formik.setFieldValue('background.work_experience', []);
    //     }

    //     // Make a copy of the array of work experiences
    //     const updatedWorkExperiences = [...formik.values.background.work_experience];

    //     // Ensure index is within bounds
    //     if (index >= 0 && index < updatedWorkExperiences.length) {
    //         // Update the start date of the specific work experience entry
    //         updatedWorkExperiences[index] = {
    //             ...updatedWorkExperiences[index],
    //             start: formattedDate,
    //         };

    //         // Set the updated value (formatted date) back into Formik's form state
    //         formik.setFieldValue(`background.work_experience`, updatedWorkExperiences);
    //         console.log("fjsdinvhs",updatedWorkExperiences);

    //     } else {
    //         console.error(`Invalid index ${index} for background.work_experience`);
    //     }
    // };



    const handleAddWorkExperience = () => {
        const newWorkExperience = [
            ...formik.values.background.work_experience,
            {
                company_name: "",
                job_title: "",
                is_current: false,
                start: null, // Initialize with null or a default date object
                end: null, // Initialize end date with null or a default date object
                description: "",
            }
        ];
        formik.setFieldValue('background.work_experience', newWorkExperience);

        const newAddedWorkExperience = [...addedWorkExperiences, true];
        setAddedWorkExperiences(newAddedWorkExperience);


    };

    const handleRemoveWorkExperience = (index) => {
        const updatedExperiences = [...formik.values.background.work_experience];
        updatedExperiences.splice(index, 1);
        formik.setFieldValue('background.work_experience', updatedExperiences);

        const updatedAddedExperience = [...addedWorkExperiences];
        updatedAddedExperience.splice(index, 1);
        setAddedLanguages(updatedAddedExperience);


    };
    const handleStartDateChange = (index, date) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        console.log("Formatted start date:", formattedDate);

        const updatedWorkExperiences = [...formik.values.background.work_experience];
        updatedWorkExperiences[index].start = formattedDate;

        formik.setFieldValue(`background.work_experience[${index}].start`, formattedDate);
    };



    const handleEndDateChange = (index, date) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        console.log("Formatted end date:", formattedDate);

        const updatedWorkExperiences = [...formik.values.background.work_experience];
        updatedWorkExperiences[index].end = formattedDate;

        formik.setFieldValue(`background.work_experience[${index}].end`, formattedDate);
    };
    const [languages, setLanguages] = useState([{ language: "", language_level: "" }]);
    const [addedLanguages, setAddedLanguages] = useState([false]);

    const handleLanguageChange = (index, key, value) => {
        const newLanguages = [...languages];
        newLanguages[index][key] = value;
        setLanguages(newLanguages);
        formik.setFieldValue(`background.languages[${index}].${key}`, value);
    };

    const handleAddLanguage = () => {
        const newLanguages = [...formik.values.background.languages, { language: "", level: "" }];
        formik.setFieldValue('background.languages', newLanguages);

        const newAddedLanguages = [...addedLanguages, true];
        setAddedLanguages(newAddedLanguages);
    };

    const handleRemoveLanguage = (index) => {
        const newLanguages = [...formik.values.background.languages];
        newLanguages.splice(index, 1);
        formik.setFieldValue('background.languages', newLanguages);

        const newAddedLanguages = [...addedLanguages];
        newAddedLanguages.splice(index, 1);
        setAddedLanguages(newAddedLanguages);
    };


    const [educations, setEducations] = useState([
        {
            university_or_collage: "",
            graduation_year: "",
            field_of_study: "",
            degree: "",
        }
    ]);
    const [addedEducations, setAddedEducations] = useState([false]);

    const handleEducationChange = (index, field, value) => {
        const updatedEducations = [...educations];
        updatedEducations[index][field] = value;
        setEducations(updatedEducations);
        formik.setFieldValue(`background.education[${index}].${field}`, value);


    };

    const handleAddEducation = () => {
        const newEducation = [...formik.values.background.education, {
            university_or_collage: "",
            graduation_year: "",
            field_of_study: "",
            degree: "",
        }];
        formik.setFieldValue('background.education', newEducation);

        const newAddedEducations = [...addedEducations, true];
        setAddedEducations(newAddedEducations);

    };

    const handleRemoveEducation = (index) => {
        const newEducations = [...formik.values.background.education];
        newEducations.splice(index, 1);
        formik.setFieldValue('background.education', newEducations);

        const newAddedEducations = [...addedEducations];
        newAddedEducations.splice(index, 1);
        setAddedLanguages(newAddedEducations);
    };

    const formik = useFormik({
        initialValues: {
            company_profile: {
                company_location: '',
                latitude: '',
                longitude: '',
                // other fields
            },
            preferences: {
                professional_area: '',
                total_years_experience: '',
                top_specialities: {
                    PublicAdministration: false,
                    BusinessAdministration: false,
                    QualityProductionAndRD: false,
                    CommercialAndSales: false,
                    PurchasingLogisticsAndWarehouse: false,
                    DesignAndGraphicArts: false,
                    EducationAndTraining: false,
                    FinanceAndBanking: false,
                    ITAndTelecommunications: false,
                    EngineersAndTechnicians: false,
                    RealEstateAndConstruction: false,
                    Legal: false,
                    MarketingAndCommunication: false,
                    ProfessionsArtsAndCrafts: false,
                    Automotive: false,
                    ConsumptionAndFood: false,
                    SecurityAndSurveillance: false,
                    HumanResources: false,
                    HealthcareAndMedicine: false,
                    TourismAndHospitality: false,
                    Sales: false,
                },
                experienceYears: {
                    PublicAdministration: {
                        EuropeanAdministration: false,
                        AutonomousAdministration: false,
                        StateAdministration: false,
                        LocalAdministration: false,
                        SecurityForces: false,
                        PublicEnterprises: false,
                    },
                    BusinessAdministration: {
                        Administration: false,
                        Auditing: false,
                        Consulting: false,
                        Management: false,
                        BillingCollectionsAndPayments: false,
                        FinanceAndAccounting: false,
                        NegotiationAndContracting: false,
                        CorporateOperations: false,
                        CompanyOrganization: false,
                        Reception: false,
                        Secretarial: false,
                        CustomerService: false,
                    },
                    QualityProductionAndRD: {
                        QualityCertification: false,
                        QualityManagement: false,
                        ProjectManagement: false,
                        ResearchAndDevelopment: false,
                        Maintenance: false,
                        Environment: false,
                        Metrology: false,
                        Operations: false,
                    },
                    CommercialAndSales: {
                        CommercialAgent: false,
                        SalesRepresentative: false,
                        ForeignTrade: false,
                        KeyAccount: false,
                        TelemarketingAndTelephoneSales: false,
                    },
                    PurchasingLogisticsAndWarehouse: {
                        Warehouse: false,
                        PurchasingAndProcurement: false,
                        DistributionAndLogistics: false,
                    },
                    DesignAndGraphicArts: {
                        AudiovisualCreation: false,
                        GraphicDesign: false,
                        IndustrialDesign: false,
                        WebDesign: false,
                        PublishingAndPrinting: false,
                        FashionAndTailoring: false,
                    },
                    EducationAndTraining: {
                        SpecialEducation: false,
                        SocialEducation: false,
                        Teaching: false,
                        EducationalCenterManagement: false,
                        Pedagogy: false,
                        Childcare: false,
                    },
                    FinanceAndBanking: {
                        CorporateBanking: false,
                        PrivateBanking: false,
                        StockMarketSecuritiesAndInvestment: false,
                        BankingProductsAndServices: false,
                        Insurance: false,
                    },
                    ITAndTelecommunications: {
                        DatabaseAdministration: false,
                        Analysis: false,
                        Architecture: false,
                        Quality: false,
                        ERPCRMBusinessIntelligence: false,
                        ProjectManagement: false,
                        HardwareNetworksAndSecurity: false,
                        Helpdesk: false,
                        Programming: false,
                        Systems: false,
                        Telecommunications: false,
                    },
                    EngineersAndTechnicians: {
                        Aerospace: false,
                        AgronomyAndForestry: false,
                        Electronics: false,
                        IndustrialElectronicsAndAutomation: false,
                        RenewableEnergies: false,
                        GeologyGeodesyAndCartography: false,
                        Industrial: false,
                        Mining: false,
                        NavalAndOceanEngineering: false,
                        IndustrialOrganization: false,
                        Chemistry: false,
                        DefenseSystems: false,
                    },
                    RealEstateAndConstruction: {
                        RealEstateAdministration: false,
                        ArchitectureAndDesign: false,
                        ProjectAndConstructionManagement: false,
                        CivilEngineeringAndPublicWorks: false,
                        MaterialsEngineering: false,
                        InteriorDesign: false,
                        ConstructionTrades: false,
                    },
                    Legal: {
                        CivilCriminalAndConstitutionalLaw: false,
                        InternationalLaw: false,
                        ProceduralLaw: false,
                        BusinessLaw: false,
                        NotaryAndRegistries: false,
                    },
                    MarketingAndCommunication: {
                        CorporateCommunication: false,
                        BrandAndProductDevelopment: false,
                        MarketResearch: false,
                        Marketing: false,
                        DigitalMarketing: false,
                        JournalismAndEditing: false,
                        Production: false,
                        Advertising: false,
                        PublicRelationsAndEvents: false,
                        AudiovisualTechnicians: false,
                    },
                    ProfessionsArtsAndCrafts: {
                        AgricultureAndGardening: false,
                        PerformingArts: false,
                        PlasticArts: false,
                        Craftsmanship: false,
                    },
                    Automotive: {
                        Carpentry: false,
                        Tailoring: false,
                    },
                    ConsumptionAndFood: {
                        Electricity: false,
                        BeautyAndCosmetology: false,
                        Plumbing: false,
                        Photography: false,
                        Cleaning: false,
                    },
                    SecurityAndSurveillance: {
                        TranslationAndInterpretation: false,
                        Transport: false,
                        Others: false,
                    },
                    HumanResources: {
                        Training: false,
                        RiskPrevention: false,
                        LaborRelations: false,
                        PersonnelSelection: false,
                    },
                    HealthcareAndMedicine: {
                        Nursing: false,
                        Pharmacy: false,
                        SpecializedMedicine: false,
                        GeneralMedicine: false,
                        AlternativeMedicine: false,
                        Dentistry: false,
                        Psychology: false,
                        Veterinary: false,
                        OtherHealthcareProfessions: false,
                    },
                    TourismAndHospitality: {
                        Hospitality: false,
                        Catering: false,
                        Tourism: false,
                    },
                    Sales: {
                        Salesperson: false,
                        StoreManager: false,
                        Cashier: false,
                        InventoryManagement: false,
                        VisualMerchandising: false,
                    },
                },

                employment_type: {
                    fullTime: false,
                    partTime: false,
                },
                job_search_status: '',
                remote_work: '',
                relocation: '',
            },
            about_you: {
                resume_url: '',
                profile_photo_url: '',
                full_name: '',
                phone_number: '',
                whatsapp_number: '',
                current_city: '',
                salary_range: {
                    from: '',
                    to: '',
                },
                linkedin_url: '',
                personal_blog_url: '',
            },
            background: {
                work_experience: [],
                education: [],
                languages: [],
                objective: '',
                achievements: '',
                personal_characteristics: '',
            },
            // is_onboarding: false,
            // is_active: false,

        },
        validationSchema: Yup.object({

        }),

        onSubmit: async (values) => {
            try {
                const profilePhotoName = await handleProfilePhotoUpload(); // Upload the profile photo and get the URL data
                if (profilePhotoName) {
                    values.about_you.profile_photo_url = profilePhotoName; // Set profile_photo_url to the photo name
                }

                const updatedValues = {
                    ...values,
                    preferences: {
                        ...values.preferences,
                        top_specialities: JSON.stringify(values.preferences.top_specialities),
                        employment_type: JSON.stringify(values.preferences.employment_type)
                    }
                };

                const res = await OnBoardingApi.OnBoarding(updatedValues);

                setOpenPersonalInfo(false);
                setOpenQualification(false);
                setOpenPreferences(false);
                setOpenLocation(false);
                setOpenLinks(false);
                setOpenSalary(false);
                setOpenSalary(false);
                setOpenWorkExperience(false);
                setOpenEducation(false);
                setOpenLanguage(false);
                setOpenAdditionalInfo(false);
                fetchOnBoardedUserData();
            } catch (err) {
                console.error(err);
            }
        },
    });
    const trueTopSpecialities = Object.entries(formik.values.preferences.top_specialities)
        .filter(([_, value]) => value) // Keep only entries where value is true
        .map(([key]) => key) // Extract the key (specialty name)
        .join(', '); // Join the names into a single string

    function multiParse(input) {
        let result = input;
        try {
            while (typeof result === 'string') {
                result = JSON.parse(result);
            }
        } catch (e) {
            console.error('Error parsing input:', e);
        }
        return result;
    }
    if (loading) {
        <Loader loading={loading} />;
    }
    const handleEmploymentTypeChange = (event) => {
        const { name, checked } = event.target;

        // Set the clicked checkbox value
        formik.setFieldValue(`preferences.employment_type.${name}`, checked);

        // Determine the other checkbox to uncheck
        const otherEmploymentType = name === 'partTime' ? 'fullTime' : 'partTime';

        // Uncheck the other checkbox if the clicked one is checked
        if (checked) {
            formik.setFieldValue(`preferences.employment_type.${otherEmploymentType}`, false);
        }
    };

    const handleProfilePhotoChange = (event) => {
        const file = event.target.files[0];
        setProfilePhoto(file);
        setProfilePhotoPreview(URL.createObjectURL(file));
    };

    const handleProfilePhotoUpload = async () => {
        if (profilePhoto) {
            try {
                const formData = new FormData();
                formData.append('file', profilePhoto);

                const res = await UploadImageApi.uploadImage(formData);
                return res.data.data.name; // Return only the name of the uploaded photo
            } catch (error) {
                console.error("Error uploading profile photo:", error);
            }
        }
    };
    const getCityColor = (city) => {
        return city === "Enter City" ? '#aaa' : 'black';
    };
    // Safely extract true specialties
    const trueTopCity = formik.values.about_you.current_city
    const formattedCity = trueTopCity
        ? trueTopCity // Add space between words
        : "Enter City"; // Default value when no true speciality

    const cityColor = getCityColor(formattedCity);

    return (
        <Box sx={{ maxWidth: "100%", backgroundColor: '#F5F5F5' }}>
            <Typography ml={1} sx={{ fontWeight: "Bold", fontSize: "24px", marginLeft: '-5px', mt: 6, }}>
                My Profile
                {/* <Button variant="outlined" color="primary" sx={{
                    color: 'transparent', borderColor: 'primary.main', fontWeight: 'bold', '&:hover': {

                        borderColor: 'primary.main'
                    },
                    ml:{xs:0.5,sm:3} 
                }}>
                    <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "5px", }}>
                        Preview my resume
                    </Typography>
                </Button> */}
            </Typography>
            <Box
                sx={{
                    background: "#F5F5F5",
                    borderRadius: "14px",
                    paddingTop: "20px"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >

                    <Typography ml={1} sx={{ fontWeight: "600", fontSize: "22px", marginLeft: '-3px', marginBottom: '15px' }}>
                        Personal Information
                    </Typography>
                    {/* <Button
                        type="submit"
                        variant="standard"
                        endIcon={<EditIcon />}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: '#F5F5F5'
                            }
                        }}
                    >
                        <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                            Edit
                        </Typography>
                    </Button> */}
                    <Typography
                        component="span"
                        variant="body1"

                        onClick={setOpenPersonalInfo}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'inherit'
                            },
                            marginLeft: "auto",
                            mt: { xs: 0.5, sm: 0 },
                            fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px"
                        }}
                    >

                        Edit
                        <EditIcon sx={{ mr: 1, ml: 1, fontSize: '16px', }} />
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Grid container spacing={2}>


                        <Grid item>
                            <Box
                                sx={{
                                    width: '100px',
                                    height: '100px',
                                    border: '1px dotted black',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '15px',
                                    backgroundColor: "lightgray"
                                }}
                            >
                                {formik.values.about_you?.profile_photo_url ? (
                                    <UploadImage
                                        imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${formik.values.about_you?.profile_photo_url}`}
                                        altText={formik.values.about_you?.profile_photo_url}
                                    />
                                ) : (
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                        Upload
                                    </Typography>
                                )}
                            </Box>
                        </Grid>

                        <Grid item>
                            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>Profile Photo</Typography>
                            <Typography variant="body2" sx={{ mr: 0, textDecoration: 'none', color: 'gray', fontSize: '11px' }}>
                                Maximum file size is up to 10MB
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                Full Name
                            </InputLabel>
                            <Box
                                sx={{
                                    mb: 2,
                                    p: 2,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '4px',
                                    backgroundColor: '#f5f5f5',
                                    color: 'black',
                                }}
                            >
                                <Typography variant="body1">
                                    {formik.values.about_you.full_name}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                Phone No
                            </InputLabel>
                            <Box
                                sx={{
                                    mb: 2,
                                    p: 2,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '4px',
                                    backgroundColor: '#f5f5f5',
                                    color: 'black',
                                }}
                            >
                                <Typography variant="body1">
                                    +{formik.values.about_you.phone_number}
                                </Typography>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                WhatsApp No
                            </InputLabel>
                            <Box
                                sx={{
                                    mb: 2,
                                    p: 2,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '4px',
                                    backgroundColor: '#f5f5f5',
                                    color: 'black',
                                }}
                            >
                                <Typography variant="body1">
                                    {formik.values.about_you.whatsapp_number}
                                </Typography>
                            </Box>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                Your Location
                            </InputLabel>
                            <Box
                                sx={{
                                    mb: 2,
                                    p: 2,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '4px',
                                    backgroundColor: '#f5f5f5',
                                    color: 'black',
                                }}
                            >
                                <Typography variant="body1">
                                    {formik.values.about_you.current_city}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>



                <Dialog width="md" open={openPersonalInfo} onClose={() => setOpenPersonalInfo(false)}>
                    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                            Edit Personal Information
                        </Typography>
                        <IconButton onClick={() => setOpenPersonalInfo(false)}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider sx={{ width: '90%', color: 'text.secondary', mb: 1, mx: "auto" }} />
                    <DialogContent >
                        <Box component="form" onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="profile-photo"
                                        type="file"
                                        onChange={handleProfilePhotoChange}
                                    />
                                    <label htmlFor="profile-photo">

                                        <Button component="span" sx={{
                                            '&:hover': {
                                                backgroundColor: 'transparent', // Set the background color to transparent on hover
                                            },
                                        }}>
                                            <Box
                                                sx={{
                                                    width: '100px',
                                                    height: '100px',
                                                    border: '1px dotted black',
                                                    borderRadius: '5px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginBottom: '15px',
                                                    backgroundColor: "lightgray"
                                                }}
                                            >
                                                {profilePhotoPreview ? (
                                                    <UploadImage
                                                        imageUrl={profilePhotoPreview}
                                                        altText="Profile Photo"
                                                    />
                                                ) : (
                                                    formik.values.about_you?.profile_photo_url ? (
                                                        <UploadImage
                                                            imageUrl={profilePhotoPreview || `${process.env.REACT_APP_API_BASE_URL}/public/image/${formik.values.about_you?.profile_photo_url}`}
                                                            altText={formik.values.about_you?.profile_photo_url}
                                                        />
                                                    ) : (
                                                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                                            Upload
                                                        </Typography>
                                                    )
                                                )}
                                            </Box>
                                        </Button>
                                    </label>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>Profile Photo</Typography>
                                    <Typography variant="body2" sx={{ mr: 0, textDecoration: 'none', color: 'gray', fontSize: '11px' }}>
                                        Maximum file size is up to 10MB
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                            Full Name *
                                        </InputLabel>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: false,
                                                style: {
                                                    fontSize: '14px',
                                                    color: 'gray'
                                                }
                                            }}
                                            placeholder="Enter Name"
                                            variant="outlined"
                                            fullWidth
                                            name="about_you.full_name"
                                            value={formik.values.about_you.full_name}
                                            onChange={formik.handleChange}
                                            error={formik.touched.about_you?.full_name && Boolean(formik.errors.about_you?.full_name)}
                                            helperText={formik.touched.about_you?.full_name && formik.errors.about_you?.full_name}
                                            sx={{
                                                mb: 2,
                                                '& .MuiInputBase-input::placeholder': {
                                                    color: 'black',
                                                    opacity: 1,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                            Phone No *
                                        </InputLabel>
                                        <PhoneInput
                                        
                                            placeholder="Enter Phone No"
                                            variant="outlined"
                                            fullWidth
                                            name="about_you.phone_number"
                                            value={formik.values.about_you.phone_number}
                                            onChange={(phone) => formik.handleChange({ target: { name: 'about_you.phone_number', value: phone } })}
                                            error={formik.touched.about_you?.phone_number && Boolean(formik.errors.about_you?.phone_number)}
                                            helperText={formik.touched.about_you?.phone_number && formik.errors.about_you?.phone_number}
                                            sx={{
                                                mb: 2,
                                                '& .MuiInputBase-input::placeholder': {
                                                    color: 'black',
                                                    opacity: 1,
                                                },
                                            }}

                                            inputStyle={{
                                                width: '100%',
                                                fontSize: '14px',
                                                padding: '10px 10px 10px 40px', // Add left padding to prevent overlap with the flag
                                                height: '55px', // Set the desired height
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                            }}
                                            InputLabelProps={{
                                                shrink: false,
                                                style: {
                                                    fontSize: '14px',
                                                    color: 'gray',
                                                }
                                            }}

                                            inputProps={{
                                                maxLength: 15, // Set max length for the input
                                            }}

                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                            WhatsApp No
                                        </InputLabel>
                                        <TextField
                                            InputLabelProps={{
                                                shrink: false,
                                                style: {
                                                    fontSize: '14px',
                                                    color: 'gray'
                                                }
                                            }}
                                            placeholder="Enter WhatsApp No"
                                            variant="outlined"
                                            fullWidth
                                            name="about_you.whatsapp_number"
                                            value={formik.values.about_you.whatsapp_number}
                                            onChange={formik.handleChange}
                                            error={formik.touched.about_you?.whatsapp_number && Boolean(formik.errors.about_you?.whatsapp_number)}
                                            helperText={formik.touched.about_you?.whatsapp_number && formik.errors.about_you?.whatsapp_number}
                                            sx={{
                                                mb: 2,
                                                '& .MuiInputBase-input::placeholder': {
                                                    color: 'black',
                                                    opacity: 1,
                                                },
                                            }}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                            Your Location *
                                        </InputLabel>
                                        {/* <TextField
                                            InputLabelProps={{
                                                shrink: false,
                                                style: {
                                                    fontSize: '14px',
                                                    color: 'gray'
                                                }
                                            }}
                                            placeholder="Enter Location"
                                            variant="outlined"
                                            fullWidth
                                            name="about_you.current_city"
                                            value={formik.values.about_you.current_city}
                                            onChange={formik.handleChange}
                                            error={formik.touched.about_you?.current_city && Boolean(formik.errors.about_you?.current_city)}
                                            helperText={formik.touched.about_you?.current_city && formik.errors.about_you?.current_city}
                                            sx={{
                                                mb: 2,
                                                '& .MuiInputBase-input::placeholder': {
                                                    color: 'black',
                                                    opacity: 1,
                                                },
                                            }}
                                        /> */}
                                        <DynamicAutocomplete
                                            color={cityColor}
                                            placeHolder={formattedCity}
                                            formik={formik}
                                            fieldName="about_you.current_city"
                                            value={formik.values.about_you.current_city}
                                        // fetchOptions={fetchCities}

                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* <Box mt={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                                Email *
                                            </InputLabel>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: false,
                                                    style: {
                                                        fontSize: '14px',
                                                        color: 'gray'
                                                    }
                                                }}
                                                placeholder="Enter Email"
                                                variant="outlined"
                                                fullWidth
                                                name="recruiter_profile.email"
                                                value={formik.values.recruiter_profile.email}
                                                onChange={formik.handleChange}
                                                error={formik.touched.recruiter_profile?.email && Boolean(formik.errors.recruiter_profile?.email)}
                                                helperText={formik.touched.recruiter_profile?.email && formik.errors.recruiter_profile?.email}
                                                sx={{
                                                    mb: 2,
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: 'black',
                                                        opacity: 1,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                                Your Location *
                                            </InputLabel>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: false,
                                                    style: {
                                                        fontSize: '14px',
                                                        color: 'gray'
                                                    }
                                                }}
                                                placeholder="Enter City"
                                                variant="outlined"
                                                fullWidth
                                                name="recruiter_profile.location"
                                                value={formik.values.recruiter_profile.location}
                                                onChange={formik.handleChange}
                                                error={formik.touched.recruiter_profile?.location && Boolean(formik.errors.recruiter_profile?.location)}
                                                helperText={formik.touched.recruiter_profile?.location && formik.errors.recruiter_profile?.location}
                                                sx={{
                                                    mb: 2,
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: 'black',
                                                        opacity: 1,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box> */}
                            <DialogActions>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mb: 1 }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: 'primary.main'
                                            }
                                        }}
                                    >
                                        <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none' }}>
                                            Save
                                        </Typography>
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>
                <Box>
                    <Box sx={{ display: "flex" }}>
                        <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                            Qualification
                        </Typography>

                        {/* <Button
                            onClick={() => setOpenQualification(true)}
                            type="submit"
                            variant="standard"
                            endIcon={<EditIcon />}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                color: 'primary.main',
                                '&:hover': {
                                    backgroundColor: '#F5F5F5'
                                },
                                marginLeft: "auto"
                            }}
                        >
                            <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                                Edit
                            </Typography>
                        </Button> */}
                        <Typography
                            component="span"
                            variant="body1"

                            onClick={() => setOpenQualification(true)}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                color: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'inherit'
                                },
                                marginLeft: "auto",
                                mt: { xs: 0.5, sm: 0 },
                                fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", mt: 2
                            }}
                        >

                            Edit
                            <EditIcon sx={{ mr: 1, ml: 1, fontSize: '16px', }} />
                        </Typography>
                    </Box>
                    <Box>
                        {/* <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
                                    Professional Area *
                                </InputLabel>
                                <Box
                                    sx={{
                                        mb: 2,
                                        p: 2,
                                        border: '1px solid #d9d9d9',
                                        borderRadius: '4px',
                                        backgroundColor: '#f5f5f5',
                                        color: 'black',
                                    }}
                                >
                                    <Typography variant="body1">
                                        {formik.values.preferences.professional_area || 'Design'}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
                                    Profession *
                                </InputLabel>
                                <Box
                                    sx={{
                                        mb: 2,
                                        p: 2,
                                        border: '1px solid #d9d9d9',
                                        borderRadius: '4px',
                                        backgroundColor: '#f5f5f5',
                                        color: 'black',
                                    }}
                                >
                                    <Typography variant="body1">
                                        {formik.values.preferences.total_years_experience || 'Select experience'}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid> */}
                        <Box>

                            <Typography variant="body1" paragraph>
                                <strong> Professional Area </strong>
                                <Box component="span">
                                    {(() => {
                                        const parsedSpecialties = multiParse(formik.values?.preferences?.top_specialities); // Ensure it's parsed correctly

                                        // Check if there are any specialties with truthy values
                                        const specialtiesArray = Object.entries(parsedSpecialties).filter(([_, value]) => value);
                                        const hasValidSpecialties = specialtiesArray.length > 0;

                                        // Return specialties or "N/A" based on availability
                                        return hasValidSpecialties ? (
                                            specialtiesArray.map(([specialty]) => (
                                                <Typography key={specialty} component="span" variant="body1" sx={{ display: 'inline', mr: 1 }}>
                                                    <br /> {specialty.replace(/([A-Z])/g, ' $1').trim()}
                                                </Typography>
                                            ))
                                        ) : (
                                            <Typography component="span" variant="body1">
                                                N/A
                                            </Typography>
                                        );
                                    })()}
                                </Box>
                            </Typography>

                            <Typography variant="body1" paragraph>
                                <strong>Specialties </strong>
                                <Box component="span">
                                    {formik.values?.preferences?.experienceYears ? (
                                        Object.entries(multiParse(formik.values?.preferences?.experienceYears)) // Parse the JSON string
                                            .filter(([specialty]) => {
                                                // Only include specialties that are marked as true in top_specialities
                                                const parsedSpecialties = multiParse(formik.values?.preferences?.top_specialities);
                                                return parsedSpecialties[specialty] === true;
                                            })
                                            .flatMap(([specialty, yearsOrSubcategories]) => {
                                                // Check if yearsOrSubcategories is an object
                                                if (typeof yearsOrSubcategories === 'object' && yearsOrSubcategories !== null) {
                                                    return Object.entries(yearsOrSubcategories).flatMap(([subSpecialty, years]) => {
                                                        // If years is an object, format it for rendering
                                                        if (typeof years === 'object' && years !== null) {
                                                            return Object.entries(years)
                                                                .filter(([yearKey, yearValue]) => yearValue) // Only show entries with truthy values
                                                                .map(([yearKey, yearValue]) => (
                                                                    <Typography key={`${subSpecialty}-${yearKey}`} variant="body2" paragraph sx={{ display: 'inline', mr: 1 }}>
                                                                        <br /> <strong>{`${subSpecialty.replace(/([A-Z])/g, ' $1').trim()} (${yearKey}):`}</strong> {yearValue}
                                                                    </Typography>
                                                                ));
                                                        }
                                                        // If years is a string or number and truthy, render it
                                                        if (years) {
                                                            return (
                                                                <Typography key={subSpecialty} variant="body2" paragraph sx={{ display: 'inline', mr: 1 }}>
                                                                    <br /> {subSpecialty.replace(/([A-Z])/g, ' $1').trim()}
                                                                </Typography>
                                                            );
                                                        }
                                                        return []; // Return an empty array if the value is not truthy
                                                    });
                                                } else if (yearsOrSubcategories) {
                                                    // If it's a direct value and truthy, render it
                                                    return (
                                                        <Typography key={specialty} variant="body2" paragraph sx={{ display: 'inline', mr: 1 }}>
                                                            <br /> <strong>{specialty.replace(/([A-Z])/g, ' $1').trim()}:</strong> {yearsOrSubcategories}
                                                        </Typography>
                                                    );
                                                }
                                                return []; // Return an empty array if the value is not truthy
                                            })
                                    ) : (
                                        "N/A"
                                    )}
                                </Box>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
                                        Total Years of Experience
                                    </InputLabel>

                                    <Typography variant="body1">
                                        {formik.values.preferences.total_years_experience || "N/A"}
                                    </Typography>

                                </Grid>
                            </Typography>


                        </Box>

                    </Box>

                    <Box>

                    </Box>
                    <Dialog maxWidth="md" open={openQualifications} onClose={() => setOpenQualification(false)}>
                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                                Edit Qualifications
                            </Typography>

                            <IconButton onClick={() => setOpenQualification(false)}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <Divider sx={{ width: '90%', color: 'text.secondary', mb: 1, mx: "auto" }} />
                        <DialogContent >
                            <Box component="form" onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        {/* <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                Professional area *
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  name="preferences.professional_area"
                  value={formik.values.preferences.professional_area}
                  onChange={handleChange}
                  error={formik.touched.preferences?.professional_area && Boolean(formik.errors.preferences?.professional_area)}
                  displayEmpty
                  renderValue={(selected) => selected || <span style={{ color: '#aaa' }}>Select Professional Area</span>}
                >

                  {designList.map((design) => (
                    <MenuItem key={design} value={design}>
                      {design}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.preferences?.professional_area && formik.errors.preferences?.professional_area && (
                  <Typography variant="caption" color="error">
                    {formik.errors.preferences.professional_area}
                  </Typography>
                )}
              </FormControl> */}

                                        <ExperienceForm
                                            placeHolder={trueTopSpecialities}
                                            formik={formik}
                                            specialties={formik.values.preferences.top_specialities}
                                            experienceYears={formik.values.preferences.experienceYears}
                                            selectedCategories={selectedCategories}
                                            experienceList={experienceList}
                                            handleChipClick={handleChipClick}
                                            handleSpecialtyChange={handleSpecialtyChange}
                                            selectedSpecialty={selectedSpecialty}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InputLabel sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                                            Total Years Of Experience
                                        </InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                name="preferences.total_years_experience"
                                                value={formik.values.preferences.total_years_experience}
                                                onChange={handleChange}
                                                displayEmpty
                                                renderValue={(selected) => selected || <span style={{ color: '#aaa' }}>Select Experience</span>}
                                            >

                                                {experienceList.map((experience) => (
                                                    <MenuItem key={experience} value={experience}>
                                                        {experience}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {formik.touched.preferences?.total_years_experience && formik.errors.preferences?.total_years_experience && (
                                                <Typography variant="caption" color="error">
                                                    {formik.errors.preferences.total_years_experience}
                                                </Typography>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* <InputLabel sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                                    Specilities
                                </InputLabel>
                                <ExperienceForm
                                    placeHolder={formik.values.preferences.top_specialities ? parseJSON(formik.values.preferences.top_specialities) : null}
                                    formik={formik}
                                    name={"preferences.top_specialities"}
                                    specialties={specialtiesList}
                                    profession={formik.values.preferences.top_specialities ? parseJSON(formik.values.preferences.top_specialities) : null}
                                    experienceYears={formik.values.preferences.experienceYears}
                                    selectedCategories={selectedCategories}
                                    experienceList={experienceList}
                                    handleCheckboxChange={handleSpecialityChange}
                                    handleSubcategoryChange={handleSubcategoryChange}

                                /> */}

                                <DialogActions>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mb: 1 }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: 'primary.main'
                                                }
                                            }}
                                        >
                                            <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none' }}>
                                                Save
                                            </Typography>
                                        </Button>
                                    </Box>
                                </DialogActions>
                            </Box>
                        </DialogContent>


                    </Dialog>
                </Box>

                <Box>
                    <Box sx={{ display: "flex" }}>
                        <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                            Job Preferences
                        </Typography>

                        {/* <Button
                            onClick={() => setOpenPreferences(true)}
                            type="submit"
                            variant="standard"
                            endIcon={<EditIcon />}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                color: 'primary.main',
                                '&:hover': {
                                    backgroundColor: '#F5F5F5'
                                },
                                marginLeft: "auto"
                            }}
                        >
                            <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                                Edit
                            </Typography>
                        </Button> */}
                        <Typography
                            component="span"
                            variant="body1"

                            onClick={() => setOpenPreferences(true)}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                color: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'inherit'
                                },
                                marginLeft: "auto",
                                mt: { xs: 0.5, sm: 0 },
                                fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", mt: 2
                            }}
                        >

                            Edit
                            <EditIcon sx={{ mr: 1, ml: 1, fontSize: '16px', }} />
                        </Typography>
                    </Box>
                    <Box>


                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.1 }}>
                            Type of Employment
                        </InputLabel>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <Box
                                sx={{
                                    mb: 2,
                                    p: 3,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '4px',
                                    backgroundColor: '#f5f5f5',
                                    color: 'black',
                                }}
                            >
                                <Typography variant="body1">
                                    <Box mt={2}>
                                        {formik.values.preferences?.employment_type ? (
                                            <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", mt: -1, ml: -0.1 }}>
                                                {formik.values.preferences.employment_type["fullTime"] ? "Full Time" : ""}
                                                {formik.values.preferences.employment_type["fullTime"] && formik.values.preferences.employment_type["partTime"] ? ", " : ""}
                                                {formik.values.preferences.employment_type["partTime"] ? "Part Time" : ""}
                                            </Typography>
                                        ) : (
                                            <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                                                N/A
                                            </Typography>
                                        )}
                                    </Box>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.1 }}>
                                Job Search Status
                            </InputLabel>
                            <Box
                                sx={{
                                    mb: 2,
                                    p: 2,
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '4px',
                                    backgroundColor: '#f5f5f5',
                                    color: 'black',
                                }}
                            >
                                <Typography variant="body1">
                                    {formik.values.preferences?.job_search_status || "N/A"}
                                </Typography>
                            </Box>
                        </Grid>


                    </Box>

                    <Dialog sx={{ maxWidth: "100%" }} open={openPreferences} onClose={() => setOpenPreferences(false)}>

                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                                Edit Job Preferences
                            </Typography>

                            <IconButton onClick={() => setOpenPreferences(false)}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <Divider sx={{ width: '90%', color: 'text.secondary', mb: 1, mx: "auto" }} />

                        <DialogContent>

                            <Box component="form" onSubmit={formik.handleSubmit}>

                                <Grid item xs={12}>
                                    <InputLabel mt={3} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                                        Type of Employment
                                    </InputLabel>
                                    <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="partTime"
                                                    checked={formik.values.preferences.employment_type.partTime}
                                                    onChange={handleEmploymentTypeChange}
                                                    sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                                                />
                                            }
                                            label="Part Time"
                                            sx={{ mr: 5, mb: 1 }}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="fullTime"
                                                    checked={formik.values.preferences.employment_type.fullTime}
                                                    onChange={handleEmploymentTypeChange}
                                                    sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                                                />
                                            }
                                            label="Full Time"
                                            sx={{ mr: 5, mb: 1 }}
                                        />
                                    </Box>

                                    {formik.touched.preferences?.employment_type && formik.errors.preferences?.employment_type && (
                                        <Typography variant="body2" color="error">
                                            {formik.errors.jobDetails.employment_type}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <InputLabel mt={3} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
                                        Job Search Status
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            name="preferences.job_search_status"
                                            value={formik.values.preferences.job_search_status}
                                            onChange={handleChange}
                                            error={formik.touched.preferences?.job_search_status && Boolean(formik.errors.preferences?.job_search_status)}
                                            displayEmpty
                                            renderValue={(selected) => selected || <span style={{ color: '#aaa' }}>Select Status</span>}
                                        >
                                            <MenuItem value="" disabled>
                                                <em>Select Status</em>
                                            </MenuItem>
                                            {jobStatusList.map((jobStatus) => (
                                                <MenuItem key={jobStatus} value={jobStatus}>
                                                    {jobStatus}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {formik.touched.preferences?.job_search_status && formik.errors.preferences?.job_search_status && (
                                            <Typography variant="caption" color="error">
                                                {formik.errors.preferences.job_search_status}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                                <DialogActions>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mb: 1 }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: 'primary.main'
                                                }
                                            }}
                                        >
                                            <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none' }}>
                                                Save
                                            </Typography>
                                        </Button>
                                    </Box>
                                </DialogActions>
                            </Box>
                        </DialogContent>

                    </Dialog>
                </Box>





                <Box>
                    <Box sx={{ display: "flex" }}>
                        <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                            Location
                        </Typography>

                        {/* <Button
                            onClick={() => setOpenLocation(true)}
                            type="submit"
                            variant="standard"
                            endIcon={<EditIcon />}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                color: 'primary.main',
                                '&:hover': {
                                    backgroundColor: '#F5F5F5'
                                },
                                marginLeft: "auto"
                            }}
                        >
                            <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                                Edit
                            </Typography>
                        </Button> */}

                        <Typography
                            component="span"
                            variant="body1"

                            onClick={() => setOpenLocation(true)}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                color: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'inherit'
                                },
                                marginLeft: "auto",
                                mt: { xs: 0.5, sm: 0 },
                                fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", mt: 2
                            }}
                        >

                            Edit
                            <EditIcon sx={{ mr: 1, ml: 1, fontSize: '16px', }} />
                        </Typography>
                    </Box>

                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.5 }}>
                        Are you interested in working remotely?
                    </InputLabel>
                    <Box
                        sx={{
                            mb: 2,
                            p: 2,
                            border: '1px solid #d9d9d9',
                            borderRadius: '4px',
                            backgroundColor: '#f5f5f5',
                            color: 'black',
                        }}
                    >
                        <Typography variant="body1">
                            {formik.values.preferences?.remote_work === '0' ? 'No' : 'Yes'}
                        </Typography>


                    </Box>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.5 }}>
                        Are you interested in relocation?
                    </InputLabel>
                    <Box
                        sx={{
                            mb: 2,
                            p: 2,
                            border: '1px solid #d9d9d9',
                            borderRadius: '4px',
                            backgroundColor: '#f5f5f5',
                            color: 'black',
                        }}
                    >
                        <Typography variant="body1">
                            {formik.values.preferences?.relocation === '0' ? 'No' : 'Yes'}
                        </Typography>
                    </Box>

                    <Dialog open={openLocation} onClose={() => setOpenLocation(false)}>

                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                                Edit Location
                            </Typography>

                            <IconButton onClick={() => setOpenLocation(false)}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <Divider sx={{ width: '90%', color: 'text.secondary', mb: 1, mx: "auto" }} />
                        <DialogContent >

                            <Box component="form" onSubmit={formik.handleSubmit}>

                                <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                                    Are you interested in working remotely?
                                </InputLabel>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <RadioGroup
                                            aria-label="remoteCandidates"
                                            name="preferences.remote_work"
                                            value={formik.values.preferences.remote_work}
                                            onChange={(event) => {
                                                formik.setFieldValue("preferences.remote_work", event.target.value);
                                            }}
                                            sx={{ flexDirection: 'row' }}
                                        >
                                            <FormControlLabel
                                                value="1"
                                                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                                                label="Yes"
                                                sx={{ marginRight: '20px' }}
                                            />
                                            <FormControlLabel
                                                value="0"
                                                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                                                label="No"
                                                sx={{ marginRight: '20px' }}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                                    Are you interested in relocation?
                                </InputLabel>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <RadioGroup
                                            aria-label="relocation"
                                            name="preferences.relocation"
                                            value={formik.values.preferences.relocation}
                                            onChange={(event) => {
                                                formik.setFieldValue("preferences.relocation", event.target.value);
                                            }}
                                            sx={{ flexDirection: 'row' }}
                                        >
                                            <FormControlLabel
                                                value="1"
                                                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                                                label="Yes"
                                                sx={{ marginRight: '20px' }}
                                            />
                                            <FormControlLabel
                                                value="0"
                                                control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />}
                                                label="No"
                                                sx={{ marginRight: '20px' }}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <DialogActions>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mb: 1 }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: 'primary.main'
                                                }
                                            }}
                                        >
                                            <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none' }}>
                                                Save
                                            </Typography>
                                        </Button>
                                    </Box>
                                </DialogActions>
                            </Box>

                        </DialogContent>

                    </Dialog>
                </Box>


                <Box>
                    <Box sx={{ display: "flex", mt: 4 }}>
                        <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                            Salary Expectation
                        </Typography>
                        {/* <Button
                            onClick={() => setOpenSalary(true)}
                            type="submit"
                            variant="standard"
                            endIcon={<EditIcon />}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                color: 'primary.main',
                                '&:hover': {
                                    backgroundColor: '#F5F5F5'
                                },
                                marginLeft: "auto"
                            }}
                        >
                            <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                                Edit
                            </Typography>
                        </Button> */}
                        <Typography
                            component="span"
                            variant="body1"

                            onClick={() => setOpenSalary(true)}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                color: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'inherit'
                                },
                                marginLeft: "auto",
                                mt: { xs: 0.5, sm: 0 },
                                fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", mt: 2
                            }}
                        >
                            Edit
                            <EditIcon sx={{ mr: 1, ml: 1, fontSize: '16px', }} />
                        </Typography>
                    </Box>

                    <strong> From:</strong>
                    <Box
                        sx={{
                            mb: 2,
                            p: 2,
                            border: '1px solid #d9d9d9',
                            borderRadius: '4px',
                            backgroundColor: '#f5f5f5',
                            color: 'black',
                        }}
                    >
                        <Typography variant="body1">
                            {formik.values.about_you?.salary_range ? (
                                <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.3 }}>
                                    {formik.values.about_you.salary_range.from}
                                </Typography>

                            ) : (
                                <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                                    N/A
                                </Typography>
                            )}
                        </Typography>
                    </Box>
                    <strong>  To:</strong>
                    <Box
                        sx={{
                            mb: 2,
                            p: 2,
                            border: '1px solid #d9d9d9',
                            borderRadius: '4px',
                            backgroundColor: '#f5f5f5',
                            color: 'black',
                        }}
                    >
                        <Typography variant="body1">
                            {formik.values.about_you?.salary_range ? (
                                <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.3 }}>
                                    {formik.values.about_you.salary_range.to}
                                </Typography>

                            ) : (
                                <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                                    N/A
                                </Typography>
                            )}
                        </Typography>
                    </Box>
                    <Dialog open={openSalary} onClose={() => setOpenSalary(false)}>
                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                                Edit Salary Expectation
                            </Typography>
                            <IconButton onClick={() => setOpenSalary(false)}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <Divider sx={{ width: '90%', color: 'text.secondary', mb: 1, mx: "auto" }} />
                        <DialogContent >
                            <Box>


                                <Box component="form" onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                                Specify the salary range (annually) *
                                            </InputLabel>
                                            <TextField
                                            type="number"
                                                fullWidth
                                                placeholder="From"
                                                name="about_you.salary_range.from"
                                                value={formik.values.about_you.salary_range.from}
                                                onChange={handleInputSalaryChange}

                                            />

                                            {formik.touched.about_you?.salary_range?.from && formik.errors.about_you?.salary_range?.from && (
                                                <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                                    {formik.errors.about_you.salary_range.from}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                                To *
                                            </InputLabel>
                                            <TextField
                                            type="number"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: false,
                                                    style: { fontSize: '14px', color: 'gray' }
                                                }}

                                                placeholder="To"
                                                name="about_you.salary_range.to"
                                                value={formik.values.about_you.salary_range.
                                                    to}
                                                onChange={handleInputSalaryChange}
                                            />
                                            {formik.touched.about_you?.salary_range?.to && formik.errors.about_you?.salary_range?.to && (
                                                <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                                    {formik.errors.about_you.salary_range.to}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <DialogActions>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mb: 1 }}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: 'primary.main',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: 'primary.main'
                                                    }
                                                }}
                                            >
                                                <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none' }}>
                                                    Save
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </DialogActions>
                                </Box>
                            </Box>

                        </DialogContent>

                    </Dialog>
                </Box>


                <Box>
                    <Box sx={{ display: "flex", mt: 4 }}>
                        <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                            Links
                        </Typography>
                        {/* <Button
                            onClick={() => setOpenLinks(true)}
                            type="submit"
                            variant="standard"
                            endIcon={<EditIcon />}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                color: 'primary.main',
                                '&:hover': {
                                    backgroundColor: '#F5F5F5'
                                },
                                marginLeft: "auto"
                            }}
                        >
                            <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                                Edit
                            </Typography>
                        </Button> */}
                        <Typography
                            component="span"
                            variant="body1"

                            onClick={() => setOpenLinks(true)}
                            sx={{
                                backgroundColor: '#F5F5F5',
                                color: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'inherit'
                                },
                                marginLeft: "auto",
                                mt: { xs: 0.5, sm: 0 },
                                fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", mt: 2
                            }}
                        >

                            Edit
                            <EditIcon sx={{ mr: 1, ml: 1, fontSize: '16px', }} />
                        </Typography>
                    </Box>
                    <Box>

                        <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                            LinkedIn Profile URL
                        </Typography>
                        <Box
                            sx={{
                                mb: 2,
                                p: 2,
                                border: '1px solid #d9d9d9',
                                borderRadius: '4px',
                                backgroundColor: '#f5f5f5',
                                color: 'black',
                            }}
                        >
                            <Typography variant="body1">
                                {formik.values.about_you?.linkedin_url || "N/A"}
                            </Typography>
                        </Box>


                        <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                            Personal Blog URL

                        </Typography>
                        <Box
                            sx={{
                                mb: 2,
                                p: 2,
                                border: '1px solid #d9d9d9',
                                borderRadius: '4px',
                                backgroundColor: '#f5f5f5',
                                color: 'black',
                            }}
                        >
                            <Typography variant="body1">
                                {formik.values.about_you?.personal_blog_url || "N/A"}
                            </Typography>
                        </Box>
                    </Box>

                    <Dialog open={openLinks} onClose={() => setOpenLinks(false)}>
                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                                Edit Links
                            </Typography>
                            <IconButton onClick={() => setOpenLinks(false)}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <Divider sx={{ width: '90%', color: 'text.secondary', mb: 1, mx: "auto" }} />
                        <DialogContent >

                            <Box component="form" onSubmit={formik.handleSubmit}>

                                <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                                    LinkedIn Profile URL
                                </Typography>
                                <TextField
                                    name="about_you.linkedin_url"
                                    value={formik.values.about_you.linkedin_url}
                                    onChange={handleChange}
                                    placeholder="LinkedIn Profile URL"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: false,
                                        style: {
                                            fontSize: '14px',
                                            color: 'gray'
                                        }
                                    }}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                                <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                                    Personal Blog URL
                                </Typography>
                                <TextField
                                    name="about_you.personal_blog_url"
                                    value={formik.values.about_you.personal_blog_url}
                                    onChange={handleChange}
                                    placeholder="Personal Blog URL"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: false,
                                        style: {
                                            fontSize: '14px',
                                            color: 'gray'
                                        }
                                    }}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                                <DialogActions>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mb: 1 }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: 'primary.main'
                                                }
                                            }}
                                        >
                                            <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none' }}>
                                                Save
                                            </Typography>
                                        </Button>
                                    </Box>
                                </DialogActions>
                            </Box>

                        </DialogContent>

                    </Dialog>
                </Box>



                {/* Work Experience Section */}
                <Box sx={{ display: "flex", mt: 4 }}>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                        Work Experience
                    </Typography>
                    {/* <Button
                        onClick={() => setOpenWorkExperience(true)}
                        type="submit"
                        variant="standard"
                        endIcon={<EditIcon />}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: '#F5F5F5'
                            },
                            marginLeft: "auto"
                        }}
                    >
                        <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                            Edit
                        </Typography>
                    </Button> */}
                    <Typography
                        component="span"
                        variant="body1"

                        onClick={() => setOpenWorkExperience(true)}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'inherit'
                            },
                            marginLeft: "auto",
                            mt: { xs: 0.5, sm: 0 },
                            fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", mt: 2
                        }}
                    >

                        Edit
                        <EditIcon sx={{ mr: 1, ml: 1, fontSize: '16px', }} />
                    </Typography>
                </Box>
                {formik.values.background?.work_experience ? (
                    (() => {
                        const parsedWorkExperience = formik.values.background.work_experience;
                        const allCompanies = parsedWorkExperience.map(exp => exp.company_name || "N/A").join(", ");
                        const allJobTitles = parsedWorkExperience.map(exp => exp.job_title || "N/A").join(", ");
                        const allStartDates = parsedWorkExperience.map(exp => exp.start || "N/A").join(", ");
                        const allEndDates = parsedWorkExperience.map(exp => exp.end || "N/A").join(", ");
                        const allDescriptions = parsedWorkExperience.map(exp => exp.description || "N/A").join(", ");
                        return (
                            <Box mt={2}>
                                <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                    <strong>Company Name:</strong> {allCompanies}
                                </Typography>
                                <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                    <strong>Job Title:</strong> {allJobTitles}
                                </Typography>
                                {/* <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                    <strong>Start Date:</strong> {allStartDates}
                                </Typography>
                                <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                    <strong>End Date:</strong> {allEndDates}
                                </Typography> */}
                                <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                    <strong>Start Date:</strong> {isNaN(new Date(allStartDates)) ? "N/A" : allStartDates}
                                </Typography>
                                <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                    <strong>End Date:</strong> {isNaN(new Date(allEndDates)) ? "N/A" : allEndDates}
                                </Typography>
                                <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                    <strong>Description:</strong> {allDescriptions}
                                </Typography>
                            </Box>
                        );
                    })()
                ) : (
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                        N/A
                    </Typography>
                )}

                <Box>
                    <Dialog width="md" open={openWorkExperience} onClose={() => setOpenWorkExperience(false)}>
                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                                Edit Work Experience
                            </Typography>
                            <IconButton onClick={() => setOpenWorkExperience(false)}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <Divider sx={{ width: '90%', color: 'text.secondary', mb: 1, mx: "auto" }} />
                        <DialogContent >
                            <Box component="form" onSubmit={formik.handleSubmit} mt={2}>
                                <Grid item xs={12}>
                                    <Box mt={2}>
                                        <Grid container spacing={2}>
                                            {formik.values.background.work_experience.map((experience, index) => (
                                                <Grid item xs={12} key={index}>
                                                    <Grid container spacing={2}>

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <InputLabel mt={2} sx={{ alignSelf: 'flex-start', fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                                                Company Name *
                                                            </InputLabel>
                                                            <TextField
                                                                name={`background.work_experience[${index}].company_name`}
                                                                placeholder="Company Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={experience.company_name}
                                                                onChange={(e) => handleWorkExperienceChange(index, "company_name", e.target.value)}
                                                                sx={{ mb: 2 }}
                                                            />
                                                            {formik.touched.background?.work_experience?.[index]?.company_name && formik.errors.background?.work_experience?.[index]?.company_name ? (
                                                                <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                                                    {formik.errors.background.work_experience[index].company_name}
                                                                </Typography>
                                                            ) : null}

                                                        </Grid>
                                                        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel mt={2} sx={{ alignSelf: 'flex-start', fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                            Company Website *
                                        </InputLabel>
                                        <TextField
                                            placeholder="Company Website"
                                            variant="outlined"
                                            fullWidth
                                            value={experience.companyWebsite}
                                            onChange={(e) => handleWorkExperienceChange(index, "companyWebsite", e.target.value)}
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid> */}

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <InputLabel mt={2} sx={{ alignSelf: 'flex-start', fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                                                Job Title *
                                                            </InputLabel>
                                                            <TextField
                                                                name={`background.work_experience[${index}].job_title`}
                                                                placeholder="Job Title"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={experience.job_title}
                                                                onChange={(e) => handleWorkExperienceChange(index, "job_title", e.target.value)}
                                                                sx={{ mb: 2 }}
                                                            />
                                                            {formik.touched.background?.work_experience?.[index]?.job_title && formik.errors.background?.work_experience?.[index]?.job_title ? (
                                                                <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                                                    {formik.errors.background.work_experience[index].job_title}
                                                                </Typography>
                                                            ) : null}

                                                        </Grid>
                                                        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox sx={{ alignSelf: 'flex-start', color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                                                                        name={`background.work_experience[${index}].is_current`}
                                                                        checked={experience.is_current}
                                                                        onChange={(e) => handleWorkExperienceChange(index, "is_current", e.target.checked)}
                                                                    />
                                                                }
                                                                label="I am currently working here"
                                                            />

                                                        </Grid> */}
                                                        {!experience.is_current && (
                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
                                                                    Started
                                                                </InputLabel>

                                                                <Box sx={{ position: 'relative', py: '20px', mr: 2 }}>
                                                                    <div className='calendar'>
                                                                        <Grid item xs={12}>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    renderInput={(props) => <TextField {...props} />}
                                                                                    name={`background.work_experience[${index}].start`}
                                                                                    value={experience.start ? dayjs(experience.start) : null} // Ensure `value` is a `dayjs` object
                                                                                    onChange={(newValue) => handleStartDateChange(index, newValue)}
                                                                                    inputFormat="MM/dd/yyyy"
                                                                                    views={['month', 'year']}
                                                                                    sx={{ width: "100%" }}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </Grid>
                                                                    </div>
                                                                </Box>

                                                            </Grid>

                                                        )}
                                                        <Box>

                                                        </Box>
                                                        {!experience.is_current && (

                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
                                                                    Finished
                                                                </InputLabel>


                                                                <Box sx={{ position: 'relative', py: '20px', mr: 2 }}>
                                                                    <div className='calendar'>
                                                                        <Grid item xs={12}>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    renderInput={(props) => <TextField {...props} />}
                                                                                    name={`background.work_experience[${index}].end`}
                                                                                    value={experience.end ? dayjs(experience.end) : null} // Ensure `value` is a `dayjs` object
                                                                                    onChange={(newValue) => handleEndDateChange(index, newValue)}
                                                                                    inputFormat="MM/dd/yyyy"
                                                                                    views={['month', 'year']}
                                                                                    sx={{ width: "100%" }}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </Grid>
                                                                    </div>
                                                                </Box>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            sx={{ alignSelf: 'flex-start', color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                                                                            name={`background.work_experience[${index}].is_current`}
                                                                            checked={experience.is_current}
                                                                            onChange={(e) => handleWorkExperienceChange(index, "is_current", e.target.checked)}
                                                                        />
                                                                    }
                                                                    label="I am currently working here"
                                                                />
                                                            </Grid>
                                                        )}
                                                        {experience.is_current && (
                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5 }}>
                                                                    Started
                                                                </InputLabel>

                                                                <Box sx={{ position: 'relative', py: '20px', mr: 2 }}>
                                                                    <div className='calendar'>
                                                                        <Grid item xs={12}>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <DatePicker
                                                                                    renderInput={(props) => <TextField {...props} />}
                                                                                    name={`background.work_experience[${index}].start`}
                                                                                    value={experience.start ? dayjs(experience.start) : null} // Ensure `value` is a `dayjs` object
                                                                                    onChange={(newValue) => handleStartDateChange(index, newValue)}
                                                                                    inputFormat="MM/dd/yyyy"
                                                                                    views={['month', 'year']}
                                                                                    sx={{ width: "100%" }}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </Grid>
                                                                    </div>
                                                                </Box>

                                                            </Grid>

                                                        )}
                                                        <Box>

                                                        </Box>
                                                        {experience.is_current && (

                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>


                                                                <Box sx={{ position: 'relative', py: '20px', ml: 10, mt: 4 }}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                sx={{ alignSelf: 'flex-start', color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }}
                                                                                name={`background.work_experience[${index}].is_current`}
                                                                                checked={experience.is_current}
                                                                                onChange={(e) => handleWorkExperienceChange(index, "is_current", e.target.checked)}
                                                                            />
                                                                        }
                                                                        label="I am currently working here"
                                                                    />
                                                                </Box>

                                                            </Grid>
                                                        )}
                                                        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5, }}>
                                        Started
                                    </InputLabel>

                                    <Box sx={{ position: 'relative', py: '20px', mr: 2 }}>
                                        <div className='calendar'>

                                            <div

                                            >
                                                <Grid item xs={12}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            renderInput={(props) => <TextField {...props} />}
                                                            name={`background.work_experience[${index}].start`}
                                                           
                                                            value={experience.startedAt}
                                                            onChange={(newValue) => handleDateChange(newValue, "start")}
                                                            inputFormat="MM/dd/yyyy hh:mm a"
                                                            className='w-full'
                                                            views={['month', 'year']}
                                                            sx={{ width: "100%" }}
                                                        />
                                                      
                                                    </LocalizationProvider>
                                                </Grid>

                                            </div>
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", mb: 0.5, }}>
                                        Finished
                                    </InputLabel>
                                    <Box sx={{ position: 'relative', py: '20px' }}>

                                        <Grid item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                     renderInput={(props) => <TextField {...props} />}
                                                     name={`background.work_experience[${index}].end`}
                                                    
                                                     value={experience.startedAt}
                                                     onChange={(newValue) => handleDateChange(newValue, "end")}
                                                     inputFormat="MM/dd/yyyy hh:mm a"
                                                     className='w-full'
                                                     views={['month', 'year']}
                                                     sx={{ width: "100%" }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>

                                    </Box>



                                </Grid> */}


                                                        {/* </Box> */}

                                                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                            Year *
                                        </InputLabel>
                                        <DatePicker
                                            selected={startYear}
                                            onChange={handleStartYearChange}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            sx={{
                                                '& .react-datepicker__input-container': {
                                                    height: '200px', // Adjust the height as needed
                                                }
                                            }}
                                        />
                                    </Grid> */}
                                                        <Grid item xs={12}>
                                                            <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                                                                Description
                                                            </Typography>
                                                            <TextField
                                                                name={`background.work_experience[${index}].description`}
                                                                placeholder="Description"
                                                                variant="outlined"
                                                                fullWidth
                                                                multiline
                                                                rows={3}
                                                                value={experience.description}
                                                                onChange={(e) => handleWorkExperienceChange(index, "description", e.target.value)}
                                                                sx={{ mb: 2 }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                onClick={() => handleRemoveWorkExperience(index)}
                                                                variant="standard"
                                                                sx={{
                                                                    mb: 2,
                                                                    backgroundColor: 'secondary.main',
                                                                    color: 'text.secondary',
                                                                    '&:hover': {
                                                                        backgroundColor: 'secondary.main'
                                                                    }
                                                                }}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Grid item xs={12}>
                                                <Button
                                                    type="button"
                                                    onClick={handleAddWorkExperience}
                                                    variant="standard"
                                                    sx={{
                                                        backgroundColor: 'white',
                                                        color: 'primary.main',
                                                        '&:hover': {
                                                            backgroundColor: 'white'
                                                        }
                                                    }}
                                                >
                                                    <AddIcon sx={{ mt: -0.5, ml: -3, color: 'primary.main' }} />
                                                    <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", backgroundColor: "white" }}>
                                                        Add More
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <DialogActions>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mb: 1 }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: 'primary.main'
                                                }
                                            }}
                                        >
                                            <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none' }}>
                                                Save
                                            </Typography>
                                        </Button>
                                    </Box>
                                </DialogActions>
                            </Box>

                        </DialogContent>

                    </Dialog>
                </Box>


                {/* Education Section */}
                <Box sx={{ display: "flex", mt: 4 }}>

                    {/* <Button
                        onClick={() => setOpenEducation(true)}
                        type="submit"
                        variant="standard"
                        endIcon={<EditIcon />}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: '#F5F5F5'
                            },
                            marginLeft: "auto"
                        }}
                    >
                        <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                            Edit
                        </Typography>
                    </Button> */}
                    <Typography
                        component="span"
                        variant="body1"

                        onClick={() => setOpenEducation(true)}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'inherit'
                            },
                            marginLeft: "auto",
                            mt: { xs: 0.5, sm: 0 },
                            fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", mt: 2
                        }}
                    >

                        Edit
                        <EditIcon sx={{ mr: 1, ml: 1, fontSize: '16px', }} />
                    </Typography>
                </Box>
                <Box>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                        Education
                    </Typography>
                    {formik.values.background?.education ? (
                        (() => {
                            const parsedEducation = formik.values.background.education
                            const allUniversities = parsedEducation.map(edu => edu.university_or_collage || "N/A").join(", ");
                            const allGraduationYears = parsedEducation.map(edu => edu.graduation_year || "N/A").join(", ");
                            const allFieldsOfStudy = parsedEducation.map(edu => edu.field_of_study || "N/A").join(", ");
                            const allDegrees = parsedEducation.map(edu => edu.degree || "N/A").join(", ");
                            return (
                                <Box mt={2}>
                                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                        <strong>University or College:</strong> {allUniversities}
                                    </Typography>
                                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                        <strong>Graduation Year:</strong> {allGraduationYears}
                                    </Typography>
                                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                        <strong>Field of Study:</strong> {allFieldsOfStudy}
                                    </Typography>
                                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                        <strong>Degree:</strong> {allDegrees}
                                    </Typography>
                                </Box>
                            );
                        })()
                    ) : (
                        <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                            N/A
                        </Typography>
                    )}

                    {/* Add Education Details */}
                </Box>
                <Dialog open={openEducation} onClose={() => setOpenEducation(false)}>
                    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                            Edit Education
                        </Typography>
                        <IconButton onClick={() => setOpenEducation(false)}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider sx={{ width: '90%', color: 'text.secondary', mb: 1, mx: "auto" }} />
                    <DialogContent>
                        <Box component="form" onSubmit={formik.handleSubmit} mt={2}>
                            <Grid item xs={12}>
                                <Box mt={2}>
                                    <Grid container spacing={2}>
                                        {formik.values.background.education.map((education, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <InputLabel
                                                            mt={2}
                                                            sx={{
                                                                fontWeight: "600",
                                                                fontSize: "14px",
                                                                color: "text.primary",
                                                                mb: 0.5,
                                                            }}
                                                        >
                                                            University/College *
                                                        </InputLabel>
                                                        <TextField
                                                            name={`background.education[${index}].university_or_collage`}
                                                            value={education.university_or_collage}
                                                            onChange={(e) => handleEducationChange(index, "university_or_collage", e.target.value)}
                                                            placeholder="Enter University/College"
                                                            variant="outlined"
                                                            fullWidth
                                                            sx={{ mb: 2 }}
                                                        />
                                                        {formik.touched.background?.education?.[index]?.university_or_collage && formik.errors.background?.education?.[index]?.university_or_collage ? (
                                                            <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                                                {formik.errors.background.education[index].university_or_collage}
                                                            </Typography>
                                                        ) : null}
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <InputLabel
                                                            mt={2}
                                                            sx={{
                                                                fontWeight: "600",
                                                                fontSize: "14px",
                                                                color: "text.primary",
                                                                mb: 0.5,
                                                            }}
                                                        >
                                                            Graduation Year *
                                                        </InputLabel>
                                                        <TextField
                                                            name={`background.education[${index}].graduation_year`}
                                                            value={education.graduation_year}
                                                            onChange={(e) => handleEducationChange(index, "graduation_year", e.target.value)}
                                                            placeholder="Select Graduation Year"
                                                            variant="outlined"
                                                            fullWidth
                                                            sx={{ mb: 2 }}
                                                        />
                                                        {formik.touched.background?.education?.[index]?.graduation_year && formik.errors.background?.education?.[index]?.graduation_year ? (
                                                            <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                                                {formik.errors.background.education[index].graduation_year}
                                                            </Typography>
                                                        ) : null}
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <InputLabel
                                                            mt={2}
                                                            sx={{
                                                                fontWeight: "600",
                                                                fontSize: "14px",
                                                                color: "text.primary",
                                                                mb: 0.5,
                                                            }}
                                                        >
                                                            Field of Study *
                                                        </InputLabel>
                                                        <TextField
                                                            name={`background.education[${index}].field_of_study`}
                                                            value={education.field_of_study}
                                                            onChange={(e) => handleEducationChange(index, "field_of_study", e.target.value)}
                                                            placeholder="Select Field of Study"
                                                            variant="outlined"
                                                            fullWidth
                                                            sx={{ mb: 2 }}
                                                        />
                                                        {formik.touched.background?.education?.[index]?.field_of_study && formik.errors.background?.education?.[index]?.field_of_study ? (
                                                            <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                                                {formik.errors.background.education[index].field_of_study}
                                                            </Typography>
                                                        ) : null}
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <InputLabel
                                                            mt={2}
                                                            sx={{
                                                                fontWeight: "600",
                                                                fontSize: "14px",
                                                                color: "text.primary",
                                                                mb: 0.5,
                                                            }}
                                                        >
                                                            Degree *
                                                        </InputLabel>
                                                        <TextField
                                                            name={`background.education[${index}].degree`}
                                                            value={education.degree}
                                                            onChange={(e) => handleEducationChange(index, "degree", e.target.value)}
                                                            placeholder="Select Degree"
                                                            variant="outlined"
                                                            fullWidth
                                                            sx={{ mb: 2 }}
                                                        />
                                                        {formik.touched.background?.education?.[index]?.degree && formik.errors.background?.education?.[index]?.degree ? (
                                                            <Typography variant="body2" sx={{ color: '#C70039', textAlign: 'left', fontSize: "12px" }}>
                                                                {formik.errors.background.education[index].degree}
                                                            </Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            onClick={() => handleRemoveEducation(index)}
                                                            variant="standard"
                                                            sx={{
                                                                mb: 2,
                                                                backgroundColor: 'secondary.main',
                                                                color: 'text.secondary',
                                                                '&:hover': {
                                                                    backgroundColor: 'secondary.main'
                                                                }
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                        <Grid item xs={12}>
                                            <Button
                                                type="button"
                                                onClick={handleAddEducation}
                                                variant="standard"
                                                sx={{
                                                    backgroundColor: 'white',
                                                    color: 'primary.main',
                                                    '&:hover': {
                                                        backgroundColor: 'white'
                                                    }
                                                }}
                                            >
                                                <AddIcon sx={{ mt: -0.5, ml: -3, color: 'primary.main' }} />
                                                <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", backgroundColor: "white" }}>
                                                    Add More
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <DialogActions>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mb: 1 }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: 'primary.main'
                                            }
                                        }}
                                    >
                                        <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none' }}>
                                            Save
                                        </Typography>
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Box>
                    </DialogContent>

                </Dialog>

                {/* Language Section */}
                <Box sx={{ display: "flex", mt: 4 }}>

                    {/* <Button
                        onClick={() => setOpenLanguage(true)}
                        type="submit"
                        variant="standard"
                        endIcon={<EditIcon />}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: '#F5F5F5'
                            },
                            marginLeft: "auto"
                        }}
                    >
                        <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                            Edit
                        </Typography>
                    </Button> */}
                    <Typography
                        component="span"
                        variant="body1"

                        onClick={() => setOpenLanguage(true)}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'inherit'
                            },
                            marginLeft: "auto",
                            mt: { xs: 0.5, sm: 0 },
                            fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", mt: 2
                        }}
                    >

                        Edit
                        <EditIcon sx={{ mr: 1, ml: 1, fontSize: '16px', }} />
                    </Typography>
                </Box>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    <Box>
                        <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                            Language
                        </Typography>
                        {formik.values.background?.languages ? (
                            (() => {
                                const parsedLanguages = JSON.parse(JSON.stringify(formik.values.background.languages));
                                const allLanguages = parsedLanguages.map(language => language.language || "N/A").join(", ");
                                const allLevels = parsedLanguages.map(language => language.language_level || "N/A").join(", ");
                                return (
                                    <Box mt={2}>
                                        <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                            <strong>Languages:</strong> {allLanguages}
                                        </Typography>
                                        <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                                            <strong>Levels:</strong> {allLevels}
                                        </Typography>
                                    </Box>
                                );
                            })()
                        ) : (
                            <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                                N/A
                            </Typography>
                        )}


                    </Box>
                </Grid>
                <Dialog open={openLanguage} onClose={() => setOpenLanguage(false)}>
                    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                            Edit Languages
                        </Typography>
                        <IconButton onClick={() => setOpenLanguage(false)}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider sx={{ width: '90%', color: 'text.secondary', mb: 1, mx: "auto" }} />
                    <DialogContent >

                        <Box component="form" onSubmit={formik.handleSubmit} mt={2}>
                            <Grid item xs={12}>
                                <Box mt={2}>
                                    <Grid container spacing={2}>
                                        {formik.values.background.languages.map((language, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                                            Language *
                                                        </InputLabel>
                                                        <TextField
                                                            placeholder="Select Language"
                                                            variant="outlined"
                                                            fullWidth
                                                            name={`background.languages[${index}].language`}
                                                            value={language.language}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            InputLabelProps={{
                                                                shrink: Boolean(language.language),
                                                                style: {
                                                                    fontSize: '14px',
                                                                    color: language.language ? 'inherit' : 'gray'
                                                                }
                                                            }}
                                                            sx={{ mb: 2 }}
                                                        />
                                                        {formik.touched.background?.languages?.[index]?.language && formik.errors.background?.languages?.[index]?.language && (
                                                            <Typography variant="body2" color="error">
                                                                {formik.errors.background.languages[index].language}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary", mb: 0.5 }}>
                                                            Level *
                                                        </InputLabel>
                                                        <TextField
                                                            placeholder="Select Level"
                                                            variant="outlined"
                                                            fullWidth
                                                            name={`background.languages[${index}].language_level`}
                                                            value={language.language_level}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            InputLabelProps={{
                                                                shrink: Boolean(language.language_level),
                                                                style: {
                                                                    fontSize: '14px',
                                                                    color: language.language_level ? 'inherit' : 'gray'
                                                                }
                                                            }}
                                                            sx={{ mb: 2 }}
                                                        />
                                                        {formik.touched.background?.languages?.[index]?.language_level && formik.errors.background?.languages?.[index]?.language_level && (
                                                            <Typography variant="body2" color="error">
                                                                {formik.errors.background.languages[index].language_level}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            onClick={() => handleRemoveLanguage(index)}
                                                            variant="standard"
                                                            sx={{
                                                                mb: 2,
                                                                backgroundColor: 'secondary.main',
                                                                color: 'text.secondary',
                                                                '&:hover': {
                                                                    backgroundColor: 'secondary.main'
                                                                }
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                        <Grid item xs={12}>
                                            <Button
                                                type="button"
                                                onClick={handleAddLanguage}
                                                variant="standard"
                                                sx={{
                                                    backgroundColor: 'white',
                                                    color: 'primary.main',
                                                    '&:hover': {
                                                        backgroundColor: 'white'
                                                    }
                                                }}
                                            >
                                                <AddIcon sx={{ mt: -0.5, ml: -3, color: 'primary.main' }} />
                                                <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px", backgroundColor: "white" }}>
                                                    Add More
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <DialogActions>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mb: 1 }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: 'primary.main'
                                            }
                                        }}
                                    >
                                        <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none' }}>
                                            Save
                                        </Typography>
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Box>

                    </DialogContent>


                </Dialog>

                {/* Additional Information Section */}
                <Box sx={{ display: "flex", mt: 4 }}>

                    {/* <Button
                        onClick={() => setOpenAdditionalInfo(true)}
                        type="submit"
                        variant="standard"
                        endIcon={<EditIcon />}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'inherit'
                            },
                            marginLeft: "auto"
                        }}
                    >
                        <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                            Edit
                        </Typography>
                    </Button> */}
                    <Typography
                        component="span"
                        variant="body1"

                        onClick={setOpenAdditionalInfo}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            color: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'inherit'
                            },
                            marginLeft: "auto",
                            mt: { xs: 0.5, sm: 0 },
                            fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px"
                        }}
                    >

                        Edit
                        <EditIcon sx={{ mr: 1, ml: 1, fontSize: '16px', }} />
                    </Typography>
                </Box>
                <Box sx={{ mb: 2 }}>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                        Additional Information
                    </Typography>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Objective
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
                        {formik.values.background?.objective || "N/A"}
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Achievements
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
                        {formik.values.background?.achievements || "N/A"}
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Personal Characteristics
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
                        {formik.values.background?.personal_characteristics || "N/A"}
                    </InputLabel>
                </Box>
                <Dialog width="md" open={openAdditionalInfo} onClose={() => setOpenAdditionalInfo(false)}>
                    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                            Edit Additional Information
                        </Typography>
                        <IconButton onClick={() => setOpenAdditionalInfo(false)}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider sx={{ width: '90%', color: 'text.secondary', mb: 1, mx: "auto" }} />
                    <DialogContent >

                        <Box component="form" onSubmit={formik.handleSubmit}>


                            <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                                Additional Information
                            </Typography>
                            <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                                Objective
                            </Typography>
                            <TextField
                                name="background.objective"
                                value={formik.values.background.objective}
                                onChange={formik.handleChange}
                                placeholder="Type here..."
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                sx={{ mb: 2 }}
                            />
                            <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                                Achievements
                            </Typography>
                            <TextField
                                name="background.achievements"
                                value={formik.values.background.achievements}
                                onChange={formik.handleChange}
                                placeholder="Type here..."
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                sx={{ mb: 2 }}
                            />
                            <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '14px', fontWeight: 'bold', textTransform: 'none', color: 'black', paddingTop: '5px', mb: 0.5 }}>
                                Personal Characteristics
                            </Typography>
                            <TextField
                                name="background.personal_characteristics"
                                value={formik.values.background.personal_characteristics}
                                onChange={formik.handleChange}
                                placeholder="Type here..."
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                sx={{ mb: 2 }}
                            />
                            <DialogActions>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 2, mb: 1 }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: 'primary.main',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: 'primary.main'
                                            }
                                        }}
                                    >
                                        <Typography component="span" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none' }}>
                                            Save
                                        </Typography>
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Box>

                    </DialogContent>

                </Dialog>
            </Box>



        </Box>

    );
};

export default MyProfile;
