import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';

const DynamicAutocomplete = ({ formik, fieldName, placeHolder, label,value,color }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    
    const fetchCities = async (pageNumber, query) => {
        // Simulate API call to fetch cities
        const allCities = [
            "Madrid", "Barcelona", "Valencia", "Sevilla", "Málaga", "Bilbao", "Zaragoza", "Murcia", "Las Palmas",
            "Palma", "Santa Cruz", "Alicante", "Córdoba", "Valladolid", "Vigo", "Gijón", "Vitoria-Gasteiz",
            "A Coruña", "Elche", "Granada", "Badalona", "Tarrasa", "Petrel", "Sabadell", "Cartagena", "Oviedo",
            "Jerez de la Frontera", "Móstoles", "Pamplona", "Almería", "Alcalá de Henares", "Leganés", "Donostia",
            "Fuenlabrada", "Getafe", "Castellón de la Plana", "Burgos", "Albacete", "Santander", "Alcorcón",
            "Guadalajara", "La Laguna", "Marbella", "Logroño", "Badajoz", "Salamanca", "Lleida", "Huelva",
            "Dos Hermanas", "Tarragona", "Parla", "Mataró", "Torrejón de Ardoz", "Algeciras", "León", "Alcobendas",
            "Jaén", "Cádiz", "Reus", "Roquetas de Mar", "Girona", "Ourense", "Telde", "San Vicente de Baracaldo",
            "Vaciamadrid", "Santiago de Compostela", "Las Rozas de Madrid", "San Cugat del Vallés", "San Fernando",
            "San Sebastián de los Reyes", "Torrevieja", "Torrente", "Ciudad de Melilla", "Toledo", "Fuengirola",
            "Arona", "San Baudilio de Llobregat", "Ciudad de Ceuta", "Valdemoro", "Rubí", "Manresa", "Gandía",
            "Benalmádena", "Avilés", "Paterna", "Majadahonda", "Benidorm", "Torremolinos", "Castelldefels",
            "Villanueva y Geltrú", "Viladecáns", "Collado-Villalba", "El Prat de Llobregat", "Arrecife",
            "La Línea de la Concepción", "Granollers", "Irún", "Boadilla del Monte", "San Vicente del Raspeig",
            "Mérida", "Elda", "Ibiza", "Mollet", "Torrelavega", "Rincón de la Victoria", "Figueras", "Gavá",
            "Vich", "Mairena del Aljarafe", "Esplugas de Llobregat", "Santurce-Antiguo", "San Felíu de Llobregat",
            "Mislata", "Portugalete", "Alcantarilla", "Blanes", "Igualada", "Villafranca del Panadés", "Burjasot",
            "Vendrell", "San Fernando de Henares", "Arucas", "Ripollet", "Olot", "San Adrián de Besós", "Moncada",
            "Cambrils", "Arroyomolinos", "Azuqueca de Henares", "San Juan Despí", "Aldaya", "Salt", "Manises",
            "Calafell", "Salou", "Alacuás", "Durango", "Catarroja", "Premiá de Mar", "Martorell", "Camas",
            "San Vicente dels Horts", "Sestao", "San Pedro del Pinatar", "Paiporta", "San Andrés de la Barca",
            "Molíns de Rey", "Santa Perpetua de Moguda", "Calpe", "Alboraya", "Tomares", "San Juan de Alicante",
            "Torrelodones", "Armilla", "Olesa de Montserrat", "Erandio", "Mejorada del Campo", "Vilaseca de Solcina",
            "Zarautz", "Bormujos", "San Felíu de Guixols", "San Juan de Aznalfarache", "Maracena", "Moncada",
            "Alfafar", "Manlleu", "San Juan de Vilasar", "Palafrugell", "Puzol", "Bañolas", "Sant Just Desvern",
            "Archena", "Calella", "Humanes de Madrid", "Barañáin", "Malgrat de Mar", "La Corredoría", "Cardedeu",
            "Utebo", "El Astillero", "Palamós", "Torredembarra", "Castilleja de la Cuesta", "Canovellas",
            "Guernica y Luno", "Albal", "Cubellas", "Montornés del Vallés", "Masamagrell", "Churriana de la Vega",
            "Arenys de Mar", "Aguadulce", "Benetúser", "Ermúa", "Cunit", "Mollerusa", "Canet de Mar", "Alpedrete",
            "Torelló", "Sopelana", "Ogíjares", "Cuarte de Huerva", "Santa Marta de Tormes", "Gines", "La Llagosta",
            "Godella", "Velilla de San Antonio", "Vilanova del Camí", "Ceuti", "Mongat", "Huétor Vega",
            "San Andrés de Llevaneras", "Picaña", "Pallejá", "Peligros", "Beniel", "Berriozar", "Meliana", "Sedaví",
            "Gelves", "Garrucha", "Masanasa", "Alella", "Torrejón de la Calzada", "Tabernes Blanques", "Tiana",
            "Montmeló", "Reinosa", "Colindres", "Santiponce", "Ortuella", "Santa Coloma de Cervelló",
            "Puebla de Farnals", "Ondárroa", "Cabrils", "Cúllar-Vega", "Foyos", "Rocafort", "Almácera", "Bigastro",
            "Canet de Berenguer", "Lekeitio", "Castellar y Oliveral", "Villanueva del Ariscal", "Teyá",
            "Roda del Ter", "Nazaret", "Navarcles", "Tanos", "Cájar", "Capellades", "Sarriá de Ter",
            "Bellreguart", "Rafal", "Formentera de Segura", "Hostalrich", "Albuixech", "Jun", "Sierrapando",
            "Bonrepós y Mirambell", "San Hipólito de Voltregá", "Vinalesa", "Alfara del Patriarca",
            "Caldas de Estrach", "San Andrés", "Miramar", "Castilleja de Guzmán", "Barreda", "Masalfasar",
            "Almoines", "Benisanó", "Molnás", "Varea", "El Saler", "Fuente de San Luis", "El Perelló",
            "Benirredrá", "Ganzo", "Benzú", "Carpesa", "El Puente del Arzobispo", "Igueldo", "Palmera",
            "Hortaleza", "Torres"
        ];
        return allCities.filter(city => city.toLowerCase().includes(query.toLowerCase()))
            .slice(0, pageNumber * 5);
    };
    // Fetch options dynamically using the fetchOptions function passed as a prop
    const fetchDynamicOptions = async (pageNumber, query) => {
        setLoading(true);
        const response = await fetchCities(pageNumber, query);
        setOptions(pageNumber === 1 ? response : [...options, ...response]);
        setLoading(false);
    };

    useEffect(() => {
        fetchDynamicOptions(page, searchQuery);
    }, [page, searchQuery]);

    // Function to handle scroll and load more options
    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        const bottom = listboxNode.scrollHeight === listboxNode.scrollTop + listboxNode.clientHeight;
        if (bottom && !loading) {
            setPage((prevPage) => prevPage + 1); // Load next page of options
        }
    };

    return (
        <>
            <Autocomplete
                options={options}
                freeSolo
                onInputChange={(event, newInputValue) => {
                    setSearchQuery(newInputValue);
                    setPage(1); // Reset page when searching
                }}
                onChange={(event, newValue) => {
                    formik.setFieldValue(fieldName, newValue); // Update the form field dynamically
                }}
                // Attach onScroll handler to the listboxProps
                ListboxProps={{
                    onScroll: handleScroll,
                    style: { maxHeight: 200, overflow: 'auto' }, // Ensure the dropdown is scrollable
                }}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label={label}
                    value={value}
                    variant="outlined"
                    fullWidth
                    placeholder={placeHolder}
                    sx={{
                    
                        "& input": {
                           
                            color: color, // Text color
                           
                        },
                        "& input::placeholder": {
                            color: color, // Placeholder color
                            opacity: 1, // Ensure the placeholder is fully opaque
                        },
                    }}
                />
                
                )}
                renderOption={(props, option) => (
                    <li {...props}>{option}</li>
                )}
                sx={{ mb: 2 }}
                getOptionLabel={(option) => option}
                noOptionsText={loading ? "Loading..." : "No options"}
            />
            {formik.touched[fieldName] && formik.errors[fieldName] && (
                <Typography sx={{ color: '#C70039', textAlign: 'left', fontSize: '12px' }}>
                    {formik.errors[fieldName]}
                </Typography>
            )}
        </>
    );
};

export default DynamicAutocomplete;
