import React from "react";
import { Box, Button } from "@mui/material";
import Preferences from "../../Employee/Onboarding/preferences/preferences";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from 'dayjs';

const Index = () => {

  const validationSchema = yup.object({
    preferences: yup.object({
      // professional_area: yup.string().required("Professional area is required"),
      total_years_experience: yup.string().required("Total Years Experience is required"),
      job_search_status: yup.string().required("Job search status is required"),

    }),
  });


  const formik = useFormik({
    initialValues: {
      preferences: {
        professional_area: "",
        total_years_experience: "",
        top_specialities: {
          PublicAdministration: false,
          BusinessAdministration: false,
          QualityProductionAndRD: false,
          CommercialAndSales: false,
          PurchasingLogisticsAndWarehouse: false,
          DesignAndGraphicArts: false,
          EducationAndTraining: false,
          FinanceAndBanking: false,
          ITAndTelecommunications: false,
          EngineersAndTechnicians: false,
          RealEstateAndConstruction: false,
          Legal: false,
          MarketingAndCommunication: false,
          ProfessionsArtsAndCrafts: false,
          Automotive: false,
          ConsumptionAndFood: false,
          SecurityAndSurveillance: false,
          HumanResources: false,
          HealthcareAndMedicine: false,
          TourismAndHospitality: false,
          Sales: false,
      },
       experienceYears : {
          PublicAdministration: {
              EuropeanAdministration: false,
              AutonomousAdministration: false,
              StateAdministration: false,
              LocalAdministration: false,
              SecurityForces: false, 
              PublicEnterprises: false,
          },
          BusinessAdministration: {
              Administration: false,
              Auditing: false,
              Consulting: false,
              Management: false,
              BillingCollectionsAndPayments: false,
              FinanceAndAccounting: false,
              NegotiationAndContracting: false,
              CorporateOperations: false,
              CompanyOrganization: false,
              Reception: false,
              Secretarial: false,
              CustomerService: false,
          },
          QualityProductionAndRD: {
              QualityCertification: false,
              QualityManagement: false,
              ProjectManagement: false,
              ResearchAndDevelopment: false,
              Maintenance: false,
              Environment: false,
              Metrology: false,
              Operations: false,
          },
          CommercialAndSales: {
              CommercialAgent: false,
              SalesRepresentative: false,
              ForeignTrade: false,
              KeyAccount: false,
              TelemarketingAndTelephoneSales: false,
          },
          PurchasingLogisticsAndWarehouse: {
              Warehouse: false,
              PurchasingAndProcurement: false,
              DistributionAndLogistics: false,
          },
          DesignAndGraphicArts: {
              AudiovisualCreation: false,
              GraphicDesign: false,
              IndustrialDesign: false,
              WebDesign: false,
              PublishingAndPrinting: false,
              FashionAndTailoring: false,
          },
          EducationAndTraining: {
              SpecialEducation: false,
              SocialEducation: false,
              Teaching: false,
              EducationalCenterManagement: false,
              Pedagogy: false,
              Childcare: false,
          },
          FinanceAndBanking: {
              CorporateBanking: false,
              PrivateBanking: false,
              StockMarketSecuritiesAndInvestment: false,
              BankingProductsAndServices: false,
              Insurance: false,
          },
          ITAndTelecommunications: {
              DatabaseAdministration: false,
              Analysis: false,
              Architecture: false,
              Quality: false,
              ERPCRMBusinessIntelligence: false,
              ProjectManagement: false,
              HardwareNetworksAndSecurity: false,
              Helpdesk: false,
              Programming: false,
              Systems: false,
              Telecommunications: false,
          },
          EngineersAndTechnicians: {
              Aerospace: false,
              AgronomyAndForestry: false,
              Electronics: false,
              IndustrialElectronicsAndAutomation: false,
              RenewableEnergies: false,
              GeologyGeodesyAndCartography: false,
              Industrial: false,
              Mining: false,
              NavalAndOceanEngineering: false,
              IndustrialOrganization: false,
              Chemistry: false,
              DefenseSystems: false,
          },
          RealEstateAndConstruction: {
              RealEstateAdministration: false,
              ArchitectureAndDesign: false,
              ProjectAndConstructionManagement: false,
              CivilEngineeringAndPublicWorks: false,
              MaterialsEngineering: false,
              InteriorDesign: false,
              ConstructionTrades: false,
          },
          Legal: {
              CivilCriminalAndConstitutionalLaw: false,
              InternationalLaw: false,
              ProceduralLaw: false,
              BusinessLaw: false,
              NotaryAndRegistries: false,
          },
          MarketingAndCommunication: {
              CorporateCommunication: false,
              BrandAndProductDevelopment: false,
              MarketResearch: false,
              Marketing: false,
              DigitalMarketing: false,
              JournalismAndEditing: false,
              Production: false,
              Advertising: false,
              PublicRelationsAndEvents: false,
              AudiovisualTechnicians: false,
          },
          ProfessionsArtsAndCrafts: {
              AgricultureAndGardening: false,
              PerformingArts: false,
              PlasticArts: false,
              Craftsmanship: false,
          },
          Automotive: {
              Carpentry: false,
              Tailoring: false,
          },
          ConsumptionAndFood: {
              Electricity: false,
              BeautyAndCosmetology: false,
              Plumbing: false,
              Photography: false,
              Cleaning: false,
          },
          SecurityAndSurveillance: {
              TranslationAndInterpretation: false,
              Transport: false,
              Others: false,
          },
          HumanResources: {
              Training: false,
              RiskPrevention: false,
              LaborRelations: false,
              PersonnelSelection: false,
          },
          HealthcareAndMedicine: {
              Nursing: false,
              Pharmacy: false,
              SpecializedMedicine: false,
              GeneralMedicine: false,
              AlternativeMedicine: false,
              Dentistry: false,
              Psychology: false,
              Veterinary: false,
              OtherHealthcareProfessions: false,
          },
          TourismAndHospitality: {
              Hospitality: false,
              Catering: false,
              Tourism: false,
          },
          Sales: {
              Salesperson: false,
              StoreManager: false,
              Cashier: false,
              InventoryManagement: false,
              VisualMerchandising: false,
          },
      },
      
        employment_type: {
          fullTime:false,
          partTime:false,
      },
        job_search_status: "",
        remote_work: "",
        relocation: "",
      },
      about_you: {
        resume_url: "",
        profile_photo_url: "",
        full_name: "",
        phone_number: "",
        whatsapp_number: "",
        current_city: "",
        salary_range: {
          from:  "",
          to:  "",
        },
        linkedin_url: "",
        personal_blog_url: ""
      },
      background: {
        work_experience: [
         {
          company_name: "",
        job_title: "",
        is_current: false,
        start: dayjs(), // Initial state for start date and time
        end: dayjs(), // Initial state for end date and time (current time)
        description: "",
          }
        ],
        
        education: [
          {
            university_or_collage: "",
            graduation_year: "",
            field_of_study: "",
            degree: "",
          }
        ],
        
        language: [
          {
            language: "",
            language_level: ""
          }
        ],
        objective: "",
        achievements: "",
        personal_characteristics: ""
      },
      is_onboarding: false,
      is_active: false,
    },
    validationSchema,
    onSubmit: (values) => {
     
    },
  });


  return (
  
      <Box>
        <Preferences formik={formik} />
      </Box>

  );
};

export default Index;