import React, { useState, useEffect } from "react"; 
import {
  Container,
  Typography,
  Divider,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { JobApi, JobPostApi } from "../../apis";
import UploadImage from "../../components/GlobalComponent/UploadImage";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CandidateProfileModal from "./candidateProfileModal";

const CandidateList = ({ jobData, close }) => {
  const [loading, setLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const fetchJobPosts = async () => {
    try {
      const res = await JobPostApi.getSuitableUsers(jobData.id);
      if (res && res.data && res.data.data) {
        const parsedJobPosts = res.data.data.map((job) => ({
          ...job,
          topSpecialities: parseJSON(job.profile.top_specialities),
          employmentType: parseJSON(job.profile.employment_type),
          salaryRange: parseJSON(job.profile.salary_range),
        }));
        setCandidates(parsedJobPosts);
      } else {
        console.error(
          "Error fetching job posts:",
          res ? res.message : "No response received"
        );
      }
    } catch (error) {
      console.error("Error fetching job posts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobPosts();
  }, []);

  const parseJSON = (jsonString) => {
    try {
      if (typeof jsonString === "object") {
        return jsonString;
      }
      return jsonString ? JSON.parse(jsonString) : {};
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };

  const handleSendRequest = async (candidate) => {
    const data = {
      job_post_id: jobData.id,
      salary_range_min: jobData.jobDetails.salaryRange.from,
      salary_range_max: jobData.jobDetails.salaryRange.to,
      employment_type:
        jobData.jobDetails.location === "remote" ? "Remote" : "OnSite",
      user_id: candidate.profile.user_id,
    };

    try {
      const response = await JobApi.sendOfferRequest(data);
      fetchJobPosts();
    } catch (error) {
      console.error("Error sending job offer request:", error);
      close();
    }
  };

  const getEmploymentType = (employmentType) => {
    if (employmentType.fullTime) return "Full Time";
    if (employmentType.partTime) return "Part Time";
    return "N/A";
  };

  const openProfileModal = (candidate) => {
    setSelectedCandidate(candidate);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedCandidate(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const filteredCandidates = () => {
    switch (tabIndex) {
      case 1: // Accepted
        return candidates.filter((candidate) => candidate.jobStatus === "Accepted");
      case 2: // Rejected
        return candidates.filter((candidate) => candidate.jobStatus === "Rejected");
      default: // All Candidates
      return candidates.filter((candidate) => !["Accepted", "Rejected"].includes(candidate.jobStatus));
    }
  };
  

  return (
    <Container
      maxWidth="lg"
      sx={{ position: "relative", zIndex: 0, backgroundColor: "white",mt:2 }}
    >
      {/* <div style={{ position: "relative", zIndex: 1 }}>
        <Typography
          ml={1}
          sx={{ fontWeight: "600", fontSize: "20px", marginBottom: "15px" }}
        >
          View Candidates
        </Typography>
        <Divider sx={{ width: "100%", color: "text.secondary", mb: 1 }} />
      </div> */}

{/* Tabs for filtering candidates */}
<Tabs 
  value={tabIndex} 
  onChange={handleTabChange} 
  sx={{ mb: 2 }}
  TabIndicatorProps={{
    style: {
      backgroundColor: '#8EFFC2'
    }
  }}
>
  <Tab 
    label={`Pending (${candidates.filter(candidate => !["Accepted", "Rejected"].includes(candidate.jobStatus)).length})`} 
    sx={{ 
      fontSize: { xs: "10px", sm: "12px", md: "14px" },
      color: tabIndex === 0 ? 'primary.main' : 'black', 
      width: '33.33%', 
      fontWeight: tabIndex === 0 ? 'bold' : 'normal' 
    }} 
  />
  <Tab 
    label={`Accepted (${candidates.filter(candidate => candidate.jobStatus === "Accepted").length})`} 
    sx={{ 
      fontSize: { xs: "10px", sm: "12px", md: "14px" },
      color: tabIndex === 1 ? 'primary.main' : 'black', 
      width: '33.33%', 
      fontWeight: tabIndex === 1 ? 'bold' : 'normal' 
    }} 
  />
  <Tab 
    label={`Rejected (${candidates.filter(candidate => candidate.jobStatus === "Rejected").length})`} 
    sx={{ 
      fontSize: { xs: "10px", sm: "12px", md: "14px" },
      color: tabIndex === 2 ? 'primary.main' : 'black', 
      width: '33.33%', 
      fontWeight: tabIndex === 2 ? 'bold' : 'normal' 
    }} 
  />
</Tabs>




      <div
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
          maxHeight: "470px",
          position: "relative",
          zIndex: 0,
          // Hide scrollbar
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none", // For Firefox
          msOverflowStyle: "none", // For Internet Explorer and Edge
        }}
      >
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {filteredCandidates().length > 0 ? (
            filteredCandidates().map((candidate) => (
              <Grid item xs={12} key={candidate.id}>
                <Card
                  sx={{
                    display: { sm: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 2,
                    backgroundColor: "white",
                    marginBottom: 2,
                    mx: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: { sm: "flex" },
                      alignItems: "center",
                      gap: 2,
                      backgroundColor: "white",
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "100%", sm: 80, md: 110 },
                        height: { xs: 200, sm: 80, md: 110 },
                        backgroundColor: "primary.main",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "lightgray",
                        m: 1,
                      }}
                    >
                      {candidate.profile &&
                      candidate.profile.profile_photo_url ? (
                        <UploadImage
                          imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${candidate.profile.profile_photo_url}`}
                          altText={candidate.profile.profile_photo_url}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ color: "text.secondary" }}
                        >
                          Upload
                        </Typography>
                      )}
                    </Box>
                    <CardContent sx={{ flex: { md: "1 0 auto" } }}>
                      <Typography
                        sx={{ fontSize: { sm: "16px", md: "22px" } ,ml:0.5}}
                        variant="h6"
                      >
                        {candidate.profile?.full_name || "N/A"}
                      </Typography>
                      <Typography
                        ml={1}
                        sx={{
                          color: "gray",
                          fontWeight: "600",
                          fontSize: "22px",
                          marginLeft: "-4px",
                        }}
                      >
                        <Box component="span">
                          {candidate?.profile?.top_specialities ? (
                            (() => {
                              const specialties = candidate.profile.top_specialities;
                              if (typeof specialties === "object" && specialties !== null) {
                                const activeSpecialties = Object.keys(specialties).filter(
                                  (specialty) => specialties[specialty]
                                );
                                return activeSpecialties.length > 0 ? (
                                  activeSpecialties.map((specialty) => (
                                    <Typography
                                      key={specialty}
                                      sx={{ fontSize: "16px", ml: 1, color: "gray" }}
                                      variant="body2"
                                    >
                                      {specialty.replace(/([A-Z])/g, " $1").trim()}
                                    </Typography>
                                  ))
                                ) : (
                                  <Typography component="span" variant="body1" sx={{ml:1}}>
                                    N/A
                                  </Typography>
                                );
                              } else {
                                return (
                                  <Typography component="span" variant="body1" sx={{ml:1}}>
                                    N/A
                                  </Typography>
                                );
                              }
                            })()
                          ) : (
                            <Typography component="span" variant="body1" sx={{ml:1}}>
                              N/A
                            </Typography>
                          )}
                        </Box>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: { sm: "12px", md: "15px" },
                        }}
                      >
                         <LocationOnIcon sx={{ mt: { xs: -1.5, sm: 0 } }} />{" "}
                         {candidate.profile?.current_city || "N/A"} •{" "}
                        {getEmploymentType(candidate.employmentType)} • €
                        {candidate.salaryRange?.from || "N/A"}-
                        {candidate.salaryRange?.to || "N/A"}
                      </Typography>
                    </CardContent>
                  </Box>
                  <CardActions sx={{ flexDirection: "column" }}>
                    <Typography>{candidate.jobStatus}</Typography>
                    {!candidate.jobStatus && (
                      
                      <Button
                      type="button"
                      variant="standard"
                      fullWidth
                      onClick={() => handleSendRequest(candidate)}
                      sx={{
                        mt: 2,
                        mb: 0,
                        width: { sm: "130px", md: "150px" },
                        backgroundColor: "white",
                        color: "primary.main",
                        border: "2px solid #031141",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="body1"
                        sx={{
                          fontSize: { sm: "12px", md: "15px" },
                          fontWeight: "bold",
                          textTransform: "none",
                          color: "primary.main",
                          backgroundColor: "white",
                          padding: "2px",
                        }}
                      >
                        Send Request
                      </Typography>
                    </Button>

                    )}
                    <Button
                       sx={{
                        mt:1.5,
                        backgroundColor: 'secondary.main',
                        color: 'text.primary',
                        border: '2px solid #8EFFC2',
                        '&:hover': {
                          backgroundColor: 'secondary.main',
                        }
                      }}
                      onClick={() => openProfileModal(candidate)}
                    >
                   <Typography component="h2" variant="body1" sx={{ color: 'primary.main', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', padding: "4px",px:2.7 }}>
                View Profile
              </Typography>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                No candidates found
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>

      {selectedCandidate && (
        <CandidateProfileModal
          open={modalOpen}
          onClose={closeModal}
          candidate={selectedCandidate}
        />
      )}
    </Container>
  );
};

export default CandidateList;
