import axios from "../axiosConfig";

class Routes {

    applyOnJobPost  = (val) => {
        let data = axios.post("/job-offer/apply-on-job-post", val)
        return data;
    };
    sendOfferRequest  = (val) => {
        let data = axios.post("/job-offer/send-offer-request", val)
        return data;
    };
    updateOfferStatus  = (val) => {
        let data = axios.post("/job-offer/update-offer-status", val)
        return data;
    };
    getAllUsers = (id) => {
        let data = axios.get(`/job-offer/get-all-users/${id}`);
        return data;
    };
    getAllJobPostUsers = () => {
        let data = axios.get("/job-offer/get-all-users");
        return data;
    };

}

export default new Routes();