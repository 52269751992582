import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Grid,
    InputLabel,
    Divider
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { OnBoardingApi } from "../../../../apis";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import UploadImage from "../../../../components/GlobalComponent/UploadImage";
import AddIcon from '@mui/icons-material/Add';
import Loader from "../../../../components/GlobalComponent/Loader";


const PreviewAndSubmit = () => {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate(); // Initialize navigate
    const location = useLocation();
    const [formikValues, setFormikValues] = useState(location.state?.formikValues || {});
    const [workExperience, setWorkExperience] = useState(null);
    const [education, setEducation] = useState(null);
    const [languages, setLanguages] = useState(null);
    useEffect(() => {
        if (!formikValues || Object.keys(formikValues).length === 0) {
            console.error("No form data available");
        }
    }, [formikValues]);

    const submitForm = async (values) => {
        try {
            // Convert top_specialities to JSON string

            const updatedValues = {
                ...values,

                preferences: {
                    ...values.preferences,
                    employment_type: values.preferences?.employment_type ? JSON.stringify(values.preferences.employment_type) : {},

                },
                about_you: {
                    ...values.about_you,
                    salary_range: values.about_you?.salary_range ? JSON.stringify(values.about_you.salary_range) : {},
                    profile_photo_url: values.about_you.profile_photo_url // Include profile_photo_url

                }
            };

            const res = await OnBoardingApi.OnBoarding(updatedValues);
            localStorage.setItem("onboarding", true);
            localStorage.setItem("is_onboarding", true);
            window.location.href = "/v1/employee/home";
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        const fetchOnBoardedUserData = async () => {
            try {

                const res = await OnBoardingApi.getOnBoarding(); // Adjust API call as needed

                setUserData(res?.data?.profile);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching on-boarded user data:", error);
                setLoading(false);
            }
        };
        fetchOnBoardedUserData();
    }, []);

    useEffect(() => {
        function multiParse(input) {
            let result = input;
            try {
                while (typeof result === 'string') {
                    result = JSON.parse(result);
                }
                return Array.isArray(result) || (typeof result === 'object' && result !== null) ? result : null;
            } catch (e) {
                console.error('Error parsing input:', e);
                return null;
            }
        }

        if (userData) {
            setWorkExperience(multiParse(userData.work_experience));
            setEducation(multiParse(userData.education));
            setLanguages(multiParse(userData.languages));
        }
    }, [userData]);

    const formatWorkExperience = (experience) => {
        if (!experience) return { companies: "N/A", titles: "N/A", startDates: "N/A", endDates: "N/A", descriptions: "N/A" };
        return {
            companies: experience.map(exp => exp.company_name || "N/A").join(", "),
            titles: experience.map(exp => exp.job_title || "N/A").join(", "),
            startDates: experience.map(exp => exp.start || "N/A").join(", "),
            endDates: experience.map(exp => exp.end || "N/A").join(", "),
            descriptions: experience.map(exp => exp.description || "N/A").join(", ")
        };
    };

    const formatEducation = (edu) => {
        if (!edu) return { universities: "N/A", graduationYears: "N/A", fieldsOfStudy: "N/A", degrees: "N/A" };
        return {
            universities: edu.map(e => e.university_or_collage || "N/A").join(", "),
            graduationYears: edu.map(e => e.graduation_year || "N/A").join(", "),
            fieldsOfStudy: edu.map(e => e.field_of_study || "N/A").join(", "),
            degrees: edu.map(e => e.degree || "N/A").join(", ")
        };
    };

    const formatLanguages = (lang) => {
        if (!lang) return { languages: "N/A", levels: "N/A" };
        return {
            languages: lang.map(l => l.language || "N/A").join(", "),
            levels: lang.map(l => l.language_level || "N/A").join(", ")
        };
    };

    const workExp = formatWorkExperience(workExperience);
    const edu = formatEducation(education);
    const lang = formatLanguages(languages);

  
    if (loading) {
        return <div>
            <Loader loading={loading} />
        </div>;
    }

    function multiParse(input) {
        let result = input;
        try {
            while (typeof result === 'string') {
                result = JSON.parse(result);
            }
        } catch (e) {
            console.error('Error parsing input:', e);
        }
        return result;
    }

    return (
        <Box>
            <Box
                sx={{
                    background: "#FFFFFF",
                    borderRadius: "14px",
                    paddingLeft: "150px",
                    paddingRight: "150px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography ml={1} sx={{ fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                        Profile Preview
                    </Typography>
                </Box>
                <Typography ml={1} sx={{ fontWeight: "600", fontSize: "16px", marginLeft: '-4px', marginBottom: '15px' }}>
                    Account
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start', // Align to the left
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box
                                sx={{
                                    width: "100px",
                                    height: "100px",
                                    border: "1px solid black",
                                    borderRadius: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "15px",
                                }}
                            >
                                {userData?.profile_photo_url ? (

                                    <UploadImage
                                        imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${userData?.profile_photo_url}`}
                                        altText={userData?.profile_photo_url}
                                        style={{ maxWidth: "100%", maxHeight: "100%", objectFit: 'cover', borderRadius: '5px' }}
                                    />
                                ) : (
                                    <AddIcon sx={{ fontSize: 30, color: "black" }} />
                                )}
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ fontWeight: "600", fontSize: "16px" }}> {userData?.full_name || "N/A"}</Typography>
                            <Typography variant="body2" sx={{ mr: 0, textDecoration: 'none', color: 'gray', fontSize: '11px' }}>
                                {userData?.current_city || "N/A"}
                            </Typography>

                            <Typography variant="body2" sx={{ mr: 0, textDecoration: 'none', color: 'gray', fontSize: '11px' }}>
                                +{userData?.phone_number || "N/A"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                        Qualification
                    </Typography>
                    {/* <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Professional Area
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
                        {userData?.professional_area || "N/A"}
                    </InputLabel> */}

                    <Typography variant="body1" paragraph>
                        <strong>Professional Area</strong>
                        <Box component="span">
                            {(() => {
                                const topSpecialties = userData?.top_specialities; // Get top_specialities from userData

                                // Only parse if top_specialities is not null or undefined
                                const parsedSpecialties = topSpecialties ? multiParse(topSpecialties) : {};

                                // Check if there are any specialties with truthy values
                                const specialtiesArray = Object.entries(parsedSpecialties).filter(([_, value]) => value);
                                const hasValidSpecialties = specialtiesArray.length > 0;

                                // Return specialties or "N/A" based on availability
                                return hasValidSpecialties ? (
                                    specialtiesArray.map(([specialty]) => (
                                        <Typography key={specialty} component="span" variant="body1" sx={{ display: 'inline', mr: 1 }}>
                                            <br /> {specialty.replace(/([A-Z])/g, ' $1').trim()}
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography component="span" variant="body1">
                                        N/A
                                    </Typography>
                                );
                            })()}
                        </Box>
                    </Typography>

                    <Typography variant="body1" paragraph>
                        <strong>Specialties</strong>
                        <Box component="span">
                            {userData?.experienceYears ? (
                                Object.entries(multiParse(userData.experienceYears)).filter(([specialty]) => {
                                    // Only include specialties that are marked as true in top_specialities
                                    const parsedSpecialties = userData?.top_specialities ? multiParse(userData.top_specialities) : {};
                                    return parsedSpecialties[specialty] === true;
                                }).flatMap(([specialty, yearsOrSubcategories]) => {
                                    // Check if yearsOrSubcategories is an object
                                    if (typeof yearsOrSubcategories === 'object' && yearsOrSubcategories !== null) {
                                        return Object.entries(yearsOrSubcategories).flatMap(([subSpecialty, years]) => {
                                            // If years is an object, format it for rendering
                                            if (typeof years === 'object' && years !== null) {
                                                return Object.entries(years)
                                                    .filter(([yearKey, yearValue]) => yearValue) // Only show entries with truthy values
                                                    .map(([yearKey, yearValue]) => (
                                                        <Typography key={`${subSpecialty}-${yearKey}`} variant="body2" paragraph sx={{ display: 'inline', mr: 1 }}>
                                                            <br /> <strong>{`${subSpecialty.replace(/([A-Z])/g, ' $1').trim()} (${yearKey}):`}</strong> {yearValue}
                                                        </Typography>
                                                    ));
                                            }
                                            // If years is a string or number and truthy, render it
                                            if (years) {
                                                return (
                                                    <Typography key={subSpecialty} variant="body2" paragraph sx={{ display: 'inline', mr: 1 }}>
                                                        <br /> {subSpecialty.replace(/([A-Z])/g, ' $1').trim()}
                                                    </Typography>
                                                );
                                            }
                                            return []; // Return an empty array if the value is not truthy
                                        });
                                    } else if (yearsOrSubcategories) {
                                        // If it's a direct value and truthy, render it
                                        return (
                                            <Typography key={specialty} variant="body2" paragraph sx={{ display: 'inline', mr: 1 }}>
                                                <br /> <strong>{specialty.replace(/([A-Z])/g, ' $1').trim()}:</strong> {yearsOrSubcategories}
                                            </Typography>
                                        );
                                    }
                                    return []; // Return an empty array if the value is not truthy
                                })
                            ) : (
                                "N/A"
                            )}
                        </Box>
                    </Typography>

                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Total Years of Experience
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
                        {userData?.total_years_experience || "N/A"}
                    </InputLabel>






                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                        Job Preferences
                    </Typography>

                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.1 }}>
                        Type of Employment
                    </InputLabel>
                    <Box mt={2}>
                        {userData?.employment_type ? (
                            <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", mt: -1, ml: -0.1 }}>
                                {multiParse(userData.employment_type)["fullTime"] ? "Full Time" : ""}
                                {multiParse(userData.employment_type)["fullTime"] && JSON.parse(userData.employment_type)["partTime"] ? ", " : ""}
                                {multiParse(userData.employment_type)["partTime"] ? "Part Time" : ""}
                            </Typography>
                        ) : (
                            <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                                N/A
                            </Typography>
                        )}
                    </Box>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.1 }}>
                        Job Search Status
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary", ml: -0.1 }}>
                        {userData?.job_search_status || "N/A"}
                    </InputLabel>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px', ml: -1 }}>
                        Location
                    </Typography>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.5 }}>
                        Remote Work
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        {userData?.remote_work ? "Yes" : "No"}
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.5 }}>
                        Relocation
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        {userData?.relocation ? "Yes" : "No"}
                    </InputLabel>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                        Salary Expectation
                    </Typography>
                    <Box mt={2}>
                        <InputLabel sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary", ml: -0.5 }}>
                            Your Preferred Net Salary
                        </InputLabel>
                        {userData?.salary_range ? (
                            <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.3 }}>
                                <strong> From:</strong> {multiParse(userData.salary_range).from}  <strong> To:</strong> {multiParse(userData.salary_range).to}
                            </Typography>

                        ) : (
                            <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
                                N/A
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Divider sx={{ my: 2 }} />
                <Box>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                        Links
                    </Typography>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        {userData?.linkedin_url || "N/A"}
                    </Typography>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        {userData?.personal_blog_url || "N/A"}
                    </Typography>
                    {/* Add Link Details */}
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                    Work Experience
                </Typography>
                <Box mt={2}>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        <strong>Company Name:</strong> {workExp.companies}
                    </Typography>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        <strong>Job Title:</strong> {workExp.titles}
                    </Typography>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        <strong>Start Date:</strong> {workExp.startDates}
                    </Typography>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        <strong>End Date:</strong> {workExp.endDates}
                    </Typography>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        <strong>Description:</strong> {workExp.descriptions}
                    </Typography>
                </Box>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box>
                <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                    Education
                </Typography>
                <Box mt={2}>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        <strong>University or College:</strong> {edu.universities}
                    </Typography>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        <strong>Graduation Year:</strong> {edu.graduationYears}
                    </Typography>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        <strong>Field of Study:</strong> {edu.fieldsOfStudy}
                    </Typography>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        <strong>Degree:</strong> {edu.degrees}
                    </Typography>
                </Box>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box>
                <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                    Language
                </Typography>
                <Box mt={2}>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        <strong>Languages:</strong> {lang.languages}
                    </Typography>
                    <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}>
                        <strong>Levels:</strong> {lang.levels}
                    </Typography>
                </Box>
            </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    <Typography ml={1} sx={{ mt: 2, fontWeight: "600", fontSize: "22px", marginLeft: '-4px', marginBottom: '15px' }}>
                        Additional Information
                    </Typography>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Objective
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
                        {userData?.objective || "N/A"}
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Achievements
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
                        {userData?.achievements || "N/A"}
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
                        Personal Characteristics
                    </InputLabel>
                    <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
                        {userData?.personal_characteristics || "N/A"}
                    </InputLabel>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 3,
                    }}
                >
                    <Button
                        type="submit"
                        onClick={() => navigate("/v1/employee/background")}
                        variant="outlined"
                        sx={{
                            mt: 3, mb: 2, backgroundColor: 'white', borderColor: 'primary.main', color: 'text.secondary', '&:hover': {
                                backgroundColor: 'text.secondary'
                            }
                        }}
                    >
                        <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                            Back
                        </Typography>
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={async () => {
                            formikValues.is_onboarding = true;

                            setIsLoading(true);
                            await submitForm(formikValues);

                        }}
                        disabled={isLoading}
                        sx={{
                            mt: 3, mb: 2, backgroundColor: 'secondary.main', color: 'white', '&:hover': {
                                backgroundColor: 'secondary.main'
                            }
                        }}
                    >
                        <Typography component="h2" variant="body1" sx={{ alignSelf: 'flex-start', fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                            Submit
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default PreviewAndSubmit;