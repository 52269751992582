import axios from "../axiosConfig";

class Routes {

    OnBoarding = (val) => {
        let data = axios.post("/on-board-user/on-boarding", val)
        return data;
    };
    getOnBoarding = () => {
        let data = axios.get("/on-board-user/get")
        return data;
    };
    uploadImage = (val) => {
        let data = axios.post("/on-board-user/upload-image", val)
        return data;
    };
  
}



export default new Routes();