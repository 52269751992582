import React, { useState, useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Box,
  IconButton,
  Grid,
  TextField,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { JobPostApi, OnBoardingApi } from "../../apis";
import UploadImage from "../GlobalComponent/UploadImage";
import CandidateList from "./candidateList";
import CurrencyFormatter from "../GlobalComponent/CurrencyFormater";

export default function JobCard() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCandidates, setShowCandidates] = useState(false);
  const [jobDataArray, setJobDataArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedJobData, setSelectedJobData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchJobPosts = async () => {
      try {
        const res = await JobPostApi.getJobPost();
        if (res && res.data && res.data.jobPosts) {
          const parsedJobPosts = res.data.jobPosts.map((job) => ({
            ...job,
            jobDetails: parseJSON(job.jobDetails),
            requirements: parseJSON(job.requirements),
            description: parseJSON(job.description),
            company_profile_photo_url: job.Profile.company_profile_photo_url,
          }));
          setJobDataArray(parsedJobPosts);
        } else {
          console.error(
            "Error fetching job posts:",
            res ? res.message : "No response received"
          );
        }
      } catch (error) {
        console.error("Error fetching job posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobPosts();
  }, []);
  const filteredJobPosts = jobDataArray.filter((job) =>
    job.jobDetails.positionName
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  // Helper function to safely parse JSON
  const parseJSON = (jsonString) => {
    try {
      // Check if jsonString is already an object
      if (typeof jsonString === "object") {
        return jsonString; // Return as-is if already an object
      }
      return jsonString ? JSON.parse(jsonString) : {}; // Parse JSON if jsonString is not null or undefined
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {}; // Return empty object if parsing fails
    }
  };

  const handleClick = (event, id) => {
    setSelectedJobId(id); // Set selected job ID
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewCandidates = (event, data) => {
    setSelectedJobData(data);
    setShowCandidates(true);
    setAnchorEl(null);
  };

  const handleDeleteJobPost = async () => {
    try {
      await JobPostApi.deleteJobPost(selectedJobId);
      // Handle success, like updating state or UI
    } catch (error) {
      console.error("Error deleting job post:", error); // Log the Axios error object
      // Handle error: you can check error.response for server response details
    }
  };

  const handleMenuItemClick = async (id, action) => {
    setSelectedJobId(id);
    setAnchorEl(null); // Close the menu

    if (action === "delete") {
      try {
        await handleDeleteJobPost();
        // Wait for 1 seconds
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // Reload the window
        window.location.reload();
      } catch (error) {
        console.error("Error deleting job post:", error);
        // Handle error if needed
      }
    }
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: "22px",
          marginBottom: "10px",
          mt: 4,
        }}
      >
        My Job Posts
      </Typography>
      <Typography sx={{ fontSize: "16px", marginBottom: "20px" }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <Box
            sx={{
              width: { xs: "100%", md: "400px" },
              height: "30px",
              borderRadius: "3px",
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              backgroundColor: "white",
              padding: "8px",
            }}
          >
            <SearchIcon sx={{ color: "gray" }} />
            <TextField
              variant="standard"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                flex: 1,
                borderBottom: "none",
                mr: 0,
                textDecoration: "none",
                color: "gray",
                fontSize: "14px",
                "& input::placeholder": { color: "gray" },
                "& input": {
                  fontSize: "14px",
                  color: "gray",
                  textDecoration: "none",
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {loading ? (
        <Typography>Loading job posts...</Typography>
      ) : filteredJobPosts.length === 0 ? (
        <Typography>No job posts found.</Typography>
      ) : (
        filteredJobPosts.map((data, index) => (
          <Card
            sx={{
              display: { sm: "flex" },
              justifyContent: "space-between", 
              alignItems: "center",
              padding: 2,
              marginBottom: 2,
              mx: "auto",
              py:3
            }}
          >
            <Box sx={{ display: { sm: "flex" }, alignItems: "center", gap: 2 }}>
              <Box
                sx={{ position: "relative", width: "100px", height: "100px" }}
              >
                {/* Job Profile Picture */}
                <Box
  sx={{
    width: 100,
    height: 100,
    backgroundColor: data?.company_profile_photo_url ? "transparent" : "lightgray", // Gray background when no image
    borderRadius: "8px",
    display: "flex", 
    alignItems: "center",
    justifyContent: "center",
    color: data?.company_profile_photo_url ? "transparent" : "white", // White text when no image
  }}
>
  {data?.company_profile_photo_url ? (
    <Box
      component="img"
      src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${data.company_profile_photo_url}`}
      alt="Company Profile Photo"
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        objectFit: "cover",
      }}
    />
  ) : (
    <Typography variant="body1" sx={{ color: "text.secondary" }}>
      No Image
    </Typography>
  )}
</Box>


                {/* Company Profile Photo (Overlayed as Logo) */}
                {/* <Box
                  component="img"
                  src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${data.company_profile_photo_url}`}
                  alt="Company Profile Photo"
                  sx={{
                    position: "absolute",
                    top: 5, // Adjust to position correctly
                    right: 5, // Adjust to position correctly
                    width: 30, // Size of the company logo
                    height: 30,
                    borderRadius: "50%", // Circular logo
                    border: "2px solid white", // Optional border to make it stand out
                    objectFit: "cover", // Ensures logo fits nicely within the circle
                  }}
                /> */}
              </Box>

              <CardContent
                sx={{ flex: "1 0 auto", width: { sm: "200px", md: "100%" } }}
              >
                  <Typography
          sx={{ fontSize: { sm: "18px", md: "22px", } }}
          variant="h6"
        >
                 {data.jobDetails?.positionName || "N/A"}
  <Box component="span">
    {data.jobDetails?.specialties &&
    Object.keys(data.jobDetails.specialties).length > 0 ? (
      Object.keys(data.jobDetails.specialties)
        .filter((specialty) => data.jobDetails.specialties[specialty])
        .map((specialty, index) => (
          <Typography
          variant="body2"
        
          sx={{ display: "flex", alignItems: "center",mb:0.5,color:"gray", }}
        >
        {specialty.replace(/([A-Z])/g, ' $1').trim()}   
          </Typography>
        ))
    ) : (
      <Typography component="span" variant="body1">
        N/A
      </Typography>
    )}
  </Box>
</Typography>
                {/* <Typography
                  sx={{ fontSize: { sm: "18px", md: "22px" } }}
                  variant="h6"
                >
                  {data.jobDetails?.positionName || "N/A"}
                </Typography> */}
                <Typography
                  variant="body2"
                  color="text.primary"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {/* <LocationOnIcon sx={{mt:{xs:-1.5,sm:0 }}} /> */}
                  {data.jobDetails?.location || "N/A"} • Full time •{" "}
                  <CurrencyFormatter
                    value={data.jobDetails?.salaryRange?.from || "N/A"}
                  ></CurrencyFormatter>
                  -
                  <CurrencyFormatter
                    value={data.jobDetails?.salaryRange?.to || "N/A"}
                  ></CurrencyFormatter>
                </Typography>
                {/* <Typography variant="body2" color="text.primary">
                  {data.description?.description || 'N/A'}
                </Typography> */}
              </CardContent>
            </Box>
            <CardActions
              sx={{ justifyContent: "flex-end", flexDirection: "column" }}
            >
              <IconButton
                disableRipple
                aria-label="settings"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(event) => handleClick(event, data.id)} // Pass job ID to handleClick
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  component={Link}
                  to={`/v1/recruiter/job-details/${selectedJobId}`} // Use selectedJobId here
                  onClick={() => handleMenuItemClick(selectedJobId)}
                >
                  View job details
                </MenuItem>
                <MenuItem
                  onClick={() => handleMenuItemClick(selectedJobId, "delete")}
                >
                  Delete
                </MenuItem>
              </Menu>
              <Button
                type="submit"
                variant="standard"
                fullWidth
                onClick={(event) => handleViewCandidates(event, data)}
                sx={{
                  mt: 1,
                  mb: 0,

                  width: { sm: "150px", md: "200px" },
                  backgroundColor: "white",
                  color: "primary.main",
                  border: "2px solid #031141",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                <Typography
                  component="h2"
                  variant="body1"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: { sm: "12px", md: "15px" },
                    fontWeight: "bold",
                    textTransform: "none",
                    padding: "4px",
                  }}
                >
                  View Candidates
                </Typography>
              </Button>
            </CardActions>
          </Card>
        ))
      )}

      <Dialog
        open={showCandidates}
        onClose={() => setShowCandidates(false)}
        fullWidth
        maxWidth="md"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 4,
            border: "1px solid #e0e0e0",
            backgroundColor: "white",
          },
        }}
      >
        <DialogTitle>
         
          <IconButton
            sx={{ position: "absolute", top: 6, right: 6, color: "black" }}
            onClick={() => setShowCandidates(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CandidateList
            jobData={selectedJobData}
            close={() => setShowCandidates(false)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCandidates(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
