import React from 'react';
import { Container, Typography, Button, Box, Grid, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#0a074f', // Dark blue background
    color: '#fff',
    padding: theme.spacing(8, 0),
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  heading: {
    fontSize: '3rem',
    color: '#fff',
  },
  subheading: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#8EFFC2',
  },
  accordion: {
    backgroundColor: '#0a074f !important',
    border: '1px solid #8EFFC2',
    borderRadius: '30px !important',
    padding: '10px !important',
    marginBottom: theme.spacing(2),
  },
  title: {
    backgroundColor: '#0a074f',
    color: 'white',
  },
  description: {
    backgroundColor: '#0a074f',
    color: 'white',
  },
  buttonContainer: {
    backgroundColor: '#0a074f',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#00cc6a',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00b358',
    },
    borderRadius: '20px',
    padding: theme.spacing(1, 4),
  },
  registerButton: {
    border: '1px solid #00cc6a',
    color: '#00cc6a',
    borderRadius: '20px',
    padding: theme.spacing(1, 4),
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: '#00cc6a',
      color: '#fff',
    },
  },
}));

const sections = [
  {
    title: 'CREA OFERTAS Y ENCUENTRA CANDIDATOS',
    description: 'Publica ofertas, recibe una lista de candidatos compatibles y envía tu propuesta directamente',
  },
  {
    title: 'FILTRA SOLICITUDES EFICAZMENTE',
    description: 'Evita masivas aplicaciones y céntrate en los candidatos que encajan con tus necesidades',
  },
];

const section2 = [
  {
    titlee: 'SELECCIÓN RÁPIDA Y SEGURA',
    descriptionn: 'El método más directo, seguro y eficiente para seleccionar a los mejores candidatos',
  },
];

const MatchSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/register'); // Change the path to your registration page
  };

  return (
    <Box className={classes.root}>
      <Container>
        <Box className={classes.header}>
          <Typography className={classes.heading} sx={{ fontSize: isSmallScreen ? '2rem' : '3.5rem' }}>
            Haz match con el
          </Typography>
          <Typography className={classes.subheading} sx={{ fontSize: isSmallScreen ? '2rem' : '3.5rem' }}>
            <strong>candidato ideal</strong>
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {sections.map((section, index) => (
            <Grid item xs={12} key={index}>
              <Box className={classes.accordion} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box sx={{ p: 1 }}>
                  <Typography variant="h6" className={classes.title}>
                    {section.title}
                  </Typography>
                  <Typography className={classes.description}>
                    {section.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2}>
          {section2.map((section, index) => (
            <Grid item xs={12} key={index}>
              <Box className={classes.accordion} sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: isSmallScreen ? 'center' : 'flex-start',mt:2 }}>
                <Box sx={{ p: 1, textAlign: isSmallScreen ? 'left' : 'left' }}>
                  <Typography variant="h6" className={classes.title}>
                    {section.titlee}
                  </Typography>
                  <Typography className={classes.description}>
                    {section.descriptionn}
                  </Typography>
                </Box>
                <Box sx={{ mt: isSmallScreen ? 2 : 3, ml: isSmallScreen ? "auto" : 'auto', mr: isSmallScreen ? 0 : 2 }}>
                  <Button
                  onClick={handleRegisterClick}
                    variant="outlined"
                    color="primary"
                    sx={{
                      color: '#8EFFC2',
                      borderColor: '#8EFFC2',
                      p: 1,
                      mt:-1,
                      borderRadius: '30px',
                      '&:hover': {
                        borderColor: '#8EFFC2',
                      },
                    }}
                  >
                    ¡Registrarme!
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default MatchSection;
