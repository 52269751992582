import React, { useState, useEffect } from 'react';
import {
  Box, CssBaseline, Drawer, List, ListItemIcon, ListItemText, Toolbar, Typography,
  ListItemButton, Button, IconButton, useMediaQuery, useTheme,ListItem
} from '@mui/material';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from '@mui/icons-material/Menu';
import Logo from "../../assets/images/logo.png";
import { Link } from 'react-router-dom';

const drawerWidth = 280;

const EmployeeSidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const [visitedMenuItems, setVisitedMenuItems] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const pathname = location.pathname;
    const currentMenuItem = getSelectedMenuItem(pathname);
    setSelectedMenuItem(currentMenuItem);
    if (currentMenuItem && !visitedMenuItems.includes(currentMenuItem)) {
      setVisitedMenuItems([...visitedMenuItems, currentMenuItem]);
    }
  }, [location.pathname]);

  const getSelectedMenuItem = (pathname) => {
    switch (pathname) {
      case '/v1/employee/preferences':
        return 'Preferences';
      case '/v1/employee/about-you':
        return 'About you';
      case '/v1/employee/background':
        return 'Background';
      case '/v1/employee/preview-and-submit':
        return 'Preview and Submit';
      default:
        return '';
    }
  };
  const renderMenuItem = (menuItem) => (
    <ListItem
      button
      key={menuItem}
      selected={selectedMenuItem === menuItem}
      onClick={() => handleMenuItemClick(menuItem)}
      sx={{
        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
        },
      }}
    >
      <ListItemIcon>
        <CheckCircleOutlineIcon
          sx={{
            color: selectedMenuItem === menuItem 
              ?'gray' // If the current step is selected
              : visitedMenuItems.includes(menuItem)
              ? theme.palette.secondary.main // Change to green when step is completed
              : 'gray', // Default gray color when unvisited
          }}
        />
      </ListItemIcon>
      <ListItemText
        primary={menuItem}
        sx={{
          color: selectedMenuItem === menuItem 
            ? 'gray' // Active step
            : visitedMenuItems.includes(menuItem)
            ? theme.palette.secondary.main // Completed step
            : 'gray', // Default for unvisited items
        }}
      />
    </ListItem>
  );
  
  const handleMenuItemClick = (menuItem) => {
    const path = `/v1/employee/${menuItem.toLowerCase().replace(/\s/g, '-')}`;
    navigate(path);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleBackButtonClick = () => {
    // Remove the last visited menu item when going back
    const updatedVisitedMenuItems = visitedMenuItems.slice(0, -1);
    setVisitedMenuItems(updatedVisitedMenuItems);
    navigate("/v1/employee/preferences");
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {isTablet ? (
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', bgcolor: 'primary.main', color: "text.secondary" },
          }}
        >
          {/* Drawer content */}
          <Box sx={{ width: '260px', paddingLeft: "10px" }}>

            <Box sx={{ display: 'flex', p: 1 }}>
            <Box sx={{ marginRight: "2%", my: 4 }}>
                <Link to="/"> {/* Link to the home page */}
                  <img src={Logo} alt="logo" style={{ width: "100px" }} />
                </Link>
              </Box>
            </Box>
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px", marginBottom: '16px' }}>
              Welcome to Hirio
            </Typography>
            <Typography ml={1} component="h2" variant="subtitle1" sx={{ fontSize: '12.5px' }}>
              Fill out the simple form and get access to thousands of opportunities!
            </Typography>
          </Box>
          <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
            <List>
              {['Preferences', 'About you', 'Background', 'Preview and Submit'].map((menuItem) => renderMenuItem(menuItem)
                )}
            </List>
          </Box>
          <Box sx={{ mt: 'auto', ml: 4, mb: 2 }}> {/* Push to the bottom */}
            <Button
              type="submit"
              onClick={() => navigate("/v1/employee/preferences")}
              variant="standard"
              sx={{
                backgroundColor: 'secondary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'secondary.main'
                }
              }}
            >
              <ArrowBackIcon sx={{ color: 'primary.main', mr: 1 }} /> {/* Use sx prop for custom color */}
              <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                Back To Home
              </Typography>
            </Button>
          </Box>
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          open={drawerOpen}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', bgcolor: 'primary.main', color: "text.secondary" },
          }}
        >
          {/* Permanent drawer content */}
          <Box sx={{ width: '260px', paddingLeft: "10px" }}>
            <Box sx={{ display: 'flex', p: 1 }}>
              <Box sx={{ marginRight: "2%", my: 4 }}>
                <Link to="/"> {/* Link to the home page */}
                  <img src={Logo} alt="logo" style={{ width: "100px" }} />
                </Link>
              </Box>
            </Box>



            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px", marginBottom: '16px' }}>
              Welcome to Hirio
            </Typography>
            <Typography ml={1} component="h2" variant="subtitle1" sx={{ fontSize: '12.5px' }}>
              Fill out the simple form and get access to thousands of opportunities!
            </Typography>
          </Box>
          <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
          <List>
              {['Preferences', 'About you', 'Background', 'Preview and Submit'].map((menuItem) => renderMenuItem(menuItem)
                )}
            </List>
          </Box>
          <Box sx={{ mt: 'auto', ml: 4, mb: 2 }}> {/* Push to the bottom */}
            <Button
              type="submit"
              onClick={() => navigate("/v1/employee/preferences")}
              variant="standard"
              sx={{
                backgroundColor: 'secondary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'secondary.main'
                }
              }}
            >
              <ArrowBackIcon sx={{ color: 'primary.main', mr: 1 }} /> {/* Use sx prop for custom color */}
              <Typography component="h2" variant="body1" sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'none', color: 'primary.main', padding: "2px" }}>
                Back To Home
              </Typography>
            </Button>
          </Box>
        </Drawer>
      )}
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <Toolbar>
          {isTablet && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setDrawerOpen(!drawerOpen)}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
        {props.children}
      </Box>
    </Box>
  );
}

export default EmployeeSidebar;
